'use strict';

var NotificationsCtrl = angular.module('RessieApp')

.controller('notificationsCtrl', function ($scope, $rootScope, $filter, $state, allowed, PendingBookings, notifications, Notes, $templateCache, $compile) {

  if(!allowed) $state.go('login');

  if($rootScope.user.restricted) $state.go('gifts');

  if(notifications){
    if(angular.isArray(notifications.notes)) $scope.notes = notifications.notes;
    if(angular.isArray(notifications.bookings)) $scope.bookings = notifications.bookings;
  }

  $scope.$watch(function(){
    return PendingBookings.pendings;
  }, function(n, o){
    var count = 0;

    angular.forEach(n, function(booking){
      if(_.findIndex($scope.bookings, function(b){
        return b._id == booking._id
      }) < 0 ){
        if(angular.isDefined($scope.bookings)) $scope.bookings.unshift(booking);
        count++;
      }
    });

    $rootScope.pendingBookings += count;

  });

  $scope.$watch('note._day', function(n){
    if(n) $scope.note.day = moment(n).format('MM/DD/YYYY')
  })

  $scope.$watch('note.day', function(n, o){
    if(n && n !== o) $scope.note._day = moment(n).toDate()
  })

  var self = {

      calendar: {
          today: moment()
        , min: this.today
      }

    , dateDiff: function(a, b){
        b = b || new Date();
        return moment(a).diff(b, 'days');
      }

    , isRead: function(note){
        var logs = note.log
          , user = $scope.user._id;

        if(logs.length == 0){
          return false;
        } else {
          return _.findIndex(logs, function(log){
            return log.user == user;
          }) >= 0
        }
      }

    , newNote: function(){

        bootbox.dialog({
          title: "Create a New Note",
          message: $compile($templateCache.get('views/bootbox/noteForm'))($scope),
          buttons: { 
            cancel: {
              label: "Nevermind",
              className: "btn-link",
              callback: function() {}
            },
            save: {
              label: "Save",
              className: "btn-success",
              callback: function() { 
                
                var note = $scope.note;

                if(!angular.isDefined(note) || (!angular.isDefined(note.day) || !angular.isDefined(note.body))) {
                  alert('Please ensure a date and note body is defined');
                  return false;
                }

                Notes.create($scope.note, function Success(note){
                  note.user = $scope.user;
                  $scope.notes.push(note);
                }, function Error(err){
                  console.error(err);
                })
              }
            },
          }
        });

      }

    , openNote: function(note, faux){

        Notes.one(note._id, function Success(res){
          $rootScope.pendingNotes -= 1;
          note.log.push({ user: $scope.user._id, date: new Date() });
          if(!faux){
            $scope.note = angular.copy(res);
            bootbox.dialog({
              title: "Note for " + $filter('readTime')(note.day, 'dddd[,] MMM Do'),
              message: $compile($templateCache.get('views/bootbox/note'))($scope),
              buttons: { 
                done: {
                  label: "Ok",
                  className: "btn-success",
                  callback: function() { }
                },
              }
            });
          }
        }, function Error(err){
          console.error(err);
        })

      }

  };

  angular.extend($scope, self);

});
