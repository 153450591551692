angular.module('RessieApp.directives.responsive', [])

.directive('responsive', ['$window', '$timeout', function($window, $timeout){
  
  var win = $($window);

  return {
    restrict: 'CA'
  , require: 'responsive'
  , controller: function($scope, $rootScope){

      $rootScope.responsive = {};

      var groups = {

          'xs' : {
              'min' : null
            , 'max' : 768 
          }

        , 'sm' : {
              'min' : 768
            , 'max' : 992
          }

        , 'md' : {
              'min' : 992
            , 'max' : 1200
          }

        , 'lg' : {
              'min' : 1200
            , 'max' : null
          }

      };

      $scope.resize = function(){

        var width = win.width()
          , size;

        angular.forEach(groups, function(val, key){
          
          if(!val.max){
            if(width >= val.min) size = key;
          }

          if(!val.min){
            if(width <= val.max) size = key;
          }

          if(width <= val.max && width >= val.min) size = key;

        });

        return size;

      };

      $scope.update = function(size){

        $timeout(function(){
          $rootScope.responsive.screen_size = size;
        });

      }

    }
  , compile: function(tElement, tAttrs, transclude){

      return function Link(scope, element, attrs){

        scope.update(scope.resize());

        win.resize(function(){
          scope.update(scope.resize());
        })

      };
    }

  };

}]);