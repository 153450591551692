'use strict';

var ContactsCtrl = angular.module('RessieApp')

.controller('contactsCtrl', function ($scope, $rootScope, $state, allowed, $stateParams, contacts, Contacts, Cards, Banks, $timeout, $filter) {

  if(!allowed) $state.go('login');

  if($rootScope.user.restricted) $state.go('gifts');

  AppCtrl.activeTab($stateParams, $rootScope);

  if(contacts){
    if($state.current.name == 'contacts'){
      $scope.pages = {};
      $scope.alphabetize = contacts.alphabetize;
      var count;
      var filter = $stateParams.filter === 'numbers' ? '0-9' : $stateParams.filter;
      var startingNumber = filter === 'all' ? contacts.count : contacts.alphabetize[filter];
      for (var i = 1; i <= Math.ceil(startingNumber / 50); i++) {
        count = (i == Math.ceil(startingNumber / 50)) ? (startingNumber % 50) : 50;
        $scope.pages[i] = count
      };
      $scope.contacts = $filter('toArray')(contacts.contacts);
    } else {
      try {
        contacts.meta = contacts.meta || {};
      } catch (e) {
        contacts = {};
        contacts.meta = {};
      }
      $scope.contacts = contacts;
    }
    // find out if we're adding a new contact or editing one.
    if(Object.prototype.toString.call(contacts) == "[object Object]" && typeof $stateParams.tab == 'undefined') {
      $scope.contacts.isNew = !angular.isDefined(contacts._id);
    }

    $scope.contacts.parentId = $scope.contacts.parentId || $stateParams.parent;
  }

  var self = {

    card: {}

  , bank: {}

  , setCode: function() {
      if($scope.contact._id) {
        Contacts.code({_id: $scope.contact._id, code: $scope.contact.code }, function Success(res){
          $scope.contact.code = res.code;
        }, function Error(err){
          console.error(err)
        })
      }
      console.log($scope.contact.code, $scope.contact._id)
    }

  , setOthers: function(i){
      angular.forEach($scope.contacts.cards, function(card, key){
        if(key !== i) {
          card.primary = false;
        } else {
          card.primary = true;
        }
      });
    }

  , verifyCard: function(){

      var self = this
        , expiration = $scope.card.expiration
        , card_obj = {
            number: $scope.card.number,
            cvv: $scope.card.cvv,
          };

      card_obj.expiration_month = expiration.substring(0, 2);
      card_obj.expiration_year = "20" + expiration.substring(2);

      var creditCard = ne(card_obj);

      creditCard.Create(
        function Success(res){

          // Get 'authorizes' the card ID
          Cards.get(res.cards[0].id,
            function Success(res){

              $timeout(function(){

                self.contacts.cards.push({
                  id: res.id,
                  card: creditCard,
                  masked: creditCard.Number().slice(-4),
                  type: creditCard.type()
                });

                angular.extend($scope.card, {
                  number: null,
                  expiration: null,
                  cvv: null,
                  billing: null,
                  zip: null
                });

                $scope.save($scope.contacts);

              });

            },
            function Error(err){
              console.error(err);
            }
          );


        },
        function Error(err){
          console.error(err);
        }
      );

    }


  
  , save: function(data){

      var method = angular.isDefined(data._id) ? 'update' : 'save';
      data.saving = true;

      Contacts[method](data,
        function Success(res){
          console.info(res);
          $timeout(function(){
            data.saving = false;
            if(data.parentId) {
              $state.go('contactEdit', { id: data.parentId });
            } else {
              $state.go('contacts', { tab: 'hotels', filter: data.name[0] })
            }
          }, 500);
        },
        function Error(err){
          $timeout(function(){ data.saving = false; }, 500);
        }
      );
    }

  };

  angular.extend($scope, self)

});
