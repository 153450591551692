var APIBookings = angular.module('RessieApp.services.api.contacts', [])

.service('Contacts', function(Request, API, $rootScope, $state, $timeout, $window){

  var self = this;

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        list: {
            method: 'GET'
          , cache: true
          , url: '/contacts/:type'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , find: {
            method: 'POST'
          , url: '/contacts'
          , isArray: true
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , get: {
            method: 'GET'
          , url: '/contact/:id'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , code: {
          method: 'PUT'
        , url: '/contact/:id/code/:code'
        , isArray: false
        , params: { id: '@_id' }
        , headers: {
          'Authorization': key,
          'Company': $rootScope.user.Company()
        }
      }

      , update: {
            method: 'PUT'
          , url: '/contact/:id'
          , isArray: false
          , params: { id : '@_id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , save: {
            method: 'POST'
          , url: '/contact/:type'
          , isArray: false
          , params: { type : '@_type' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , delete: {
            method: 'DELETE'
          , url: '/contact/:_id'
          // , params: { id : '@_id' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }


    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource(endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) $timeout(function(){
          var message = (angular.isDefined(response.error.message)) ? response.error.message : response.error;
          var confirm = window.alert(message);
          return error(response);
        });
      } else {
        Request.renew(token);
        return success(response, token);
      }

    });
  };

  self.byType = function(type, payload, success, error) {

    payload = payload || {};

    if(typeof payload == 'function'){
      error = success;
      success = payload;
      payload = {}
    }

    payload.type = type;

    self.request('list',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.find = function(query, success, error) {

    payload = { conditions: query };

    self.request('find',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.get = function(id, success, error) {

    var payload = {};

    payload.id = id;

    self.request('get',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.code = function (payload, success, error) {

    self.request('code',
      {
        payload: payload
      },
      function Success(response, token) {

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if (angular.isDefined(success)) return success(response);

      },
      function Error(err) {
        console.log(err);
        if (angular.isDefined(error)) return error(err);
      }

    );

  };

  self.update = function(payload, success, error) {

    self.request('update',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.delete = function(payload, success, error) {

    self.request('delete',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.save = function(payload, success, error) {

    payload = payload || {};

    payload._type = payload.meta.type;

    self.request('save',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };


});
