var StringFilter = angular.module('RessieApp.filters.string', [])

  .filter('replace', function () {

    return function (string, search, replace) {
      if (!string) return;
      return string.replace(search, replace);
    };

  })

  .filter('padStart', function () {

    return function (string, total) {
      if (!string) return;
      return string.padStart(total, '&nbsp;');
    };

  })