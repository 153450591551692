(function(){

  'use strict';

  var FlightsController = angular.module('RessieApp')
  .value('flight', null)
  .value('flights', null)
  .controller('flightsCtrl', function ($scope, $state, Flights, $timeout, flights, flight, API, Bookings) {

    if(flight) $scope.flight = flight;

    $scope.QueryFilter = {};

    $scope.perPage = 15;

    $scope.calendar = {
      date: {
        startDate: $scope.startDate,
        endDate: $scope.endDate
      },
      options: {
        showDropdowns: true,
        locale: {
          format: 'MM/DD/YY',
          separator: ' - '
        },
        singleDatePicker: false,
        ranges: {
          'Today': [
            moment(),
            moment()
          ],
          'Yesterday': [
            moment().subtract(1, 'days'),
            moment().subtract(1, 'days')
          ],
          'Last 7 Days': [
            moment().subtract(6, 'days'),
            moment()
          ],
          'Last 30 Days': [
            moment().subtract(29, 'days'),
            moment()
          ],
          'This Month': [
            moment().startOf('month'),
            moment().endOf('month')
          ],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
          ]
        }
      }
    };

    $scope.$watch('calendar.date', function(n, o){
      if(n){
        var start = n.startDate;
        var end = n.endDate;
        if(start) start = start.format('MM-DD-YY');
        if(end) end = end.format('MM-DD-YY');
        $scope.QueryFilter = {
          start: start,
          end: end
        };
        $scope.flightPage(1, $scope.perPage);
      }
    }, true)

    var self = {
      flights: flights || [],
      photoTile: function(cols){
        var columns = new Array(cols);
        for (var i = 0; i < $scope.flight.photos.files.length; i++) {
          var element = $scope.flight.photos.files[i];
          var col = i % cols;
          columns[col] = columns[col] || [];
          columns[col].push(element)
        }
        return columns;
      },
      // make pagination pretty
      pagination: function(pagination){
        pagination.pages = pagination.pages.constructor === Array ? 
          pagination.pages : (function(){
            var i = 1;
            var pageArray = [];
            while (i <= pagination.pages) {
              pageArray.push(i);
              i++;
            }
            return pageArray;
          })();
        return pagination;
      },
      flightPage: function(page, count) {
        count = count || $scope.perPage;
        var query = { 
          id: $scope.user ? $scope.user._id : null, 
          count: count, 
          page: page
        };
        angular.forEach($scope.QueryFilter, function(val, key){
          query[key] = val;
        });
        Flights.byPilot(query, function Win(res){
          $scope.flights = res;
        });
      },
      findFlight: function(booking){
        return (booking.flight.filter(function(flight){
          return flight.flight == $scope.flight._id
        })[0]);
      },
      archiveLink: function(){
        return API.getDomain() + '/flight/archive/' + $scope.flight._id + '.zip'
      },
      thumb: function(file){
        if(file){
          var file = file.replace(/%2F/g, '/').split('/');
          file.splice(file.length - 1, 0, 'thumbs');
          return file.join('/');
        }
      },
      update: function(flight, redir){
        redir = redir !== undefined ? redir : true;
        flight.working = true;
        Flights.update(flight, function Win(res){
          flight.working = false;
          if(redir) $state.go('dashboard');
        }, function Lose(err){
          alert(err);
          flight.working = false;
        })
      },
      byPilot: function(pilot){
        Flights.byPilot({ id: pilot._id }, function Win(res){
          $scope.flights = res;
        });
      },
      totalGuests: function(flight){
        var bookings = flight.reservations, total = 0;
        if(bookings.length > 1){
          angular.forEach(flight.reservations, function(booking){
            total += (booking.adults + (booking.children || 0));
          });
          return total;
        } else {
          return typeof bookings[0] !== 'undefined' ? (bookings[0].adults + (bookings[0].children || 0)) : 0;
        }
      },
      getLaunchSites: function(){
        return $scope.user.activeCompany().locations.filter(function(site){
          return site.type == 'launch';
        });
      },
      someoneHasPhotos: function(flight, emails){
        var photos = [];
        emails = emails || false;
        angular.forEach(flight.reservations, function(booking){
          booking.line_items.filter(function(item){
            if(item.product.name.match(/photo|picture|image/i)){
              booking.photo_email = booking.photo_email || booking.email;
              if(booking.photo_email) photos = photos.concat(booking.photo_email.split(','));
            }
          });
        });
        return emails ? photos : photos.length > 0;
      },

      updateImpression: function(booking, flight, happy){

        var orig;
        var thisFlight = (booking.flight.filter(function(f){
          return f.flight == flight
        })[0]);

        orig = thisFlight.happy;

        if(orig === happy) happy = 'unset';

        thisFlight.happy = happy !== 'unset' ? happy : null;

        Bookings.updateImpression({ _id: booking._id, flight: thisFlight.flight, happy: happy },
          function Success(res){
          }, function Error(err){
            thisFlight.happy = orig;
            alert(JSON.stringify(err));
          })
      }
    };

    angular.extend($scope, self);

  });

})();
