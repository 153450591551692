var APILegacy = angular.module('RessieApp.services.api.pickups', [])

.service('Pickups', function(Request, API, $rootScope, $state, $timeout){

  var self = this;

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        forDay: {
            method: 'GET'
          , url: '/pickups/day/:day'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource('pickups', endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) $timeout(function(){ return error(response); });
      } else {
        return success(response);
      }

    });
  };

  self.forDay = function(day, lite, success, error) {

    if(typeof lite == 'function') {
      error = success;
      success = lite;
      lite = false;
    }

    var payload = { 'day' : day };

    if(lite) payload.light = true;

    self.request('forDay',
      {
        payload: payload
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation

        // Populate drivers
        if(angular.isDefined(response.meta)){
          angular.forEach(response.meta.drivers, function(driver){

            _.findWhere(response.bookings, function(booking){
              _.findWhere(booking.flight, function(flight){
                var flight = flight.flight;
                var drivers = flight.crew.drivers;

                _.findWhere(drivers, function(driv){
                  if(driver._id == driv._id){
                    driver.flight = flight;
                    driver.reservations.push(booking);
                  }
                })
              });
            });

          });
        }

        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };


});
