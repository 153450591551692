var APIBundles = angular.module('RessieApp.services.api.bundles', [])

.service('Bundles', function(Request, API, $rootScope, $state, $timeout){

  var self = this;

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

      list: {
          method: 'GET'
        , url: '/bundles'
        , isArray: true
        , headers: {
            'Authorization': key,
            'Company': $rootScope.user.Company()
          }
      },

    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource('bundles', endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) $timeout(function(){ return error(response); });
      } else {
        return success(response);
      }

    });
  };

  self.list = function(success, error) {

    var payload = { };

    self.request('list',
      {
        payload: payload
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

});
