'use strict';

var SettingsCtrl = angular.module('RessieApp')

.value('templates', {})
.value('tab', '')

.controller('settingsCtrl', function ($scope, $rootScope, $filter, $state, $location, tab, allowed, templates, $timeout, company, balloons, packages, zones, pilots, Balloons, Packages, Products, products, Zones, Companies, $compile, $templateCache, Users, StripeBank) {

  if(!allowed) $state.go('login');

  $scope.tab = tab;

  $scope.setTab = function(tab) {
    $scope.tab = tab;
    $location.path('admin/' + tab)
  }

  if(pilots){
    $scope.pilots = pilots;
  }

  if(company){
    $scope.company = company;
  }

  if(balloons){
    if(angular.isArray(balloons)){
      $scope.balloons = balloons;
    } else {
      $scope.balloon = balloons;
    }
  }

  if(templates){
    if(angular.isArray(templates.templates)){
      $scope.templates = templates.templates;
      $scope.template_rules = templates.rules;
    } else {
      $scope.template = templates;
    }
  }

  if(products){
    // if they're nested then the object key is an ObjectID
    // this checks to see if it's alphanumeric and 24 characters long
    // not foolproof, but should suffice
    if(Object.keys(products).length > 0 && Object.keys(products)[0].match(/^[0-9a-fA-F]{24}$/)){
      $scope.products = products;
    } else {
      if($state.current.id == 'new'){
        Products.nested(
          function Success(res){
            products.products = res;
          },
          function Error(err){
            console.error(err);
          }
        );
      }
      $scope.product = products;
    }
  }

  if(packages){
    if(angular.isArray(packages)){
      $scope.packages = packages;
    } else {
      $scope.package = packages;
    }
  }

  if(zones){
    if(angular.isArray(zones)){
      $scope.zones = zones;
    } else {
      $scope.zone = zones;
    }
  }

  // $state.current.tab = $state.current.tab.replace(/^\//, '');

  $scope.getTrace = function () {
    Users.locations(function (res) {
      delete res.requestUrl;
      $scope.userLocations = res;
    });
  }

  $scope.httpReg = new RegExp('https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}/', 'i');

  $scope.selected = undefined;

  $scope.$watch('product.parents', function(n){
    if(n) {
      if(n.length > 0) $scope.product.parentId = n[n.length-1]._id;
    }
  }, true);

  $scope.$watch('sibling', function(n){
    if(n && n._id && n._id !== "") {
      var id = angular.copy(n._id);
      $scope.product.parentId = id;
      Products.one(id,
        function Success(res){
          delete res.products;
          delete res.siblings;
          $scope.product.parents.push(res);
          if(res.children) {
            $scope.product.siblings = res.children;
          } else {
            $scope.product.siblings = null;
          }
        },
        function Error(err){
          console.error(err);
        }
      );

    }
  }, true);

  $scope.editTemplate = function(cancellation){
    $timeout(function(){ $scope.selected = cancellation; });
  }

  var self = {

    addbank: false,

    isActiveTab: function(string){
      return string == $state.current.tab
    },

    JSONparse: function(string){
      // return JSON.parse(string);
    },

    /**
     * Add Bank to companys merchant account
     * @param {[type]} info [description]
     */
    addBank: function(info){

      var bank = new StripeBank({
        routing_number: info.routing_number,
        account_number: info.account_number
      });

      bank.Create($scope.company.account.keys.public, function Win(res){
        Companies.addBank({ token: res.id },
          function Success(res){
            $scope.company.account.bank_accounts.push(res);
          },
          function Error(err){
            alert(err.error || err);
          }
        )
      }, function Lose(err){
        console.error(err);
      });

    },

    hideBank: function(event){

    },

    linkMerchant: function(){
      $scope.linking = true;
      Companies.linkMerchant(
        function Success(res){
          $timeout(function(){

            if(res.verification){
              angular.forEach(res.verification.fields_needed, function(field){
                field.toObject(res.verification, null);
              })
            }

            $scope.linking = false;
            $scope.company.account = res.account;
            $scope.company.verification = res.verification;

          }, 1000)
        },
        function Error(err){
          $timeout(function(){
            alert(err)
          }, 1000)
        }
      )
    },

    updateCompany: function(company, merchant){

      merchant = merchant || false;

      company.saving = true;

      var prices = [];

      var copy = angular.copy(company);

      if(copy.prepaids.prices.length > 0 && copy.prepaids.prices[0].toString() == '[object Object]'){
        angular.forEach(copy.prepaids.prices, function(price){
          prices.push(price.value);
        });
        copy.prepaids.prices = prices;
      }

      if(merchant && (company.account.stripe.bank_account && company.account.stripe.bank_account.routing_number && company.account.stripe.bank_account.account_number)){

        var bank = new StripeBank({
          key: company.account.keys.public,
          routing_number: company.account.stripe.bank_account.routing_number,
          account_number: company.account.stripe.bank_account.account_number
        });

        bank.Create(function Win(res){
          delete copy.account.stripe.bank_account;
          copy.account.stripe.bank_account = res.id;

          Done();

        }, function Lose(err){
          console.error(err);
        });

      } else {
        Done();
      }

      function Done(){

        if(!merchant) delete copy.account;

        Companies.update(copy, function Success(res){

          if(res.verification){
            angular.forEach(res.verification.fields_needed, function(field){
              field.toObject(res.verification, null);
            })
          }

          company.account.bank_accounts = res.account.bank_accounts;
          company.verification = res.verification || [];
          company.saving = false;
        }, function Error(err){
          company.saving = false;
        })
      }

    },

    updateCancellation: function(cancellation){
      cancellation.saving = true;
      var cancel = angular.copy(cancellation);
      if(cancel.dontSend) cancel.template = null;
      Companies.updateCancellation(cancel, function Success(res){
        $timeout(function(){
          cancellation.saving = false;
          $scope.selected = undefined;
        }, 1000);
      }, function Error(err){
        console.error(err);
        cancellation.saving = false;
      })
    },

    newCancellation: function(){
      $scope.company.cancellations.push({
        default: false,
        name: null,
        price: false,
        template: null,
        index: $scope.company.cancellations.length
      });
      $scope.selected = $scope.company.cancellations[$scope.company.cancellations.length - 1];
    },

    saveCancellation: function(cancellation){
      cancellation.saving = true;
      var cancel = angular.copy(cancellation);
      if(cancel.dontSend) cancel.template = null;
      Companies.createCancellation(cancel, function Success(res){
        $scope.company.cancellations[cancellation.index] = res;
        $scope.selected = $scope.company.cancellations[cancellation.index];
        $scope.user.activeCompany().cancellations.push(res);
        $timeout(function(){
          cancellation.saving = false;
          $scope.selected = undefined;
        }, 1000);
      }, function Error(err){
        console.error(err);
        cancellation.saving = false;
      })
    },

    removeCancellation: function(cancellation){
      cancellation.saving = true;
      var cancel = angular.copy(cancellation);
      Companies.removeCancellation(cancel._id, function Success(res){

        _.takeWhile($scope.user.activeCompany().cancellations, function(n) {
          return n._id != cancellation._id;
        });

        $timeout(function(){
          cancellation.removed = true;
          cancellation.saving = false;
        }, 1000);
      }, function Error(err){
        console.error(err);
        cancellation.saving = false;
      })
    },

    updateBalloon: function(balloon){
      Balloons.update(balloon, function Success(res){
        $state.go('admin');
      }, function Error(err){

      })
    },

    saveBalloon: function(balloon){
      Balloons.create(balloon, function Success(res){
        $state.go('admin');
      }, function Error(err){
      })
    },

    removeBalloon: function(balloon){
      Balloons.remove(balloon._id, function Success(res){
        balloon.removed = true;
      }, function Error(err){
      })
    },

    updatePackage: function(pack){
      $scope.saving = true;
      Packages.update(pack, function Success(res){
        $scope.saving = false;
        $state.go('admin', { tab: 'packages'});
      }, function Error(err){
        $scope.saving = false;
      })
    },

    savePackage: function(pack){
      Packages.create(pack, function Success(res){
        $state.go('admin', { tab: 'packages'});
      }, function Error(err){
      })
    },

    removePackage: function(pack){
      Packages.remove(pack._id, function Success(res){
        pack.removed = true;
      }, function Error(err){})
    },

    updateZone: function(zone){
      $scope.saving = true;
      Zones.update(zone, function Success(res){
        $scope.saving = false;
        $state.go('admin');
      }, function Error(err){
        $scope.saving = false;
      })
    },

    saveZone: function(zone){
      Zones.create(zone, function Success(res){
        $state.go('admin');
      }, function Error(err){
      })
    },

    removeZone: function(zone){
      Zones.remove(zone._id, function Success(res){
        zone.removed = true;
      }, function Error(err){
      })
    },

    updateProduct: function(product){
      $scope.saving = true;
      Products.update(product, function Success(res){
        $scope.saving = false;
        $state.go('admin');
      }, function Error(err){
        $scope.saving = false;
      })
    },

    saveProduct: function(product){
      Products.create(product, function Success(res){
        $state.go('admin');
      }, function Error(err){
      })
    },

    removeProduct: function(product){

      bootbox.dialog({
        message: "<p><strong>This can not be undone.</strong> All children will also be removed. If you still want to remove this product please type <code>DELETE</code> below:</p><div class='form-group bg-light' style='padding: 26px;margin: 0 -20px -1px -20px;margin-bottom: -35px;'><input type='text' id='result' class='form-control' /></div>",
        title: "Removing <code>" + product.name +"</code>. Are you sure?",
        buttons: {
          success: {
            label: "Remove",
            className: "btn-primary",
            callback: function() {
              var result = $('#result').val();
              if(result == 'DELETE') {

                Products.remove(product._id, function Success(res){
                  product.removed = true;
                }, function Error(err){

                })

              }
            }
          },
          cancel: {
            label: "Cancel",
            className: "btn-default",
            callback: function() {}
          }

        }
      });

    },

    appendParent: function(product){
      if(product !== "") {
        var product = JSON.parse(product)
        delete product.products;
        delete product.siblings;
        $scope.product.parents.push(product);
        if(product.children) {
          $scope.product.siblings = product.children;
        }
      }
    },

    setParent: function(i){
      var parents = angular.copy($scope.product.parents);
      parents.splice(i + 1, parents.length);
      if(p !== "") {
        Products.one(parents[i]._id,
          function Success(res){
            delete res.products;
            delete res.siblings;
            parents[i] = res;
            if(res.children) {
              $scope.product.siblings = res.children;
            }
          },
          function Error(err){
            console.error(err);
          }
        );
      }
    },

    getProducts: function(item){

      item.depth = item.path.split(',').length - 1;

      if(item.depth == 0) return $scope.product.products;

      var parents = $scope.product.parents;
      var parent = $scope.product.products[parents[0]._id];
      var products;

      function findParent(parent, i){
        products = parent.children;
        if(angular.isDefined(products[item._id])) return;
        i++;
        parent = products[parents[i]._id]
        return findParent(parent, i)
      }

      findParent(parent, 0);

      return products;

    },

    newAppUser: function(){

      $scope.appUser = { meta: { mobile: true } };

      bootbox.dialog({
        size: "large",
        title: "New App User",
        message: $compile($templateCache.get('views/bootbox/appUser'))($scope),
        buttons: {
          done: {
            label: "Save",
            className: "btn-success",
            callback: function() {
              Users.save($scope.appUser, function Success(res){
                $scope.company.devices.push(res);
              }, function Error(err){

              })
            }
          },
        }
      });

    },

    toggleActive: function(user){

      var _user = angular.copy(user);

      _user.active = !_user.active;

      var data = { _id: _user._id, active: _user.active };

      Users.update(data, function Success(res){
        user.active = _user.active;
      }, function Error(err){

      });

    }

  };

  if($scope.package) {
    $scope.package.vipPricing = $scope.package.vipPricing.map(function (vip) {
      return new VipPrice(vip);
    })
  
    self.lastVip = function() {
      var pricing = angular.copy($scope.package.vipPricing).sort(function (a, b) {
        return a.max < b.max ? 1 : -1
      });
      return pricing.length ? pricing[0] : undefined;
    }

    self.removeVip = function(vip) {
      var pricing = $scope.package.vipPricing;
      var index = pricing.findIndex(function(p) {
        if (vip._id) return p._id === vip._id;
        return p.min === vip.min && p.max === vip.max && p.price === vip.price;
      })
      pricing.splice(index, 1)
    }
  
    self.vipPrice = {
      min: undefined,
      max: undefined,
      price: undefined,
      add: function() {
        var self = angular.copy(this);
        delete self.add;
        $scope.package.vipPricing.push(new VipPrice(self));
        this.min = undefined;
        this.max = undefined;
        this.price = undefined;
      }
    }
  }

  angular.extend($scope, self);

});

function VipPrice(vip) {
  angular.extend(this, vip);
  return this;
}
