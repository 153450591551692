var DirectivesFiles = angular.module('RessieApp.directives.header', [])

.directive('header', ['$timeout', function($timeout) {
  return {
    restrict: 'A'
  , templateUrl: 'dir/header/header'
  , replace: false
  , controller: function($scope, $rootScope){

      $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams){
        $scope.state = angular.extend(toState, toParams);
      });

      var self = this;

      angular.extend(self, {});

      angular.extend($scope, self);

    }
  , compile: function(tElement, tAttrs, transclude){

      return function Link(scope, element, attrs, Ctrl){
      }

    }
  }
}]);