var AppDirectives = angular.module('RessieApp.directives', [
    'RessieApp.directiveTemplates'
  , 'RessieApp.directives.sidebar'
  , 'RessieApp.directives.header'
  , 'RessieApp.directives.scrollable'
  , 'RessieApp.directives.responsive'
  , 'RessieApp.directives.hallo'
  , 'RessieApp.directives.pricing'
  , 'RessieApp.directives.time'
  , 'RessieApp.directives.date'
  , 'RessieApp.directives.calendar'
  , 'RessieApp.directives.reports'
  , 'RessieApp.directives.copy'
  , 'RessieApp.directives.code'
]);
