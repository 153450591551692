var year = new Date().getFullYear();

var rootDomain = window.rezflow.api_url ? window.rezflow.api_url : (window.location.host.match(/joseymorton/)) ? 'http://nvbapi.joseymorton.com' : 'http://localhost:8080';

var AppCtrl = angular.module('RessieApp', [
    'RessieApp.directives'
  , 'RessieApp.components'
  , 'RessieApp.filters'
  , 'RessieApp.templates'
  , 'RessieApp.services'
  , 'RessieApp.factories'
  , 'ngFileUpload'
  , 'ui.date'
  , 'ui.select2'
  , 'ui.select'
  , 'ui.router'
  , 'ui.utils'
  , 'ui.bootstrap'
  , 'ui.bootstrap.tpls'
  , 'AngularPrint'
  , 'ivpusic.cookie'
  , 'ngAnimate'
  , 'ngResource'
  , 'spin.js'
  , 'stripe'
  , 'ngSanitize'
  , 'daterangepicker'
  
]);

var dateCheck = {};

AppCtrl.run(['$state', '$rootScope', '$location', '$timeout', '$trace', function ($state, $rootScope, $location, $timeout, $trace) {
  // $trace.enable('TRANSITION');

  $rootScope.bugCatcher = $rootScope.bugCatcher || [];

  var original = $location.path;
  $location.path = function (path, reload) {
    if (reload === false) {
      var lastRoute = $state.current;
      var un = $rootScope.$on('$locationChangeSuccess', function () {
        $state.current = lastRoute;
        un();
      });
    }
    return original.apply($location, [path]);
  };
}]);

AppCtrl.factory('$exceptionHandler', function($log, $injector, $window) {
  return function myExceptionHandler(exception, cause) {
    var $rootScope = $injector.get('$rootScope')
    var $location = $injector.get('$location')
    $rootScope.bugCatcher.push({
      url: $location.$$absUrl,
      error: exception.stack,
      stack: exception.stack,
      location: undefined
    });

    var options = { tags: {
      host: $window.location.host
    } };

    options.tags['username'] = $window.active_user;
    options.tags.company = $window.active_company;
    if($window.rezflow.env && $window.rezflow.env !== 'development') Raven.captureException(exception, options);
    $log.error(exception, cause);
  };
})

AppCtrl.config(function ($provide, APIProvider, $urlRouterProvider, $urlMatcherFactoryProvider, uiSelectConfig) {
  /**
   * Use environmental api_url if it exists, otherwise fall back
   */
  if(window.rezflow.api_url) {
    APIProvider.setDomain(window.rezflow.api_url);
  } else {
    if(window.location.host.match(/localhost/)) {
      APIProvider.setDomain('http://localhost:8080');
    } else if(window.location.host.match(/joseymorton/)) {
      APIProvider.setDomain('http://nvbapi.joseymorton.com');
    } else {
      APIProvider.setDomain('https://nvbapi.herokuapp.com');
    }
  }

  function valToString(val) { return val != null ? val.toString() : val; }
  function valFromString(val) { return val != null ? val.toString() : val; }
  function regexpMatches(val) { /*jshint validthis:true */ return this.pattern.test(val); }
  $urlMatcherFactoryProvider.type("MyType", {
    encode: valToString,
    decode: valFromString,
    is: regexpMatches,
    pattern: /[a-zA-Z0-9/\%]/
  });


  uiSelectConfig.theme = 'select2';
  uiSelectConfig.appendToBody = true;

  $provide.decorator("$exceptionHandler", function ($delegate, $window, $injector) {
    Error.stackTraceLimit = Infinity;
    return function (exception, cause) {
      $delegate(exception, cause);
    };
  });

  $provide.decorator('dateRangePickerDirective', function($delegate, $rootScope, $templateCache, Calendar) {

    var directive = $delegate[0];

    var link = directive.link;
    var now;

    directive.compile = function() {
      return function(scope, element, attrs, ctrls) {

        scope.opts.template = $templateCache.get('views/overrides/daterangepicker');

        var datepickerCtrl = ctrls[0], ngModelCtrl = ctrls[1];

        if ( ngModelCtrl ) {

        }

        link.apply(this, arguments);
      };
    };

    return $delegate;

  });

  $provide.decorator('datepickerDirective', function($delegate, $rootScope, Calendar, $timeout) {

    var directive = $delegate[0];

    var link = directive.link;

    var test = [];

    var now;
    // We dont want this to fire a million times.
    // If the last fire for a month is less than 5 seconds ago we wont fire again
    function getEvents(datepickerCtrl, scope, cb){
      cb = cb || function(){};
      var startDate, endDate, toMoment;
      toMoment = moment(datepickerCtrl.activeDate);
      startDate = moment(toMoment).set('date', 1).subtract(1, 'month').format('MM-DD-YYYY');
      endDate = moment(toMoment).set('date', 1).add(1, 'month').format('MM-DD-YYYY');

      Calendar.month([startDate, endDate], function Success(events){
        scope.days = _.groupBy(events, function(day){
          return moment.utc(day.date).format('MM-DD-YYYY');
        });
        cb(scope.days)
      }, function Error(err){
        console.error(err);
      });

    }

    directive.compile = function() {
      return function(scope, element, attrs, ctrls) {

        var datepickerCtrl = ctrls[0], ngModelCtrl = ctrls[1];
        var days = [];

        if ( ngModelCtrl ) {

          datepickerCtrl.init( ngModelCtrl );

          var refresh = datepickerCtrl.refreshView;

          datepickerCtrl.refreshView = function(){
            getEvents(datepickerCtrl, scope);
            refresh.apply(this, arguments);
          }

          getEvents(datepickerCtrl, scope);

        }

        scope.dayBtnClass = function(dt){
          var date = dt.date;
          var classes = { 'btn-info': dt.selected, active: scope.isActive(dt) }
          var capacity = null;
          if(angular.isDefined(scope.days)){
            var day = scope.days[moment(date).format('MM-DD-YYYY')];
            if(day && day.length > 0) {
              capacity = day[0].capacity
            }
          }

          if(capacity){
            classes['btn-warning'] = capacity.remaining <= 10 && capacity.remaining > 0;
            classes['text-muted lter'] = capacity.remaining == 0;
            if(capacity.remaining === 0){
              dt.disabled = true;
            }
          }

          return classes;

        }
        link.apply(this, arguments);
      };
    };

    return $delegate;

  });

});

/**
 * Application Controller
 */
AppCtrl.controller('AppCtrl', function ($log, $scope, $rootScope, $window, $location, Time, Pricing, API, $sce, $stateParams, $state, $timeout, Events, Bookings, Contacts, Users, Notifications, PendingBookings, $http, Socket, $filter, $transitions) {

  var lastPage = undefined;

  $scope.trans = {};

  function LogAccess() {
    var currentPage = $location.$$absUrl;
    Socket.emit('routeTrace', { last: lastPage, current: currentPage });
  }

  $rootScope.$state = $state;

  $transitions.onStart({
    to: function(){
      return true
    }
  }, function(trans){
    lastPage = $location.$$absUrl;
  });

  $transitions.onSuccess({
    to: function (state) {
      return true;
    }
  }, function(trans){
    var to = $scope.trans.to = trans.$to();
    var toParams = trans._targetState._params;
    var from = $scope.trans.from = trans.$from();
    var fromParams = from.params;
    if (toParams.day !== undefined) $scope.day = toParams.day;
    $rootScope.state = angular.extend(to, toParams);
    $rootScope.sidebar.offscreen = false;
    if (!toParams.day) toParams.day = 'tomorrow';
    $scope.date = angular.isDefined(Time.shortcuts.times[toParams.day]) ? Time.shortcuts.times[toParams.day] : $window.moment(toParams.day, 'MM-DD-YY');
    $scope.dateSelect = $scope.date;
    $scope.previousState = fromParams;
    $scope.params = toParams;
    if(trans.to().auth) LogAccess();
  })

  // $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
  //   if (angular.isDefined(toParams.day)) {
  //     $scope.day = toParams.day;
  //   }
  //   $rootScope.state = angular.extend(toState, toParams);
  //   $rootScope.sidebar.offscreen = false;
  //   if (!toParams.day) toParams.day = 'tomorrow';
  //   $scope.date = angular.isDefined(Time.shortcuts.times[toParams.day]) ? Time.shortcuts.times[toParams.day] : $window.moment(toParams.day, 'MM-DD-YY');
  //   $scope.dateSelect = $scope.date;
  //   $scope.previousState = fromParams;
  // });

  // var transition = $uiRouterGlobals.transition;

  Socket.on('err', function(err) {
    console.error(err);
  });

  $scope.trustMe = function(src) {
    return $sce.trustAsResourceUrl(src);
  }

  $window.onerror = function(error, url, row, col) {
    $rootScope.bugCatcher.push({
      url: url,
      error: JSON.stringify(error, null, 2),
      location: { row: row, column: col }
    });
  };

  $rootScope.reportBug = function(bug){
    //
    var fields = [
      {
        "title": "Company",
        "value": $scope.user.activeCompany().nickname,
        "short": true
      },
      {
        "title": "User Notes",
        "value": bug.notes,
        "short": false
      },
      {
        "title": "User Agent",
        "value": $window.navigator.userAgent,
        "short": false
      }
   ];

    if($rootScope.bugCatcher.length) {
      fields.push({
         "title": "Console Errors",
         "value": JSON.stringify($rootScope.bugCatcher, null, 2),
         "short": false
      });
    }


    $http.post('https://hooks.slack.com/services/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', {
      "parse": 'full',
      "channel": 'bugs',
      "username": $scope.user.name,
      "text": "*" + $scope.user.name + "* reported a bug from " + bug.url,
      "attachments": [
         {
            "fallback": "bug on " + bug.url,
            "color":"#D00000",
            "fields": fields
         }
      ]
    }, {
      headers : { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' }
    }).then(function(res){
      $timeout(function(){
        bug.notes = '';
        $rootScope.bugCatcher = [];
        angular.element('#bugTrigger').click();
      })
    }, function(err){
      console.error(err);
    });

  }

  $rootScope.maintenance = window.rezflow.maintenance;

  $rootScope.betaMode = $window.rezflow.beta;

  $rootScope.calendar = [];

  $rootScope.getEventDay = function(date){
    var date = $rootScope.calendar.filter(function(val){
      return moment.utc(val.date, 'MM/DD/YYYY').format('MM/DD/YY') == moment.utc(date, 'MM/DD/YYYY').format('MM/DD/YY');
    })
  }

  $scope.location = $window.location;

  var clicktime = null;

  $rootScope.pendingBookings = $rootScope.pendingBookings || 0;

  $rootScope.previous = {
    giftId: null,
    bookingId: null,
    noteId: null
  };

  $rootScope.typeof = function(val){
    return typeof(val);
  }

  $rootScope.showCover = false;

  var stopPending = false;

  $rootScope.$watch('user', function(n, o){
    if(n){
      Socket.emit('openedBookings');
      Socket.emit('unrespondedSms');
      Socket.emit('bookings', { status: 'pending' });
      Socket.emit('gifts', { status: 'pending' });
  }
  })

  setInterval(function(){
    var time = $window.moment();
    if(clicktime && time.diff(clicktime) > 500) {
      $state.go($state.$current.name, {day: $scope.day });
      clicktime = null;
    }
  }, 500);

  $rootScope.spinnerConf = {
    lines: 17,
    length: 0,
    width: 4,
    radius: 4,
    corners: 1,
    rotate: 0,
    direction: 1,
    color: '#fff',
    speed: 1.2,
    trail: 36,
    shadow: false,
    hwaccel: false,
    className: 'spinner',
    zIndex: 2e9,
    top: '-5px',
    left: 'auto'
  };

  $scope.extendSpin = function(obj){
    var spinner = angular.copy($rootScope.spinnerConf);
    return _.assign({}, spinner, obj);
  }

  $scope.removeConfirm = function(contact, type){

    var endpoint;

    if(type == 'contact') endpoint = Contacts;
    if(type == 'user') endpoint = Users;

    bootbox.dialog({
      message: "<p><strong>This can not be undone.</strong> If you still want to remove this contact please type <code>DELETE</code> below:</p><div class='form-group bg-light' style='padding: 26px;margin: 0 -15px -1px -15px;margin-bottom: -15px;'><input type='text' id='result' class='form-control' /></div>",
      title: "Removing <code>" + contact.name +"</code>. Are you sure?",
      buttons: {
        success: {
          label: "Remove",
          className: "btn-primary",
          callback: function() {
            var result = $('#result').val();
            if(result == 'DELETE') {

              if(endpoint){
                endpoint['delete']({ _id: contact._id },
                  function Success(res){
                    $timeout(function(){
                      contact.removed = true;
                    })
                  },
                  function Error(err){
                  var message = (angular.isDefined(err.message)) ? err.message : err;
                   window.alert(message)
                  }
                );
              } else {
                $timeout(function(){
                  contact.removed = true;
                });
              }

            }
          }
        },
        cancel: {
          label: "Cancel",
          className: "btn-default",
          callback: function() {}
        }

      }
    });
  };

  var global = {

      sidebar: {
          expanded: false
        , offscreen: false
      }

    , exists: function(model){
        return typeof model != 'undefined';
      }

    , showAdmin: function(){
        var user = $rootScope.user;
        return typeof user.can == 'function' ? user.can('company', 'Admin') || user.can('balloons', 'Admin') || user.can('packages', 'Admin') || user.can('products', 'Admin') || user.can('zones', 'Admin') || user.can('cancellations', 'Admin') : false;
      }

    , tabs: {

        bookings: [

          {
              heading: 'Reservations'
            , tab: 'bookings'
            , active: true
          }

        , {
              heading: 'Pickups'
            , tab: 'pickups'
            , active: false
          }

        , {
              heading: 'Balloons'
            , tab: 'balloons'
            , active: false
          }

        ]

      , contacts: [

          {
              heading: 'Hotels'
            , tab: 'hotels'
            , active: true
          }

        , {
              heading: 'Employees'
            , tab: 'employees'
            , active: false
          }

        , {
              heading: 'Partners'
            , tab: 'partners'
            , active: false
          }

        ]

      }


  };

  $rootScope.openedBookings = {};

  Socket.on('gifts', function(res){
    $rootScope.pendingGifts = res.response;
  })

  Socket.on('unrespondedSms', function (res) {
    var messages = res.response;
    $rootScope.unrespondedMessages = messages;
  });


  // get pending bookings
  Socket.on('bookings', function(res){
    console.log('res', res)
    var bookings = res.response;
    PendingBookings.setPendings(bookings);
    $rootScope.pendingBookings = bookings.filter(function(booking){
      var filter = booking.trace && booking.trace.filter(function(trace){
        return trace.reason === 'open' && !trace.ignore;
      });
      return filter.length <= 0;
    }).length;
  });

  Socket.on('newPending', function(res){
    $rootScope.pendingBookings += 1;
    PendingBookings.pendings.push(res.response)
  })

  Socket.on('openedBookings', function(data){
    $rootScope.openedBookings = data.response;
  });

  Socket.on('openedBooking', function(data){
    if ($rootScope.user) {
      var currentCompany = $rootScope.user.activeCompany();
      var conf = data.response.confirmation;
      // $stateParams.confirmation.replace(/^[a-z]+/i, '')
      $rootScope.openedBookings[data.company] = $rootScope.openedBookings[data.company] || {};
      $rootScope.openedBookings[data.company][conf] = $rootScope.openedBookings[data.company][conf] || [];
      var booking = $rootScope.openedBookings[data.company][conf];
      var hasUser = booking.filter(function(user){
        return user._id === data.user._id;
      });
      if(!hasUser.length) $rootScope.openedBookings[data.company][conf].push(data.user);
    }
  });

  Socket.on('leftBooking', function(data){
    if ($rootScope.user) {
      var currentCompany = $rootScope.user.activeCompany();
      var conf = data.response.confirmation;
      // $stateParams.confirmation.replace(/^[a-z]+/i, '')
      $rootScope.openedBookings[data.company] = $rootScope.openedBookings[data.company] || {};
      $rootScope.openedBookings[data.company][conf] = $rootScope.openedBookings[data.company][conf] || [];
      var booking = $rootScope.openedBookings[data.company][conf];
      var index = -1;
      angular.forEach(booking, function(user, i){
        if(angular.equals(user, data.user)) {
          index = i;
        }
      })
      if(index > -1) $rootScope.openedBookings[data.company][conf].splice(index, 1);
    }
  });

  var self = {

      today: $window.moment()

		, clone: function(scope){
				return angular.copy(scope);
			}

    , objectKeys: function(obj){
        var constructor = obj && obj.constructor.name;
        if(obj){
          if(constructor === 'Resource') obj = JSON.parse(angular.toJson(obj));
          if(constructor === 'Object' || obj.constructor.name === 'Object'){
            return Object.keys(obj);
          }
        }
      }

    , searchBookings: function(q){
        Bookings.search(q, function Success(res){
        }, function Error(err){
          console.error(err)
        })
      }

    , activeCompany: function(id){
        $rootScope.user.Company(id);
        $window.location.reload();
      }

    , goto: function(){
        console.log(arguments);
        $state.go(arguments);
      }

    , gotoContacts: function(tab){
        $state.go('contacts', { tab: tab.tab });
      }

    , domain: API.getDomain()

    , go: function(route, params) {
        return $state.go(route, params);
      }

    , changeDay: function(state, int){
        $rootScope.dayWorking = true;
        if(int instanceof Date) {
          $state.go((state || $scope.state).name, { day: $window.moment(int).format('MM-DD-YY') });
          $scope.showCal = false;
        } else {
          var times = Time.shortcuts.times
            , day = (angular.isDefined(times[$scope.day])) ? times[$scope.day] : $window.moment($scope.day, 'MM-DD-YY');

          $scope.day = day.add(int, 'days').format('MM-DD-YY');

          clicktime = $window.moment();

        }

      }

  };

  angular.extend($rootScope, global);

  angular.extend($scope, self);

});

AppCtrl.activeTab = function(stateParams, $rootScope){

  /**
   * Set the active tab based on the passed state.
   */
  $rootScope.tabs.bookings.filter(function(val, key){
    if(val.tab == stateParams.tab){
      val.active = true;
    }
  });

  $rootScope.tabs.contacts.filter(function(val, key){
    if(val.tab == stateParams.tab){
      val.active = true;
    }
  });

}

AppCtrl.authResolver = function ($q, $rootScope, $state, $stateParams, ipCookie, Users, User, Request, $window, $uiRouterGlobals){

  var to = $uiRouterGlobals.transition.$to().self;

  var auth = to.auth
    , deferred = $q.defer()
    , user = $rootScope.user
    , cookie = ipCookie('nvbUser');

  if(auth){
    if(angular.isUndefined(user) && cookie){
      Request.renew(cookie.token);
      Users.get({ id: cookie._id, companyId: cookie.company },
        function Success(res){
          res.user.useCompany = cookie.company;
          $rootScope.user = new User(res.user);
          deferred.resolve(true);
        },
        function Error(err){
          $window.location.href = '/login';
          // var confirm = window.confirm(err.message);
          // if(confirm){
          // }
        }
      )

    } else if(user){
      deferred.resolve(true);
    } else {
      deferred.resolve(false);
    }

  } else {
    deferred.resolve(true);
  }

  return deferred.promise;

};

AppCtrl.filter('toObject', function () {
  return function (array) {
    if(array.constructor.name == 'Array'){
      return array.reduce(function(o, v, i) {
        o[i] = v;
        return o;
      }, {});
    }
  }
});

AppCtrl.filter('parseFloat', function () {
  return function (string, toFixed) {
    string = string || '';
    var float;
    float = parseFloat(string);
    if(toFixed) float.toFixed(toFixed);
    return float;
  }
});

AppCtrl.filter('emptyObject', function () {
  var bar;
  return function (obj) {
    for (bar in obj) {
      if (obj.hasOwnProperty(bar)) {
        return false;
      }
    }
    return true;
  };
});

/**
 * Programatic input focus directive
 * http://plnkr.co/edit/tHguvMVu418P3mJXOp40?p=preview
 */
AppCtrl.directive('autoFocus', function ($timeout) {
  return {
    restrict: 'A',

    link: function ($scope, $element, $attributes) {
      if ($scope.$eval($attributes.autoFocus) !== false) {
        var element = $element[0];

        $timeout(function() {
          $scope.$emit('focus', element);
          element.focus();
        });
      }
    }

  };
});

AppCtrl.directive('print', function ($location, $timeout) {
  return {
    restrict: 'A',
    priority: 100,
    link: function(scope, element, attrs) {

      var previousTitle = document.title;

      var url = $location.protocol() + "://" + $location.host();
      if($location.port()) url += ':' + $location.port();

      element.bind('click', function(evt){
        evt.preventDefault();
        PrintElem(attrs.print);
      });

      function PrintElem(elem) {
        PrintWithIframe($(elem).html());
      }

      function PrintWithIframe(data) {
        if ($('iframe#printf').size() == 0) {
          $('html').append('<iframe id="printf" name="printf" style="width: 100%"></iframe>');

          var styles = $('html').find('link[rel="stylesheet"]').filter(function(i, sheet){
            return sheet.href.match(/main.css|angularPrint.css/);
          }).clone();

          var stylesHTML = "";

          angular.forEach(styles, function(html) {
            stylesHTML += html.outerHTML;
          });

          var printWindow = window.frames["printf"];

          if(attrs.printTitle !== undefined) {
            document.title = attrs.printTitle;
          }

          printWindow.document.write(''
            + '<html><head>' + stylesHTML
            + '<title></title>'
            + '<style>@page { margin: 0.25in 0.25in; -webkit-print-color-adjust: exact; }</style>'
            + '</head><body><div id="printBody" class="container">' + data
            + '</div></body></html>');

          $(printWindow.document).ready(function(){
            $timeout(function(){
              printWindow.print();
              document.title = previousTitle;
              $timeout(function(){
                $('iframe#printf').remove();
              }, 500);
            }, 500);
          });

        }

        return true;
      }
    }
  };
});

AppCtrl.filter('nl2br', function() {
  return function (string) {
    return string.replace(/\n/g, '<br />')
  }
});

AppCtrl.filter('tel', function () {
  return function (tel) {
    if (!tel) { return ''; }

    var value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) return tel;

    var country, city, number;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        number = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        number = value.slice(5);
        break;

      default:
        return tel;
    }

    if (country == 1) country = "";

    number = number.slice(0, 3) + '-' + number.slice(3);

    return (country + " (" + city + ") " + number).trim();
  };
})

AppCtrl.filter('dotToObject', function () {
  return function (string, parent) {
    if (!string) { return ''; }
    return string.toObject(parent)
  };
})

String.prototype.toObject = function( obj, value ) {
  var names = this.split('.');
  // If a value is given, remove the last name and keep it for later:
  var lastName = arguments.length === 2 ? names.pop() : false;
  // Walk the hierarchy, creating new objects where needed.
  // If the lastName was removed, then the last object is not set yet:
  for( var i = 0; i < names.length; i++ ) {
    obj = obj[ names[i] ] = obj[ names[i] ] || {};
  }
  // If a value was given, set it to the last name:
  if( lastName ) obj = obj[ lastName ] = value;
  // Return the last object in the hierarchy:
  return obj;
};
