'use strict';

var UsersCtrl = angular.module('RessieApp')

.value('contacts', true)

.controller('usersCtrl', function ($scope, $rootScope, $state, $stateParams, ipCookie, allowed, Users, $window, $timeout, contacts, User, $filter) {

  if(!allowed) $state.go('login');

  $scope.contacts = undefined;

  if(contacts){

    if(angular.isArray(contacts)) {
      $scope.contacts = contacts;
    } else {
      if(contacts.contacts !== undefined) {
        $scope.pages = {};
        $scope.alphabetize = contacts.alphabetize;
        var count;
        var filter = $stateParams.filter === 'numbers' ? '0-9' : $stateParams.filter;
        var startingNumber = filter === 'all' ? contacts.count : contacts.alphabetize[filter];
        for (var i = 1; i <= Math.ceil(startingNumber / 50); i++) {
          count = (i == Math.ceil(startingNumber / 50)) ? (startingNumber % 50) : 50;
          $scope.pages[i] = count
        };
        $scope.contacts = $filter('toArray')(contacts.contacts);
      } else {
        $scope.groups = contacts.security_groups || $rootScope.groups;
        $scope.contact = contacts.user || {};
        $scope.contact._roles = $scope.contact._roles || {};
        $scope.roles = contacts.roles || $rootScope.roles;
        $scope.contact.is_user = angular.isDefined($scope.contact.api_key) && $scope.contact.api_key !== "";
        $scope.contact.isNew = !angular.isDefined($scope.contact._id);

        angular.forEach($scope.roles, function(role){
          if(typeof $scope.contact._roles[role.name] == 'undefined') $scope.contact._roles[role.name] = {};
        });

        var originalRoles = angular.copy($scope.contact._roles) || {};

      }
    }
  }

  $scope.$watch('contact.roles', function(n, o){

    if(n && $state.current.auth){

      var companyRoles = _.findIndex(n, function(role){
          return role.company == $rootScope.user.activeCompany()._id;
        })
      , roles = {}

      if(companyRoles >= 0){
        angular.forEach(n[companyRoles].rules, function(actions, model){
          $scope.contact._roles[model] = actions;
        });
      }
      
    }
    
  })

  /**
   * Track roles and after "false" reset indeterminate
   */
  $scope.$watch('contact._roles', function(n, o){

    // Loop over the old value of roles
    angular.forEach(o, function(actions, model){
      // If actions aren't empty we want to set overrides
      if(!_.isEmpty(actions)) {

        // Toggle the indeterminate
        angular.forEach(actions, function(action, key){
          // old action was false, next up should be indeterminate
          // but only if the field is covered by the security group priority check
          // If not then it will taggle true again.
          if(!action && !/_indeterminate/.test(key) && $scope.contact._roles[model][key + "_indeterminate"]) {
            if(typeof originalRoles[model][key] == 'undefined') delete $scope.contact._roles[model][key];
          }

        });

      }
    });

  }, true)

  var self = {

      error: {}

    , all: {}

    , users: []

    , listUsers: function() {
        return Users.list({ 'all': true }, function Success(res){
          var users = res.contacts;
          angular.forEach(users, function(user){
            var isEmployee = user.company.map(function(company){
              return company._id;
            }).filter(function(id){
              return id == $scope.user.activeCompany()._id;
            }).length > 0;
            if(!isEmployee) $scope.users.push(new User(user));
          });
        })
      }

    , setPass: function(user){
        user.company = $state.current.company;
        user.user = $state.current.user;
        Users.setPassword(user, function Success(user){
          $window.location.href = '/login';
        }, function Error(err){
          alert(err)
        })
      }

    , resetPass: function(user){
        Users.passwordReset(user, function Success(user){
          alert('reset sent');
        }, function Error(err){
          alert(err)
        })
      }

    , checkEmail: function(e, contact){
        if(contact.email){

          Users.get({ email: contact.email }, function Success(res){

            if(res.user){

              var user = res.user;

              var isUser = _.findIndex(user.company, function(company){
                return company._id == $rootScope.user.activeCompany()._id
              }) >= 0;

              if(!isUser){

                var companies = [];

                angular.forEach(user.company, function(company){
                  companies.push(company._id);
                });

                bootbox.dialog({
                  title: "Add " + user.name +"?",
                  message: "<p><strong>" + user.name + "</strong> exists on another company with the specified email address.</p><p> Would you like to add them to <code>" + $rootScope.user.activeCompany().name + "</code> as well?</p>",
                  buttons: {
                    success: {
                      label: "Add",
                      className: "btn-primary",
                      callback: function() {

                        companies.push($rootScope.user.activeCompany()._id);

                        user.company = companies;

                        Users.update(user,
                          function Success(res){
                            $state.go('contacts', { tab: 'employees' })
                          },
                          function Error(err){
                            var message = (angular.isDefined(err.message)) ? err.message : err;
                            alert(message);
                          }
                        );

                      }
                    },
                    cancel: {
                      label: "Cancel",
                      className: "btn-default",
                      callback: function() {}
                    }

                  }
                });
              
              }
            }
            
          })
        }
      }

    , checkAll: function(model){
      
        var actions = $scope.roles[_.findIndex($scope.roles, function(role){
          return role.name == model
        })].actions;

        angular.forEach(actions, function(action){
          $scope.contact._roles[model][action] = $scope.all[model];
        });

      }

    , remove: function(contact, hard){

        hard = hard || undefined;

        var endpoint;

        bootbox.dialog({
          message: "<p><strong>This can not be undone.</strong> If you still want to remove this contact please type <code>DELETE</code> below:</p><div class='form-group bg-light' style='padding: 26px;margin: 0 -20px -1px -20px;margin-bottom: -35px;'><input type='text' id='result' class='form-control' /></div>",
          title: "Removing <code>" + contact.name +"</code>. Are you sure?",
          buttons: {
            success: {
              label: "Remove",
              className: "btn-primary",
              callback: function() {
                var result = $('#result').val();
                if(result == 'DELETE') {

                  Users.delete({ _id: contact._id, hard: hard },
                    function Success(res){
                      $timeout(function(){
                        contact.removed = true;
                      })
                    },
                    function Error(err){
                    var message = (angular.isDefined(err.message)) ? err.message : err;
                     window.alert(message)
                    }
                  );

                }
              }
            },
            cancel: {
              label: "Cancel",
              className: "btn-default",
              callback: function() {}
            }

          }
        });
      }

    , changeGroup: function(group){

        $scope.contact.groups = $scope.contact.groups || [];

        var index = _.findIndex($scope.contact.groups, function(g){
            return g._id == group._id
          })
          , selected = group.selected;

        if(index < 0 && selected){
          $scope.contact.groups.push(group);
        }

        if(index >= 0 && !selected){
          $scope.contact.groups.splice(index, 1);
        }

      }

    , hasGroup: function(group, nth){

        var hasGroup = false
          , index = "-1";

        angular.forEach($scope.contact.groups, function(item, i){
          if(item){
            
            if(item._id == group._id) {
              hasGroup = true;
              index = i;
            }

          }
        })

        return (nth) ? index.toString() : hasGroup;
      }

    , changePerm: function(model){

      }

      /**
       * Some of the logic in this should be pulled on so as not to fire
       * for each input...
       */
    , inheritsRole: function(model, action){
        var user_groups = _.compact($scope.contact.groups) || []
          , groups = $scope.groups
          , group_details = []
        ;

        angular.forEach(user_groups, function(group){
          group_details.push(group);
        })

        group_details.sort(function(a, b){
          if(a.priority > b.priority) return -1;
          if(a.priority == b.priority) return 0;
          return 1;
        });

        // Loop over assigned groups to get models
        for (var i = 0; i < group_details.length; i++) {

          $scope.contact._roles[model] = $scope.contact._roles[model] || {};

          var models = group_details[i].models

          // Need to get the index of the model we're checking against
          var modelIndex = _.findIndex(models, { name: model });

          if(modelIndex >= 0){

            // with that index we can now ensure that the action is present.
            var actionIndex = _.indexOf(models[modelIndex].actions, action);

            // Security group is missing the model assignment, but bypass is set to true
            // this means that if a model is missing it automatically passes.
            if(actionIndex !== -1) {
              $scope.contact._roles[model][action + "_indeterminate"] = true;
            } else {
              $scope.contact._roles[model][action + "_indeterminate"] = false;
            }
            return ((typeof $scope.contact._roles[model] == 'undefined' || typeof $scope.contact._roles[model][action] == 'undefined') && $scope.contact._roles[model][action + "_indeterminate"])
            
          }

        }

      }

    , auth: function(user){
        var userCookie = ipCookie('nvbUser') || false;
        $rootScope.user = userCookie;
        Users.auth({ email: user.email, password: user.password },
          function Success(res, token){
            $rootScope.user = new User(res);
            userCookie = (userCookie) ? userCookie : {};
            angular.extend(userCookie, { _id: res._id, key: res.api_key, token: token, company: $rootScope.user.activeCompany()._id });
            ipCookie('nvbUser', userCookie, { path: '/' });
            if(res.restricted) {
              $state.go('gifts');
            } else {
              $state.go('home');
            }
          },
          function Error(err){
            $timeout(function(){ $scope.error = err; });
          }
        );
      }

    , submit: function(user){

        var data = angular.copy(user);

        if(data.existing) data = data.existing;

        data.company = typeof data.company !== 'undefined' ? data.company.map(function(company){
          return company._id;
        }) : []; 

        if(data.company.filter(function(id){
          return id == $scope.user.activeCompany()._id;
        }).length == 0) data.company.push($scope.user.activeCompany()._id);

        user.saving = true;

        user.groups = _.compact(user.groups);

        var roles = {};

        if(user.groups.length > 0){
          user.groups = _.map(user.groups, function(group){
            return group._id;
          })
        }

        angular.forEach(user._roles, function(actions, model){

          angular.forEach(actions, function(value, key){
            if(!/indeterminate$/.test(key)){
              roles[model] = roles[model] || {};
              roles[model][key] = value;
            }
          });

        })

        data.roles = roles;

        var endpoint = user.isNew && !user.existing ? 'save' : 'update';

        Users[endpoint](data,
          function Success(res){
            user.saving = false;
            $state.go('contacts', { tab: 'employees' })
          },
          function Error(err){
            user.saving = false;
            var message = (angular.isDefined(err.message)) ? err.message : err;
            alert(message);
          }
        );
      }

  };

  angular.extend($scope, self);

});
