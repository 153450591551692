var DirectivesFiles = angular.module('RessieApp.directives.sidebar', [])

.directive('sidebar', ['$timeout', function($timeout) {
  return {
    restrict: 'EA'
  , templateUrl: 'dir/sidebar/sidebar'
  , replace: false
  // , scope: {}
  , controller: function($scope, $rootScope, Bookings){

      $scope.vips = null;

      $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams){
        $scope.state = angular.extend(toState, toParams);
      });

      $scope.$watch('isOpened', function(n){
        $scope.showCover = n;
      });

      $scope.$watch('showCover', function(n){
        if(!n) $scope.isOpened = false;
      })

      var self = this;

      $scope.getVips = function(){

          Bookings.vips(function Success(res){
            $timeout(function(){
              angular.forEach(res, function(vip, i){
                vip.dates = [
                  moment(vip.day).format('MM/DD/YYYY'),
                  moment(vip.day).format('dddd, MMMM Do YYYY')
                ]
              });
              $scope.vips = res;
            }, 1000)
          }, function Error(err){
            console.error(err);
          });

      }

      angular.extend(self, {
          toggle: function(){
            $rootScope.sidebar.expanded = !$rootScope.sidebar.expanded;
          }
        , offscreen: function(){
            $rootScope.sidebar.offscreen = !$rootScope.sidebar.offscreen;
          }

      });

      angular.extend($scope, self);

    }
  , compile: function(tElement, tAttrs, transclude){

      return function Link(scope, element, attrs, Ctrl){
      }

    }
  }
}]);
