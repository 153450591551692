var router = angular.module('RessieApp');

router.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', function($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider) {

  $locationProvider.html5Mode(true)

  $urlRouterProvider.otherwise('/404');

  $stateProvider

    .state('404', {
        url: '/404'
      , templateUrl: 'views/404'
    })

    .state('home', {
        url: '/'
      , auth: true
      , resolve: {
            'allowed': AppCtrl.authResolver
        }
      , templateUrl: 'views/home'
      , controller: 'pagesCtrl'
    })

    .state('search', {
        url: '/search?q&page'
      , auth: true
      , resolve: {
            'allowed': AppCtrl.authResolver
          , 'bookings': function(allowed, $state, $q, $stateParams, Bookings, $rootScope){
              var deferred = $q.defer();
              $rootScope.searchQ = decodeURI($stateParams.q.replace('+', ' '));

              $stateParams.page = $stateParams.page || 1;

              Bookings.search({ q: $stateParams.q, page: $stateParams.page }, function Success(res){
                deferred.resolve(res);
              }, function Error(err){
                console.error(err);
              });
              return deferred.promise;
            }
        }
      , templateUrl: 'views/search'
      , controller: 'reservationsCtrl'
    })

    .state('calendar', {
        url: '/calendar/:month'
      , auth: true
      , resolve: {
            'allowed': AppCtrl.authResolver
          , 'date' : function(allowed, $q, $rootScope, Calendar, $stateParams){
              var date = $stateParams.month.replace('/', '') || null;
              var deferred = $q.defer();
              var month;


              if(date || moment(date).isValid()){
                month = moment(date, 'MM-DD-YYYY').startOf('month')
              } else {
                month = moment(new Date()).startOf('month');
              }

              Calendar.month(month.format('MM-DD-YYYY'), function Success(events){
                $rootScope.calendar = events;
                deferred.resolve({
                  month: month,
                  events: events
                });
              }, function Error(err){});

              return deferred.promise;
            }
        }
      , templateUrl: 'views/calendar'
      , controller: 'calendarCtrl'
    })

    .state('thisMonth', {
        url: '/calendar'
      , auth: true
      , resolve: {
            'allowed': AppCtrl.authResolver
          , 'date' : function(allowed, $q, $rootScope, Calendar, $stateParams){

              var deferred = $q.defer();
              var month;

              month = moment(new Date()).startOf('month');

              Calendar.month(month.format('MM-DD-YYYY'), function Success(events){
                $rootScope.calendar = events;
                deferred.resolve({
                  month: month,
                  events: events
                });
              }, function Error(err){});

              return deferred.promise;
            }
        }
      , templateUrl: 'views/calendar'
      , controller: 'calendarCtrl'
    })

    .state('reports', {
      url: '/reports',
      auth: true,
      params: {
        report: 'home',
        start: moment().add(1, 'day').format('MM-DD-YY'),
        end: moment().add(1, 'day').format('MM-DD-YY')
      },
      resolve: {
        'allowed': AppCtrl.authResolver,
      },
      templateUrl: 'comp/reports/reports',
      controller: 'ReportHome'
    })

    .state('reports.report', {
      url: '/:report',
      auth: true,
      params: {
        start: moment().add(1, 'day').format('MM-DD-YY'),
        end: moment().add(1, 'day').format('MM-DD-YY')
      },
      resolve: {
        'allowed': AppCtrl.authResolver,
      },
      templateUrl: 'comp/reports/reports',
      controller: 'ReportHome'
    })

    .state('reports.report.range', {
      url: '/:start/:end',
      auth: true,
      resolve: {
        'allowed': AppCtrl.authResolver
      },
      templateUrl: 'comp/reports/reports',
      controller: 'ReportHome'
    })

    .state('dashboard', {
        url: '/dashboard'
      , auth: true
      , resolve: {
          'allowed': AppCtrl.authResolver,
          'flights': function(allowed, $q, Flights, $rootScope){
            var deferred = $q.defer();
            Flights.byPilot({ id: $rootScope.user._id }, function Win(res){
              deferred.resolve(res);
            })
            return deferred.promise;
          }
        }
      , templateUrl: 'views/settings/dash'
      , controller: 'flightsCtrl'
    })

    .state('me', {
        url: '/me'
      , auth: true
      , resolve: {
          'allowed': AppCtrl.authResolver,
          'company': function(){},
          'balloons' : function(){},
          'packages' : function(){},
          'zones' : function(){},
          'products' : function(){},
          'pilots' : function(){}
        }
      , templateUrl: 'views/settings/me'
      , controller: 'settingsCtrl'
    })

    .state('flight', {
      url: '/flight/:id',
      auth: true,
      resolve: {
        'allowed': AppCtrl.authResolver,
        flight: function(allowed, Flights, $q, $stateParams){
          var deferred = $q.defer();
          Flights.get({ id: $stateParams.id }, function Win(res){
            deferred.resolve(res);
          }, function Lose(err){
          });
          return deferred.promise;
        }
      },
      templateUrl: 'views/flights/edit',
      controller: 'flightsCtrl'
    })

    .state('flightPhotos', {
      url: '/photos/:id',
      resolve: {
        'allowed': function(){ return true },
        flight: function(allowed, Flights, $q, $stateParams){
          var deferred = $q.defer();
          Flights.photos({ id: $stateParams.id }, function Win(res){
            deferred.resolve(res);
          }, function Lose(err){
          });
          return deferred.promise;
        }
      },
      templateUrl: 'views/flights/photos',
      controller: 'flightsCtrl'
    })

    .state('editBalloon', {
        url: '/balloon/:id'
      , auth: true
      , resolve: {
          'allowed': AppCtrl.authResolver,
          'products' : function(){},
          'company': function(){},
          'pilots': function(allowed, Users, $q){
            var deferred = $q.defer();
            Users.ofType('pilot', function Success(pilots){
              deferred.resolve(pilots);
            });
            return deferred.promise;
          },
          'balloons' : function(pilots, Balloons, Users, $q, $stateParams){
            var deferred = $q.defer();

            if($stateParams.id == 'new'){
              deferred.resolve({});
            } else {

              Balloons.one($stateParams.id,
                function Success(res){
                  deferred.resolve(res);
                },
                function Error(err){
                  console.error(err);
                  deferred.reject();
                }
              );

            }

            return deferred.promise;

          },
          'packages' : function(){},
          'zones' : function(){}
        }
      , templateUrl: 'views/settings/forms/balloons'
      , controller: 'settingsCtrl'
    })

    .state('editProduct', {
        url: '/product/:id'
      , auth: true
      , resolve: {
          'allowed': AppCtrl.authResolver,
          'company': function(){},
          'products' : function(allowed, Products, $q, $stateParams){
            var deferred = $q.defer();

            if($stateParams.id == 'new'){
              deferred.resolve({ parents: [], children: [], siblings: [] });
            } else {

              Products.one($stateParams.id,
                function Success(res){
                  deferred.resolve(res);
                },
                function Error(err){
                  console.error(err);
                  deferred.reject();
                }
              );

            }

            return deferred.promise;
          },
          'pilots': function(){},
          'balloons' : function(){},
          'packages' : function(){},
          'zones' : function(){}
        }
      , templateUrl: 'views/settings/forms/product'
      , controller: 'settingsCtrl'
    })

    .state('editPackage', {
        url: '/package/:id'
      , auth: true
      , resolve: {
          'allowed': AppCtrl.authResolver,
          'company': function(){},
          'pilots': function(){},
          'balloons' : function(){},
          'products' : function(){},
          'packages' : function(allowed, $q, $stateParams, Packages){

            var deferred = $q.defer();

            if($stateParams.id == 'new'){
              deferred.resolve({
                vipPricing: []
              });
            } else {

              Packages.one($stateParams.id,
                function Success(res){
                  res.vipPricing = res.vipPricing || [];
                  deferred.resolve(res);
                },
                function Error(err){
                  console.error(err);
                  deferred.reject();
                }
              );

            }

            return deferred.promise;

          },
          'zones' : function(){}
        }
      , templateUrl: 'views/settings/forms/packages'
      , controller: 'settingsCtrl'
    })

    .state('editZone', {
        url: '/zone/:id'
      , auth: true
      , resolve: {
          'allowed': AppCtrl.authResolver,
          'company': function(){},
          'pilots': function(){},
          'balloons' : function(){},
          'packages' : function(){},
          'products' : function(){},
          'zones' : function($q, $stateParams, Zones){

            var deferred = $q.defer();

            if($stateParams.id == 'new'){
              deferred.resolve({});
            } else {

              Zones.one($stateParams.id,
                function Success(res){
                  deferred.resolve(res);
                },
                function Error(err){
                  console.error(err);
                  deferred.reject();
                }
              );

            }

            return deferred.promise;


          }
        }
      , templateUrl: 'views/settings/forms/zones'
      , controller: 'settingsCtrl'
    })

    .state('admin', {
        url: '/admin/:tab'
      , auth: true
      , resolve: {
          'allowed': AppCtrl.authResolver,
          'pilots' : function(){},
          'tab': function(allowed, $stateParams) {
            return $stateParams.tab.replace('/', '');
          },
          'company' : function(allowed, Companies, $q, $rootScope){
            var deferred = $q.defer();

            Companies.one($rootScope.user.useCompany,
              function Success(res){
                var array = [];
                var prepaids = res.prepaids.prices;
                angular.forEach(prepaids, function(val, i){
                  array.push({ 'value' : val });
                })

                if(res.verification){
                  angular.forEach(res.verification.fields_needed, function(field){
                    field.toObject(res.verification, null);
                  })
                }

                res.prepaids.prices = array;
                deferred.resolve(res);
              },
              function Error(err){
                console.error(err);
                deferred.reject();
              }
            );

            return deferred.promise;
          },
          'balloons' : function(company, Balloons, $q){

            var deferred = $q.defer();

            Balloons.get(
              function Success(res){
                deferred.resolve(res);
              },
              function Error(err){
                console.error(err);
                deferred.reject();
              }
            );

            return deferred.promise;
          },
          'packages' : function(allowed, Packages, $q){

            var deferred = $q.defer();

            Packages.get(
              function Success(res){
                deferred.resolve(res);
              },
              function Error(err){
                console.error(err);
                deferred.reject();
              }
            );

            return deferred.promise;
          },
          'zones' : function(allowed, Zones, $q){

            var deferred = $q.defer();

            Zones.get(
              function Success(res){
                deferred.resolve(res);
              },
              function Error(err){
                console.error(err);
                deferred.reject();
              }
            );

            return deferred.promise;
          },
          'products' : function(allowed,Products, $q){

            var deferred = $q.defer();

            Products.nested(
              function Success(res){
                deferred.resolve(res);
              },
              function Error(err){
                console.error(err);
                deferred.reject();
              }
            );

            return deferred.promise;
          },
          'templates' : function(allowed, EmailTemplates, $q){

            var deferred = $q.defer();

            EmailTemplates.list({},
              function Success(res){
                deferred.resolve(res);
              },
              function Error(err){
                console.error(err);
                deferred.reject();
              }
            );

            return deferred.promise;
          }

        }
      , templateUrl: 'views/settings/admin'
      , controller: 'settingsCtrl'
      , onEnter: function($rootScope, $state){
          if(!$rootScope.user.isAdmin()){
            $state.go('settings')
          }
        }
    })

    .state('login', {
        url: '/login'
      , auth: false
      , resolve: {
            'allowed': AppCtrl.authResolver
          , 'contacts' : function(){}
        }
      , templateUrl: 'views/login'
      , controller: 'usersCtrl'
    })

    .state('logout', {
        url: '/logout'
      , auth: false
      , resolve: {
            'logout': function(ipCookie, $rootScope, $window){
              ipCookie.remove('nvbUser');
              delete $rootScope.user;
              $window.location.href = '/login';
            }
        }
    })

    .state('contacts', {
      url: '/contacts/:tab/:filter/:page',
      templateUrl: 'views/contacts/contacts',
      params: {
        filter: { value: 'all' },
        page: { value: '1' }
      },
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'contacts': function(allowed, Contacts, Users, $stateParams, $q, $rootScope){

            $stateParams.page = parseInt($stateParams.page) || 1;

            if(!allowed) $state.go('login');
            $rootScope.contactsWorking = true;
            var deferred = $q.defer()
              , singularize = $stateParams.tab.replace(/s$/, '');

            function Done(){
              $rootScope.contactsWorking = false;
              var tabs = $rootScope.tabs.contacts;
              angular.forEach(tabs, function(tab){
                tab.active = false;
              })
              var tab = tabs.find(function(tab){
                return tab.tab === $stateParams.tab
              });
              tab.active = true;
            }

            if($stateParams.tab == 'hotels'){
              Contacts.byType(singularize, {
                count: 50,
                page: $stateParams.page,
                filter: $stateParams.filter
              },
                function Success(res){
                  Done();
                  deferred.resolve(res);
                },
                function Error(err){
                  $rootScope.contactsWorking = false;
                  deferred.reject();
                }
              );
            }

            if($stateParams.tab == 'employees'){
              Users.list({
                count: 50,
                page: $stateParams.page,
                filter: $stateParams.filter
              },
                function Success(res){
                  Done();
                  deferred.resolve(res);
                },
                function Error(err){
                  $rootScope.contactsWorking = false;
                  deferred.reject();
                }
              );
            }

            if($stateParams.tab == 'partners'){
              Contacts.byType(singularize, {
                count: 50,
                page: $stateParams.page,
                filter: $stateParams.filter
              },
                function Success(res){
                  Done();
                  deferred.resolve(res);
                },
                function Error(err){
                  $rootScope.contactsWorking = false;
                  deferred.reject();
                }
              );
            }

            return deferred.promise;

          }
      },
      controllerProvider: function($stateParams){

        switch($stateParams.tab) {
          case 'employees':
            return 'usersCtrl';
            break;
          default:
            return 'contactsCtrl';
        }

      }
    })

    .state('employeeEdit', {
      url: '/employee/:id',
      templateUrl: 'views/contacts/forms/employee',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'contacts': function(allowed, $state, Users, $stateParams, $q){

            if(!allowed) $state.go('login');

            var deferred = $q.defer();
            if($stateParams.id == 'new'){

              var obj = {
                "user" : {
                  "type": {
                    "hospitality": false,
                    "driver": false,
                    "chase": false,
                    "pilot": false,
                    "manager": false,
                    "agent": false
                  }
                }
              };

              deferred.resolve(obj);
            } else {

              Users.get({ id: $stateParams.id },
                function Success(res){
                  deferred.resolve(res);
                },
                function Error(err){
                  console.error(err);
                  deferred.reject();
                }
              );
            }

            return deferred.promise;

          }
      },
      controller: 'usersCtrl'
    })

    .state('employeeVerify', {
        url: '/{action:verify|reset}/:company/:user'
      , auth: false
      , resolve: {
          'contacts': function($q, Users, $stateParams){
            var deferred = $q.defer();

            Users.forVerification({ user: $stateParams.user, company: $stateParams.company },
              function Success(res){
                deferred.resolve(res);
              },
              function Error(err){
                console.error(err);
                deferred.reject();
              }
            );

            return deferred.promise;
          }
        }
      , templateUrl: 'views/contacts/verify'
      , controller: 'usersCtrl'
    })

    .state('contactEdit', {
      url: '/contact/:id',
      templateUrl: 'views/contacts/form',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'contacts': function(allowed, $state, Contacts, Packages, $stateParams, $q, $filter){

            if(!allowed) $state.go('login');

            var deferred = $q.defer();

            if($stateParams.id == 'hotel' || $stateParams.id == 'concierge' || $stateParams.id == 'partner'){
              if($stateParams.id == 'concierge'){
                Contacts.byType('hotel',
                  function Success(res){
                    deferred.resolve({ meta: { type: $stateParams.id }, extras: { hotels : res.contacts }})
                  },
                  function Error(err){
                    deferred.reject();
                  }
                );
              } else if($stateParams.id == 'partner') {
                Packages.get(
                  function Success(res){
                    deferred.resolve({ meta: { type: $stateParams.id }, extras: { packages : $filter('toArray')(res) }})
                  },
                  function Error(err){
                    deferred.reject();
                  }
                );

              } else {

                Contacts.get('new',
                  function Success(res){
                    var data = res;
                    data.meta = data.meta || {};
                    data.meta.type = $stateParams.id;
                    deferred.resolve(data);
                  },
                  function Error(err){
                    console.error(err);
                    deferred.reject();
                  }
                );

              }

            } else {

              Contacts.get($stateParams.id,
                function Success(res){
                  if(res.meta.type == 'partner'){
                    Packages.get(
                      function Success(packages){
                        res.extras.packages = $filter('toArray')(packages);
                        deferred.resolve(res)
                      },
                      function Error(err){
                        deferred.reject();
                      }
                    );

                  } else {
                    deferred.resolve(res);
                  }
                },
                function Error(err){
                  console.error(err);
                  deferred.reject();
                }
              );

            }

            return deferred.promise;

          }
      },
      controllerProvider: function($stateParams){
        return 'contactsCtrl';
      }
    })

    .state('contactEdit.assign', {
      url: '/:parent',
      templateUrl: 'views/contacts/form',
      auth: true,
      resolve: {
        'allowed': AppCtrl.authResolver
        , 'contacts': function (allowed, $state, Contacts, Packages, $stateParams, $q, $filter) {
          if (!allowed) $state.go('login');

          var deferred = $q.defer();

          $stateParams.parentId = $stateParams.parent;

          if ($stateParams.id == 'hotel' || $stateParams.id == 'concierge' || $stateParams.id == 'partner') {
            if ($stateParams.id == 'concierge') {
              Contacts.byType('hotel',
                function Success(res) {
                  console.log($stateParams.parent)
                  deferred.resolve({ meta: { type: $stateParams.id }, parentId: $stateParams.parent, extras: { hotels: res.contacts } })
                },
                function Error(err) {
                  deferred.reject();
                }
              );
            } else if ($stateParams.id == 'partner') {
              Packages.get(
                function Success(res) {
                  deferred.resolve({ meta: { type: $stateParams.id }, extras: { packages: $filter('toArray')(res) } })
                },
                function Error(err) {
                  deferred.reject();
                }
              );

            } else {

              Contacts.get('new',
                function Success(res) {
                  var data = res;
                  data.meta = data.meta || {};
                  data.meta.type = $stateParams.id;
                  deferred.resolve(data);
                },
                function Error(err) {
                  console.error(err);
                  deferred.reject();
                }
              );

            }

          } else {

            Contacts.get($stateParams.id,
              function Success(res) {
                if (res.meta.type == 'partner') {
                  Packages.get(
                    function Success(packages) {
                      res.extras.packages = $filter('toArray')(packages);
                      deferred.resolve(res)
                    },
                    function Error(err) {
                      deferred.reject();
                    }
                  );

                } else {
                  deferred.resolve(res);
                }
              },
              function Error(err) {
                console.error(err);
                deferred.reject();
              }
            );

          }

          return deferred.promise;

        }
      },
      controllerProvider: function ($stateParams) {
        return 'contactsCtrl';
      }
    })

    .state('notifications', {
      url: '/notifications',
      templateUrl: 'views/notifications',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'notifications': function(allowed, Notes, $q, $rootScope, Notifications, PendingBookings, $state){
            if(!allowed) $state.go('login');
            var deferred = $q.defer();

            Notifications.get({},
              function Success(response){
                if(response.bookings.length > 0) $rootScope.previous.bookingId = response.bookings[0]._id;
                PendingBookings.setPendings(response.bookings);
                deferred.resolve(response);
              },
              function Error(err){}
            );
            return deferred.promise;
          }

      },
      onExit: function($rootScope){
        $rootScope.previous.bookingId = null;
      },
      controller: 'notificationsCtrl'
    })

    .state('day', {
      url: '/day',
      templateUrl: 'views/day/day',
      params: {
        day: 'tomorrow'
      },
      auth: true,
      controller: function($scope, event, Events) {
        $scope.event = event;
        console.info('deprecated: updateCapacity method in routes, under day $state');
        $scope.updateCapacity = function (event, save) {
          if (!save) {
            event.capacity.remaining = event.capacity.total - event.capacity.booked;
          } else {
            Events.update({ _id: event._id, capacity: event.capacity.total },
              function Success(res) {
                $scope.editCapacity = false;
              },
              function Error(err) {}
            );
          }
        }
      },
      resolve: {
        'allowed': AppCtrl.authResolver,
        'date': function (allowed, Time, $rootScope, $stateParams){
          var times = Time.shortcuts.times;
          var momentDate = (angular.isDefined(times[$stateParams.day])) ? moment(times[$stateParams.day]) : moment($stateParams.day, 'MM-DD-YY');
          var date = $rootScope.day = momentDate.format('MM-DD-YY');
          return date;
        },
        'event': function (allowed, date, $window, Events, $state, $q) {
          if (!allowed) $state.go('login');
          var deferred = $q.defer()
          Events.get(date, true, function (response) {
            deferred.resolve(response.meta !== undefined ? response.event : response);
          });
          return deferred.promise;
        }
      }
    })

    .state('day.oldview', {
      url: '/legacy/:day',
      views: {
        'day': {
          templateUrl: 'views/day/bookings',
          controller: 'reservationsCtrl'
        }
      },
      params: {
        tab: 'bookings',
        day: 'tomorrow'
      },
      auth: true,
      resolve: {
        'allowed': AppCtrl.authResolver
        , 'bookings': function (allowed, $window, Bookings, $stateParams, $state, $q, Time, $rootScope) {

          if (!allowed) $state.go('login');

          var deferred = $q.defer()
            , times = Time.shortcuts.times;

          var date = (times[$stateParams.day] !== undefined) ? $window.moment(times[$stateParams.day]).format('MM-DD-YY') : $window.moment($stateParams.day, 'MM-DD-YY').format('MM-DD-YY');

          Bookings.get({ day: date }, function (response) {
            $rootScope.dayWorking = false;
            deferred.resolve(response)
          });

          return deferred.promise;

        }
      },
    })

    .state('day.pickups', {
      url: '/pickups/:day',
      params: {
        tab: 'pickups',
        day: 'tomorrow'
      },
      auth: true,
      resolve: {
        'allowed': AppCtrl.authResolver,
        'pickups': function (allowed, date, Pickups, $q, $rootScope) {
          if (!allowed) $state.go('login');
          var deferred = $q.defer();
          Pickups.forDay(date, function (response) {
            $rootScope.dayWorking = false;
            deferred.resolve(response);
          });
          return deferred.promise;
        }
      },
      views: {
        'day': {
          controller: 'pickupsCtrl',
          templateUrl: 'views/day/pickups',
        }
      }
    })

    .state('day.balloons', {
      url: '/balloons/:day',
      views: {
        'day': {
          templateUrl: 'views/day/balloons',
          controller: 'eventsCtrl'
        }
      },
      params: {
        tab: 'balloons',
        day: 'tomorrow'
      },
      auth: true,
      resolve: {
        'allowed': AppCtrl.authResolver,
        'event': function (allowed, date, Events, $q, $rootScope) {
          if (!allowed) $state.go('login');
          var deferred = $q.defer();
          Events.get(date, function (response) {
            $rootScope.dayWorking = false;
            deferred.resolve(response)
          });
          return deferred.promise;
        }
      }
    })

    .state('editBooking', {
      url: '/booking/:confirmation',
      templateUrl: 'views/booking/form',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'bundles': function(allowed, $q, $timeout, Bundles) {
            var deferred = $q.defer();

            $timeout(function () {
              Bundles.list(
                function Success(response) {
                  response.push({
                    _id: undefined,
                    name: '-- None --'
                  });
                  deferred.resolve(response);
                },
                function Error(err) {
                  deferred.resolve(false)
                }
              );
            })

            return deferred.promise;
          }
        , 'bookings': function(allowed, Bookings, $q, $stateParams, Pricing, Reservation){

            if(!allowed) $state.go('login');

            var deferred = $q.defer();
            Bookings.confirmation($stateParams.confirmation,
              function Success(response){

                var booking = response;

                booking.day = moment.utc(booking.day).hours(0).format('MM/DD/YYYY');

                /**
                 * Things are all set, pass to the form
                 */
                // if(booking.meta.weights && booking.meta.weights.length){
                //   var weights = [];
                //   angular.forEach(booking.meta.weights, function(weight, i){
                //     var data = {};
                //     data[i] = weight;
                //     weights.push(data);
                //   })
                //   booking.meta.weights = weights;
                // }
                booking.pricing = new Pricing(booking);
                if(booking.status === 'waiting') booking.waiting = true;
                booking = Reservation.fill(booking);
                deferred.resolve(booking);

              },
              function Error(err){
                deferred.reject(err);
              }
            );

            return deferred.promise;

          }
      },
      onEnter: function($rootScope, $state, Socket, $stateParams){
        Socket.emit('openedBooking', { confirmation: $stateParams.confirmation.replace(/^[a-z]+/i, '') })
      },
      onExit: function($rootScope, $state, Socket, $stateParams){
        Socket.emit('leftBooking', { confirmation: $stateParams.confirmation.replace(/^[a-z]+/i, '') })
      },
      controller: 'reservationsCtrl'
    })

    .state('publicBookingForm', {
      url: '/book',
      resolve: {
        'allowed': function () { return true },
      },
      templateUrl: 'views/booking/public_form',
      controller: 'ReservationComponenets'
    })

    .state('booking', {
      url: '/booking/:action/:day/:gift',
      params: {
        gift: { value: '', squash: true }
      },
      templateUrl: 'views/booking/form',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'gift' : function(allowed, $q, Gifts, $stateParams, $timeout, $rootScope){

            var giftId = $stateParams.gift !== "" ? $stateParams.gift.replace(/^\//, '') : null;

            var deferred = $q.defer();

            if($stateParams.action == 'add' && giftId){
              $timeout(function(){
                Gifts.get(giftId,
                  function Success(response){
                    deferred.resolve(response);
                  },
                  function Error(err){
                    deferred.resolve(false)
                  }
                );
              })
            } else {
              deferred.resolve(false);
            }

            return deferred.promise;

          }
        , 'bundles': function(allowed, $q, $timeout, Bundles) {
            var deferred = $q.defer();

            $timeout(function () {
              Bundles.list(
                function Success(response) {
                  response.push({
                    _id: undefined,
                    name: '-- None --'
                  });
                  deferred.resolve(response);
                },
                function Error(err) {
                  deferred.resolve(false)
                }
              );
            })

            return deferred.promise;
          }
        , 'bookings': function(allowed, gift, Bookings, $q, $stateParams, Pricing, $rootScope){

            // Pricing.reset();

            if(!allowed) $state.go('login');

            var deferred = $q.defer();

            var data = {}
            data.reservation = {};
            Bookings.confirmation({ day: $stateParams.day },
              function Success(response){
                if(gift) {

                  var recipient = gift.contact.recipient;

                  data = {

                    reservation: {
                      day: null,
                      first_name: recipient.name,
                      last_name: '',
                      email: recipient.email || null,
                      phone: recipient.phone || null,
                      notes: {
                        internal: gift.notes || null
                      },
                      payment: { gifts: [ gift ] }
                    }

                  }
                }

                data.reservation.pricing = new Pricing(data.reservation || {});
                data.reservation.day = moment.utc($stateParams.day, 'MM-DD-YY').format('MM/DD/YYYY');
                if(!response.meta.meetingTime) {
                  var error = 'No Meeting Time Defined for ' + moment($stateParams.day).format('MMMM Do, YYYY') + ', Closed.';
                  alert(error);
                  deferred.reject(error);
                }
                deferred.resolve(angular.extend(data, response))
              },
              function Error(err){
                deferred.reject(err);
              }
            );

            return deferred.promise;

          }
      },
      controller: 'reservationsCtrl'
    })

    .state('pending', {
      url: '/pending',
      templateUrl: 'views/booking/pending',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'bookings': function(){}
      },
      controller: 'reservationsCtrl'
    })

    /**
     * Gifts
     */

    .state('giftsCreate', {
      url: '/gifts/create',
      templateUrl: 'views/gifts/form',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'gifts': function(){}
        , 'gift': function(allowed, $q){
            if(!allowed) $state.go('login');
            var deferred = $q.defer();
            deferred.resolve({
              amount: 239,
              electronic: 'false',
              type: 'card',
              state: 'Pending',
              sendTo: 'recipient',
              cards: [],
              contact: {
                purchaser: {
                  send_to: false
                },
                recipient: {
                  send_to: true
                }
              }
            })
            return deferred.promise;
          }
      },
      controller: 'giftsCtrl'
    })

    .state('giftsEdit', {
      url: '/gift/:id',
      templateUrl: 'views/gifts/form',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'gifts': function(){}
        , 'gift': function(allowed, $rootScope, Gifts, $q, $stateParams, GuestCard, $state, $window){

            if(!allowed) $state.go('login');

            var deferred = $q.defer();

            // Backwards Compatibility
            function sendTo(gift){
              var isNew = !angular.isDefined(gift._id);
              var purchaser = angular.isDefined(gift.contact.purchaser) && gift.contact.purchaser.send_to;
              var recipient = angular.isDefined(gift.contact.recipient) && gift.contact.recipient.send_to;

              if(isNew) return 'recipient';

              if(!isNew){
                if(!purchaser && !recipient){
                  if(angular.isDefined(gift.contact.purchaser.shipping_address)) {
                    return 'purchaser';
                  } else {
                    return 'recipient';
                  }
                } else {
                  return (purchaser) ? 'purchaser' : 'recipient';
                }
              }
            }

            // Map cards
            Gifts.get($stateParams.id,
              function Success(response){

                response.preexisting = response.preexisting !== undefined || response.cards.length == 0;

                response.sendTo = sendTo(response);
                response.electronic = response.electronic.toString();

                response.sent = (response.sent) ? $window.moment.utc(response.sent).format('MM/DD/YYYY') : '';
                response.send_on = (response.send_on) ? $window.moment.utc(response.send_on).format('MM/DD/YYYY') : '';
                response.created = $window.moment(response.created);

                /**
                 * Prepaid state definition
                 */
                if(response.expiration) {
                  var expiration = $window.moment(response.expiration);
                  response.expiration = expiration.format('MM/DD/YYYY');
                  if(expiration.diff($rootScope.today, 'days') < 0) response.state = 'Expired';
                }

                if(!response.state){
                  if(response.redeemed) {
                    var redeemed = $window.moment(response.redeemed);
                    response.redeemed = redeemed.format('MM/DD/YYYY');
                    response.state = 'Redeemed';
                  } else {
                    response.state = angular.isDefined(response.code) ? 'Active' : 'Pending';
                  }
                }

                angular.forEach(response.cards, function(card, index){
                  response.cards[index] = new GuestCard(card);
                });

                deferred.resolve(response);

              },
              function Error(err){
                console.error(err);
                deferred.reject(err);
              }
            );

            return deferred.promise;

          }
      },
      controller: 'giftsCtrl'
    })

    .state('gifts', {
      url: '/gifts/:page',
      templateUrl: 'views/gifts/list',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'gifts': function(allowed, $rootScope, Gifts, $q, $stateParams, $state){

            if(!allowed) $state.go('login');

            $rootScope.gifts = {};

            $stateParams.page = parseInt($stateParams.page);

            Gifts.list({ page: $stateParams.page, count: 50 },
              function Success(response){
                // deferred.resolve(response)
                $rootScope.gift = {};
                $rootScope.pendingGifts = response.results.filter(function(gift){ return gift.code == null || gift.code == "" }).length;
                $rootScope.gifts = response;
                $rootScope.lastGift = response.results.length > 0 ? response.results[0]._id : null;
              },
              function Error(err){
                // deferred.reject(err);
              }
            );

            // return deferred.promise;

          }
        , 'gift': function(){}
      },
      onExit: function($rootScope){
        $rootScope.previous.giftId = null;
      },
      controller: 'giftsCtrl'
    })

    .state('allGifts', {
      url: '/gifts',
      templateUrl: 'views/gifts/list',
      auth: true,
      resolve: {
          'allowed': AppCtrl.authResolver
        , 'gifts': function(allowed, $rootScope, Gifts, $q, $stateParams, $state){

            if(!allowed) $state.go('login');

            $rootScope.gifts = {};

            Gifts.list({ all: true },
              function Success(response){
                // deferred.resolve(response)
                $rootScope.gift = {};
                $rootScope.gifts = response;
                $rootScope.lastGift = response.results[0]._id
              },
              function Error(err){
                // deferred.reject(err);
              }
            );

            // return deferred.promise;

          }
        , 'gift': function(){}
      },
      controller: 'giftsCtrl'
    })

}])
