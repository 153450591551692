angular.module('RessieApp.directives.copy', [])

.directive('copy', function($window, $parse, $document, $timeout){
  
  return {
      restrict: 'A'
    , scope: true
    , compile: function(tElement, tAttrs) {

        return function Link(scope, elm, attr) {

          scope.error = false;
          scope.success = false;

          document.addEventListener('copy', function(e){
            var target = e.target;
            if(target.id.replace(/copy/, '') === scope.$id.toString()){
              if(target.className === 'copyable') {
                scope.success = true;
                $timeout(function(){
                  scope.success = false;
                }, 2000);
                scope.error = false;
              } else {
                console.error('didn\'t work', e.target);
                scope.error = true;
                scope.success = false;
              }
            }
            scope.$apply();
          });

          var newNode = document.createElement('div');
          newNode.className = 'copyable';
          newNode.id = 'copy' + scope.$id;
          newNode.style.position = 'absolute';

          newNode.style.zIndex = '-1';
          newNode.style.opacity = 0;
          
          var parent = elm[0].parentNode;
          parent.insertBefore(newNode, elm[0]);

          function copyVal(e){

            window.getSelection().removeAllRanges();

            var range = document.createRange();
            range.setStart(newNode, 0);
            range.setEnd(newNode, newNode.childNodes.length);

            window.getSelection().addRange(range);

            try {
              document.execCommand('copy');
            } catch (err) {
              console.error(err);
            }


          }

          elm[0].addEventListener('click', copyVal, false);

          attr.$observe('copy', function(val){
            $timeout(function(){
              try {
                var element = document.querySelector(val);
                if (element) {
                  newNode.innerHTML = element.innerHTML;
                } else {
                  newNode.innerHTML = val || '';
                }
              } catch (error) {
                newNode.innerHTML = val || '';
              }
            })
          });

        }

      }
  };

});