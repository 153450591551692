var APIEvents = angular.module('RessieApp.services.api.events', [])

.service('Events', function(Request, API, $rootScope, $state, $timeout, $window){

  var self = this;

  self.queued = {};
  self.active = {};

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        get: {
            method: 'GET'
          , url: '/event/day/:day'
          , isArray: false
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , range: {
            method: 'GET'
          , url: '/events/range/:start/:end'
          , isArray: true
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , update: {
            method: 'PUT'
          , url: '/event/:id'
          , isArray: false
          , params: { id : '@_id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , addNote: {
            method: 'POST'
          , url: '/event/:id/note'
          , isArray: false
          , params: { id : '@id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , hideNote: {
            method: 'DELETE'
          , url: '/note/:id'
          , isArray: false
          , params: { id : '@id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource(endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) $timeout(function(){
          var confirm = window.confirm(response.error);
          if(confirm){
            $window.location.href = '/login';
          }
          return error(response);
        });
      } else {
        Request.renew(token);
        return success(response, token);
      }

    });
  };

  self.get = function(day, light, success, error) {

    var payload = {
      day: day
    };

    if(light !== undefined) {
      if(light.constructor.name === 'Function') {
        payload.meta = true;
        error = success;
        success = light;
        light = false;
      } else {
        payload.light = true;
      }
    }

    self.request('get',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.range = function(start, end, success, error) {

    var payload = {
      start: start,
      end: end
    };

    self.request('range',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.update = function(payload, success, error) {

    self.request('update',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.addNote = function(payload, success, error) {

    self.request('addNote',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.hideNote = function(payload, success, error) {

    if(payload.constructor.name === 'Object') payload = payload._id;

    self.request('hideNote',
      {
        payload: {
          id: payload
        }
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

});
