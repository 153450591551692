'use strict';

var FilesCtrl = angular.module('RessieApp')

.controller('filesCtrl', function ($scope, $rootScope, $state, Files, Upload, API, $timeout) {

  var self = {

    error: null,

    getSignature: function($files, data, target){
      $scope.files = $files;

      var fileName;
      angular.forEach($files, function(file, nextFile){
        fileName = data.flight ? 'flights/' + data.flight +'/' + file.name : file.name;
        Files.getSig({ 'file-name' : fileName, 'file-type' : file.type }, function Success(res){
          file.signature = res;
          self.uploadToS3(file, data, target);
        }, function Error(err){
          console.error(err);
        });
      });

    },

    uploadToS3: function(file, data, target){
      var uploadOptions;
      var Parent = null;

      if(data && data.flight) Parent = $scope.$parent

      uploadOptions = {
        method: 'PUT',
        url: file.signature.signedRequest,
        headers: {
          'Content-Type': file.type
        },
        data: file
      }

      // Upload that shit and update the flight.
      file.upload = Upload.http(uploadOptions);

      file.upload.then(function(uploaded){

        var post = file.signature;
        post.path = post.url;
        delete post.signedRequest;
        delete post.url;
        post.size = file.size;
        file.result = post;
        post.reference = data.flight;

        Files.add(post, function Success(res){
          if(target) {
            res.hidden = true; // this is so the upload preview remains and a 'flash' doesn't happen
            if(target.constructor.name === 'Array') {
              target.push(res);
              if(Parent && Parent.update) Parent.update(data.scope, false);
            }
          }

        }, function Err(err){
          console.err(err);
        });

      }, function (response) {
        if (response.status > 0) file.error = response.status + ': ' + response.data;
      }, function (evt) {
        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
      });

    },

    removeFile: function(file){
      file.working = true;
      Files.remove(file._id, function Success(res){
        file.working = false;
        file.removed = true;
      }, function Error(err){
        file.working = false;
        alert(JSON.stringify(err));
      })
    },

    photoUpload: function($files, data, target){
      $scope.files = $files;

      if($files && $files.length){
        data = typeof data == 'string' ? JSON.parse(data) : data;

				data.company = $scope.user.activeCompany()._id;

        var options = {
          url: API.getDomain() + '/file/upload',
          fields: data
        };

        angular.forEach($files, function(file) {
          $timeout(function(){

            file.progress = 0;
            options.file = file;
            file.result = file.result || null;
            file.upload = Upload.upload(options);

            file.upload.then(function (response) {
              $timeout(function () {
                file.result = response.data;
                if(target) {
                  file.result.hidden = true; // this is so the upload preview remains and a 'flash' doesn't happen
                  if(target.constructor.name === 'Array') target.push(file.result);
                }
              });
            }, function (response) {
              if (response.status > 0) file.error = response.status + ': ' + response.data;
            }, function (evt) {
              file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });

          });

        });

      }
    },

    cancelUpload: function(file){
      file.upload.abort();
      file.removed = true;
    },

    cancelAll: function(files){
      angular.forEach(files, function(file){
        file.upload.abort();
        file.removed = true;
      });
    },

    upload: function($files, scope, id){

      var upload;

      scope = scope || null;
      id = id || true;
      $scope.files = $files;

      angular.forEach($files, function(file){

        upload = Upload.upload({
          url: API.getDomain() + '/file/upload',
          file: file,
          fields: { company: $scope.user.activeCompany()._id }
        }).progress(function (evt) {
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        }).success(function (data, status, headers, config) {
          if(scope && typeof scope !== 'undefined'){
            if(scope.path) {
              scope.path = data.Location;
            } else {
              if(typeof scope == 'string'){
                var exec = '$scope.$parent.' + scope + " = data.Location";
                eval(exec);
              }
              if(scope.constructor.name === 'Array'){
                if(id) {
                  scope.push(data);
                }
              }
            }
          }

          if($scope.updateCompany) $scope.updateCompany($scope.company);
        });

      });

    }

  };

  angular.extend($scope, self)

});
