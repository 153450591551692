var APICalendar = angular.module('RessieApp.services.api.calendar', []);

var requested, data;

APICalendar.service('Calendar', function(Request, API, $rootScope, $state, $timeout, $window){

  var self = this;

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        month: {
            method: 'GET'
          , cache: true
          , url: '/month/:month'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , setMonth: {
            method: 'PUT'
          , url: '/month/:start'
          , params: { start: '@start' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }


    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource(endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) {
          $timeout(function(){
            return error(response);
          });
        } else {
          var message = (angular.isDefined(response.error.message)) ? response.error.message : response.error;
          window.alert(message)
        }
      } else {
        Request.renew(token);
        return success(response, token);
      }

    });
  };

  self.month = function(month, success, error) {
    var start, end;

    if(month.constructor.name === 'Array') {
      start = month[0];
      end = month[1];
    } else {
      start = month;
    }

    var payload = { month: start, end: end };

    // if(month === requested) return success(data);

    requested = month;

    self.request('month',
      {
        payload: payload
      },
      function Success(response, token){

        data = response;

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.setMonth = function(month, success, error) {
    var start, end, payload;

    if(month.constructor.name === 'Array') {
      start = month[0];
      end = month[1];
    } else {
      if(month.constructor.name === 'Object') {
        payload = month;
      }
    }

    self.request('setMonth',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

});
