var DirectivesPricing = angular.module('RessieApp.directives.pricing', [])

.service('GuestCard', function(Cards, $timeout){

  function Card(details, meta){

    details.errors = details.errors || [];

    if(angular.isUndefined(details.id)){
      console.error('Processor Card ID Required');
      details.status = 'error';
      details.errors = 'Stripe failed to assign card ID';
    }

    this.meta = (meta) ? meta : null;

    angular.extend(details, {
      charging: false,
      total: 0
    });

    details.status = details.status || 'pending';
    details.charges = details.charges || [];

    angular.extend(this, details);

    this.updateBalance();

    this.chargeAmount = function(val, total){
      var isPercent = val.match(/(.+)%$/);
      if(isPercent){
        return total * (parseFloat(isPercent[1], 2) / 100)
      } else {
        return typeof val !== "number" ? parseFloat(val.replace(/\$/, ""), 2) : val;
      }
    }

    return this;

  }

  Card.prototype.cartBalance = function(amount){
    this.cart_balance = amount;
  }

  Card.prototype.cartTotal = function(amount){
    this.cart_total = amount;
  }

  Card.prototype.updateBalance = function(){
    var self = this;
    $timeout(function(){
      self.balance = 0;
      self.total = 0;
      self.total_refunds = 0;
      self.charges.forEach(function(charge){
        self.total += parseInt(charge.amount);
        charge.total_refunds = 0;
        angular.forEach(charge.refunds, function(val){
          charge.total_refunds += parseInt(val.amount);
        });
        self.total_refunds += charge.total_refunds;
        charge.balance = parseFloat(charge.amount, 2) - charge.total_refunds;
      });
      var balance = self.total - self.total_refunds;
      self.balance = balance;
    });
  }

  Card.prototype.totalCharges = function(){
    var total = 0;
    angular.forEach(this.charges, function(charge, i){
      total += parseFloat(charge.amount);
    });
    return total;
  }

  Card.prototype.charge = function(customer, model, cb){
    if(typeof customer == 'function'){
      cb = customer;
      customer = null;
      model = null;
    }

    if(typeof model == 'function'){
      cb = model;
      model = null;
    }

    var self = this;

    cb = cb || function(){};

    var tip = 0;
    this.tip = this.tip || "";

    var amount = self.chargeAmount(self.amount, self.cart_balance);

    if(/%/.test(this.tip)){
      var tip_percent = parseFloat(this.tip.replace(/[\$|%]/, ""));
      tip = parseFloat(parseFloat(self.cart_total * (tip_percent / 100)).toFixed(2));
    } else {
      tip = parseFloat(this.tip.replace(/\$/, "")) || 0;
    }

    amount += tip;

    var payload = {
          id: self.id,
          customer: customer,
          amount: amount,
          description: 'Booking Charge',
          tip: tip
        };

    if(model) payload.model = model;

    self.charging = true;

    if(self.meta){
      payload = angular.extend(payload, this.meta);
    }

    Cards.charge(payload,
      function Success(res){
        self.charging = false;
        self.status = 'valid';
        self.charges.push({
          id: res.id,
          amount: amount,
          tip: tip,
          date: new Date()
        });
        self.updateBalance();
        self.amount = "";
        return cb(false);
      },
      function Error(err){
        self.charging = false;
        self.amount = null;
        self.errors = err.error;
        self.status = 'failed';
      }
    );

  }

  Card.prototype.sync = function(charge, cb){
    Cards.sync({
      id: charge.id,
      model: 'reservation'
    }, function Success(res){
      $timeout(function(){
        charge.refunds = res.charge.refunds;
        angular.forEach(charge.refunds, function(refund){
          charge.balance -= parseFloat(refund.amount);
        })
        charge.refundAmount = charge.balance;
        return cb()
      })
    }, function Error(err){
      alert(err.message);
    });
  }

  Card.prototype.refund = function(id, amount, obj, cb, model, tipOnly){

    if(typeof obj == 'function') {
      cb = obj;
      obj = null;
    }

    model = model || null;

    var index = null;

    amount = amount || "100%";

    var isPercent = /%$/.test(amount);

    angular.forEach(this.charges, function(val, key){
      if(val.id == id) index = key;
    });

    cb = typeof cb == 'function' ? cb : function(){};

    if(isPercent){
      amount = this.charges[index].balance * (parseFloat(amount.replace("%", ""), 2) / 100)
    } else {
      amount = typeof amount !== "number" ? parseFloat(amount.replace(/\$/, ""), 2) : amount;
    }

    var self = this
      , payload = {
          id: id,
          amount: amount,
          description: 'Refunding Charge',
          model: model,
          tip: tipOnly
        };

    self.refunding = true;

    if(self.meta){
      payload = angular.extend(payload, this.meta);
    }

    var refunds = (angular.isDefined(obj) && angular.isDefined(obj.refunds) ? obj.refunds : this.charges[index].refunds) || [];

    Cards.refund(payload,
      function Success(res){
        $timeout(function(){
          self.refunding = false;
          refunds.push(res);
          if(self.updateBalance) self.updateBalance();
          if(angular.isDefined(obj) && angular.isDefined(obj.refunds)){
            obj.balance = obj.amount;
            angular.forEach(obj.refunds, function(refund){
              obj.balance -= refund.amount;
            });
            // Pricing.cards[obj.card_index].charges[obj.charge_index].refunds.push(res);
          }
          return cb(false);
        });
      },
      function Error(err){
        alert(err.error);
        self.refunding = false;
        self.amount = null;
        self.errors = err.error;
        self.status = 'failed';
      }
    );

  }

  return Card;

})

.factory('Pricing', function(LineItem, GuestCard, $rootScope){

  var vipPricing = $rootScope.vipPricing;

  var setPackageCost = function(booking){

    var pack = booking.package
      , adult_price = 0
      , child_price = 0
      , guestCount = (booking.adults || 0) + (booking.children || 0)
    ;

    if(angular.isDefined(pack)){

      pack.price.perperson = pack.price.perperson || true;

      if(booking.vip) pack.price.perperson = (guestCount > 16);

      if(pack.price.perperson){

        adult_price = (booking.adults * pack.price.adult);
        child_price = (booking.children || 0) * (pack.price.child || 0);
        var pickupCost = (angular.isDefined(booking.accommodations) && (booking.accommodations.hotel && booking.accommodations.hotel.zone) ? booking.accommodations.hotel.zone.price : 0)
        return !booking.hotel ? (adult_price + child_price) : (adult_price + child_price) + (guestCount * pickupCost);

      } else {

        var price;
        angular.forEach($rootScope.vipPricing, function(group, index){
          if(guestCount >= group.min && guestCount <= group.max) price = group.price;
        })

        return (!booking.hotel || booking.accommodations.hotel === null) ? price : price + (guestCount * (booking.accommodations.hotel.zone ? booking.accommodations.hotel.zone.price : 0));

      }

    }

  }

  function Pricing(obj){

    var self = this;
    obj = obj || {};
    obj.line_items = obj.line_items || [];
    obj.payment = obj.payment || {};
    obj.payment.cards = obj.payment.cards || [];

    this.package_cost = setPackageCost(obj);

    obj.line_items = obj.line_items.filter(function(item){
      return angular.isDefined(item.product);
    });

    angular.forEach(obj.line_items, function(item, index){
      var _item = new LineItem(item);
      var prev = index > 0 ? obj.line_items[index - 1] : null;
      if(prev) {
        _item.running = prev.running + _item.total;
      } else {
        _item.running = self.package_cost + _item.total;
      }
      _item.calculate();
      obj.line_items[index] = _item;
    });

    this.booking_id = obj._id;

    this.payments = {};
    this.other_payments = {
      cash: obj.payment.cash || [],
      other: obj.payment.other || [],
      gifts: obj.payment.gifts || []
    };

    this.pickup_price = 0;

    if(obj.needs_pickup && obj.accommodations.hotel){
      this.pickup_price = obj.accommodations.hotel.zone ? obj.accommodations.hotel.zone.price * (obj.adults + obj.children || 0) : 0;
    }

    /**
     * Handle Items
     * ======================================
     */

    this.items = obj.line_items || [];

    /**
     * Handle Cards
     * ======================================
     */

    this.cards = obj.payment.cards || {};

    /**
     * Handle Discounts
     * ======================================
     */

    this.discount = 0;

    this.discount_value = obj.payment.discount || 0;

    this.discount_name = obj.payment.discount_name || "Discount";

    /**
     * Handle Totals
     * ======================================
     */

    this.total = 0;

    /**
     * Handle Balance
     * Balance is the total - discounts and payments
     * ======================================
     */

    this.balance = 0;

    this.Charges();
    /**
     * Loop thru the saved card data and convert to a usable
     * GuestCard object.
     * This object is what allows us to perform charges on the card.
     */
    angular.forEach(obj.payment.cards, function(card, index){
      obj.payment.cards[index] = new GuestCard(card);
      obj.payment.cards[index].cartBalance(self.Balance());
      obj.payment.cards[index].cartTotal(self.Total());
    });

  }

  Pricing.prototype.setPackageCost = function(booking){
    this.package_cost = setPackageCost(booking);
  }

  Pricing.prototype.reset = function(){
    this.payments = {};
    this.items = [];
    this.cards = {};
  }

  Pricing.prototype.packageTotal = function(amount){
    if(amount) this.package = amount;
    return this.package;
  }

  Pricing.prototype.PaymentTotal = function(){

    var self = this;

    var total = 0;

    // Card Payments
    angular.forEach(this.payments, function(card, id){
      angular.forEach(card.charges, function(charge, i){
        total = parseFloat(total) + parseFloat(charge.amount - (charge.tip || 0));
        angular.forEach(charge.refunds, function(refund, i){
          total = (parseFloat(total) - parseFloat(refund.amount));
        })
      })
    });

    // Misc Payments (other)
    if(this.other_payments){
      angular.forEach(this.other_payments.other, function(payment){
        total = parseFloat(total) + parseFloat(payment.amount)
      });
      // Cash Payments
      angular.forEach(this.other_payments.cash, function(amount){
        amount = parseFloat(amount)
        total = parseFloat(total) + amount
      });

      // Gift Payments
      angular.forEach(this.other_payments.gifts, function(gift){
        if (gift.used.length) {
          var sum = 0;
          angular.forEach(gift.used, function(val) {
            if (val.reservation === self.booking_id) {
              sum += parseFloat(val.amount);
            }
          });

          if (sum !== 0) {
            amount = sum;
          } else {
            amount = parseFloat(gift.amount)
          }
        } else {
          amount = parseFloat(gift.amount)
        }
        total = parseFloat(total) + amount
      });
    }

    return parseFloat(total);

  }

  Pricing.prototype.Cash = function(){
    var total = 0;
    angular.forEach(this.other_payments.cash, function(number){
      total += number;
    });
    return total;
  }

  Pricing.prototype.addItem = function(item){
    this.items.push(item);
  }

  Pricing.prototype.addCard = function(obj){
    this.cards[obj.id] = obj;
  };

  Pricing.prototype.applyDiscount = function(value, set){
    set = set || false;

    value = value || parseInt(0);

    this.discount_value = value;

    var isPercent, Discount;

    isPercent = typeof value == 'string' && value.match(/%$/);

    discount = typeof value == 'string' ? parseFloat(value.replace(/[A-Za-z$-]/g, "")) : value;

    if(discount > 0){
      if(isPercent){
        Discount = -(this.Total() * (discount/100)) || 0;
      } else {
        Discount = (this.Total() > 0) ? -discount || 0 : 0;
      }
    } else {
      Discount = 0;
    }

    var done = set ? this.Discount(Discount) : Discount;

    return done;

  }

  Pricing.prototype.Charges = function(){

    this.charges = []
    var self = this;

    self.tip_total = 0;

    angular.forEach(self.cards, function(card, card_i){


      angular.forEach(card.charges, function(charge, charge_i){

        charge.card_index = card_i;
        charge.charge_index = charge_i;

        charge.isCharge = true;
        charge.card = { type: card.type, masked: card.masked, id: card.id, refund: card.refund, sync: card.sync };
        charge.balance = charge.amount;
        charge.refunds = charge.refunds || [];
        charge.refundTotal = 0;

        angular.forEach(charge.refunds, function(refund, refund_i){
          charge.refund_index = refund_i;
          charge.refundTotal += parseFloat(refund.amount);
          charge.balance -= parseFloat(refund.amount);
        });

        var tip = 0;
        if(typeof charge.tip !== 'undefined'){
          var percentage = typeof charge.tip == 'string' && charge.tip.match(/(.+)%$/);
          if(percentage){
            tip = charge.amount - (charge.amount / parseFloat("1." + percentage[1]));
          } else {
            tip = parseFloat(charge.tip);
          }
          charge.tip = tip;
          self.tip_total += tip;
        }

        self.charges.push(charge);

      });

    });

    return self.charges;

  };

  Pricing.prototype.Discount = function(amount){
    this.discount = amount;
    return parseFloat(this.discount);
  }

  Pricing.prototype.Total = function(amount){

    var total = this.package_cost;

    if(amount) {
      this.total = amount;
      return this.total;
    } else {

      // this.total = this.package;
      angular.forEach(this.items, function(item){
        total += parseFloat(item.total);
      });

      this.total = total + (this.pickup_price || 0);

      return this.total;

    }
  };

  Pricing.prototype.Balance = function(){
    var balance = (this.Total() + this.Discount()) - this.PaymentTotal();
    return balance;
  }

  return Pricing;

})

.service('LineItem', function(){

  function LineItem(data){

    data.discount_value = data.discount_value || 0;
    data.isdiscounted = data.isdiscounted || (angular.isDefined(data.discount) && data.discount);
    data.total = data.total || 0;
    data.value = data.value || 0;

    angular.extend(this, data);

  }

  LineItem.prototype.calculate = function(){

    var self = this;

    this.discountValue();

    this.total = (parseInt(this.count) * parseFloat(this.value)) - this.discount_value;

    return this;

  }

  LineItem.prototype.discountValue = function(){

    var newTotal = (parseInt(this.count) * parseFloat(this.value))
      , item = this
      , discount
      , isPercent;

    if(item.isdiscounted && item.discount){

      isPercent = item.discount.match(/%$/);

      discount = parseFloat(item.discount.replace(/[A-Za-z$-]/g, ""));

      if(isPercent){
        item.discount_value = (newTotal * (discount/100));
      } else {
        item.discount_value = (discount || 0);
      }

    } else {
      item.discount_value = 0;
    }

    return this;

  }

  return LineItem;

})

/**
 * Parent Pricing controller
 */
.controller('PricingCtrl', function($scope, $timeout, $rootScope, $modalInstance, lineItems, editing, parent, LineItem, discount, Gifts, Pricing, Contacts, partners, $filter){

  var total = 0;

  $scope.partners = partners || undefined;

  $scope.editing = editing;

  $scope.discount = discount;

  $scope.parent = parent;

  $scope.grandTotal = parent;

  $scope.parseInt = $rootScope.parseInt;

  $scope.$watch('item', function(n, o){
    if(!n.isCreditCard && !n.isPayment && !n.isOtherPayment && !n.isGift && !n.isDiscount){
      if(!n.isdiscounted) n.discount = null;
      var item = new LineItem(n);
      item.calculate();
      $scope.item = item;
    }
  }, true);

  $scope.item = {
    'count': 1,
    'isdiscounted': false
  };

  $scope.invalidDiscount = false;

  $scope.activeTab = function(tab){
    $scope.onTab = tab;
  };

  $scope.findGift = function(code){
    Gifts.search(code, function Success(res){
      $scope.gifts = res.results;
    });
  };

  $scope.getPartners = function(){
    Contacts.find({ 'meta.partner': true }, function Win(res){
      $scope.partners = res;
    }, function Nope(err){
      alert(err.message);
    });
  }

  if(editing){

    $scope.selection = [ lineItems.product ]
    $scope.item = lineItems;

  } else {

    $scope.selection = [ lineItems ];

    $scope.$watch('selected', function(n, o){
      var item = $scope.item;
      if(!item.isCreditCard){

        item.product = n;
        if(n) {
          item.value = n.value;
          item.total = (n.value * item.count);
        }
      }
    }, true);

  }

  $scope.$watch('discount._value', function(n, o) {
    if(n) {
      var isNegative = typeof n == 'string' && n.match(/^-/);
      $scope.invalidDiscount = isNegative;
    }
  })

  var self = {

      'selected': null

    , 'push' : function(selection, $index){
        $scope.selection.splice($index + 1);
        selection = JSON.parse(selection);
        $timeout(function(){ $scope.selected = selection; });
        if(selection.children) $scope.selection.push(selection.children)
      }

    , 'remove': function(item){
        var confirm = window.confirm('Are you sure you want to remove this item?');
        if(confirm) {
          item.removed = true;
          if(item.isDiscount){
            $timeout(function(){
              $scope.discount._value = 0;
              $scope.discount.value = 0;
              $scope.discount.total = 0;
            })
          }
          if(item.isLineItem){
            item = null
          }
          $modalInstance.close(item);
        }
      }

    , 'confirm': function(){
        var tab = $scope.onTab;
        var item = (tab == 'discount') ? $scope.discount : $scope.item;
        if(angular.isDefined(item.name)) {
          Pricing.discount_name = item.name;
        }
        $modalInstance.close(item);
      }

    , 'selectGift' : function(gift){
        if(!gift.redeemed) {
          gift.isGift = true;
          gift.option = 'gift';
          $scope.item = gift;
        }
      }

    , 'cancel' : function(){
        $modalInstance.dismiss('cancel');
      }

  };

  angular.extend($scope, self);

})

.controller('PricingDirectiveHelper', function($scope, $rootScope, Pricing, GuestCard, LineItem, $timeout, $modal, $log, Contacts){

  $scope.booking = $scope.booking || {};

  $scope.booking.pricing = $scope.booking.pricing || new Pricing($scope.booking);

  var Pricing = $scope.booking.pricing;

  function UpdatePackageCost(package){

    if(package) {
      Pricing.setPackageCost($scope.booking);
    }
  }

  $scope.$watch('booking.package', UpdatePackageCost);
  $scope.$watch('booking.adults', UpdatePackageCost);
  $scope.$watch('booking.children', UpdatePackageCost);

  $scope.vipPricing = $rootScope.vipPricing;

  $scope.charges = [];

  $scope.hasPhotos = function(){
    return $scope.booking.line_items.filter(function(item){
      return item.product.name.match(/photo|picture|image/i);
    }).length > 0
  };

  $scope._charges = function(){

    $scope.charges = [];

    angular.forEach(Pricing.cards, function(card, card_i){

      angular.forEach(card.charges, function(charge, charge_i){

        charge.card_index = card_i;
        charge.charge_index = charge_i;

        charge.isCharge = true;
        charge.card = { type: card.type, masked: card.masked, id: card.id, refund: card.refund, sync: card.sync };
        charge.balance = charge.amount;
        charge.refunds = charge.refunds || [];
        charge.refundTotal = 0;

        angular.forEach(charge.refunds, function(refund, refund_i){
          charge.refund_index = refund_i;
          charge.refundTotal += parseFloat(refund.amount);
          charge.balance -= parseFloat(refund.amount);
        });

        $scope.charges.push(charge);

      });

    });
  };

  $scope._charges();

  $scope.$parent.$watch(function(){
    return angular.isDefined($scope.$parent.getHotel) ? $scope.$parent.getHotel() : null;
  }, function(n){
    if(n){
      if(angular.isDefined(n.zone) && n.zone && angular.isDefined(n.zone.price)) n.zone.price = parseFloat(n.zone.price);
      $scope.hotel = n;
    }
  });

  /**
   * Watch cards for changes (usually for charges)
   * @return {[type]} [description]
   */
  $scope.$watch(function GuestCards(){
    return $scope.booking.pricing.cards;
  }, function(cards){
    angular.forEach(cards, function(card, id){
      card.cartBalance(Pricing.Balance())
      if(card.total > 0) {
        $scope.payments[id] = card;
        $scope.booking.pricing.payments[id] = card;
        $scope._charges();
      }
    });
  }, true)

  $scope.$watch(function OtherPayments(){
    return $scope.booking.pricing.other_payments.other;
  }, function(otherPayments){

    $scope.other_payments = otherPayments;
    $scope.booking.pricing.other_payments.other = otherPayments;
  }, true)

  $scope.$watch(function CashPayments(){
    return $scope.booking.pricing.other_payments.cash;
  }, function(cashPayments){
    $scope.cash_payments = cashPayments;
    $scope.booking.pricing.other_payments.cash = cashPayments;
  }, true)

  $scope.$watch(function GiftPayments(){
    return $scope.booking.pricing.other_payments.gifts;
  }, function(giftPayments){
    giftPayments.map(function(gift){
      var used = gift.used || [];
      var amount = used.reduce(function(prev, cur){
        if(cur.reservation === $scope.booking._id) {
          return prev + (cur.amount || 0);
        } else {
          return 0;
        }
      }, 0)
      if (amount > 0) {
        gift.amount = amount
      } else {
        const balance = $scope.getGrandTotal() + $scope.discount.total + $scope.totalPayments;
        if (gift.amount > balance) gift.amount = balance;
      }
    })
    $scope.gift_payments = giftPayments;
    $scope.booking.pricing.other_payments.gifts = giftPayments;
  }, true)

  /**
   * Watch Pricing Items
   * @return {[type]} [description]
   */
  $scope.$watch(function ListItems(){
    return $scope.booking.pricing.items;
  }, function(n){
    $scope.lineItems = n;
  });

  $scope.$watch(function GuestPayments(){
    return $scope.booking.pricing.PaymentTotal();
  }, function(n, o){
    $scope.totalPayments = parseFloat(n);
  });

  $scope.$watch(function () {
    return ($scope.booking.adults || 0) + ($scope.booking.children || 0);
  }, function (n, o) {
    if (n && n !== 0 && n !== o) {
      $scope.booking.payment.discount = "";
    }
  })


  $scope.lineItems = [];

  // Assign Package
  $scope.$watch('booking', function(n, o){
    if(n){
      if(n.package) {
        if(typeof n.package == 'string') {
          $scope.package = JSON.parse(n.package);
        } else {
          $scope.package = n.package;
        }
        if ($scope.package.vipPricing && $scope.package.vipPricing.length){
          $scope.vipPricing = $scope.package.vipPricing;
        }
      }
    }
  }, true);

  $scope.$watch('booking.payment.discount', function(n){
    if(n) {
      $timeout(function() {
        $scope.discount.total = Pricing.applyDiscount(n, true);
        $scope.discount._value = n;
      })
    }
  })

  $scope.$watch('discount.name', function(n){
    if(n) $scope.booking.pricing.discount_name = n;
  })

  var self = {

    expanded: true,

    totalPayments: 0,

    payments: {},

    discount: {
      isDiscount: true,
      _value: 0,
      total: 0,
      name: $scope.booking.pricing.discount_name || 'Balance Discount',
      calculate: function(val, set){
        set = set || false;
        var discount = Pricing.applyDiscount(val, set);
        return discount;
      }
    },

    update: function(item, $index){
      if(typeof item == 'string' || typeof item == 'number'){
        var amount = item;
        item = {
          amount : item,
          option : 'cash',
          isCash : true
        };
      }

      item.isCreditCard = (item instanceof GuestCard) || angular.isDefined(item.card);

      if(item.isCreditCard) {
        // item.grandTotal = item.total;
        item.refundAmount = item.balance;
      }
      item.isLineItem = (item instanceof LineItem);
      item.isOtherPayment = (typeof item.type !== 'undefined' || item.partner !== undefined) && !item.isCreditCard && !item.isGift;
      item.index = $index;

      if(!item.isLineItem && !item.isCreditCard && !item.isCash && !item.isOtherPayment && !item.isGift) item.isDiscount = true;

      var workable = angular.copy(item);

      var modalInstance = $modal.open({
        templateUrl: 'views/pricing/modal',
        controller: 'PricingCtrl',
        resolve: {
          lineItems: function(){
            return workable;
          },
          editing: function(){
            return true;
          },
          partners: function($q){
            var deferred = $q.defer();
            Contacts.find({ $or: [
              { "meta.partner": true }
            ] }, function Win(res){
              deferred.resolve(res);
            }, function Nope(err){
              deferred.reject(err);
            });
            return deferred.promise;
          },
          discount: function(){ return $scope.discount; },
          parent: function(){
            return self.getGrandTotal() - $scope.totalPayments;
          }
        }
      });

      modalInstance.result.then(
        function Confirm(item) {

          if(item){
            if(item.removed){

              if(item.isCash){
                $timeout(function(){ Pricing.other_payments.cash.splice($index, 1); });
              }

              if(item.isOtherPayment){
                $timeout(function(){ Pricing.other_payments.other.splice($index, 1); });
              }

              if(item.isGift){
                $timeout(function(){ Pricing.other_payments.gifts.splice($index, 1); });
              }

            } else {
              if(item.isCreditCard){
                Pricing.cards[item.card_index].charges[item.charge_index] = item;
              }
              if(item.isDiscount){
                var set = $scope.discount._value !== "undefined" && $scope.discount._value > 0
                $scope.discount.total = $scope.discount.calculate($scope.discount._value, set) || 0;
              }
              if(item.isLineItem){
                $timeout(function(){ $scope.lineItems[$index] = item; });
              }
              if(item.isCash){
                $timeout(function(){ Pricing.other_payments.cash[$index] = parseFloat(item.amount).toFixed(2) });
              }
              if(item.isOtherPayment){
                var amount = parseFloat(item.amount).toFixed(2);
                $timeout(function(){
                  Pricing.other_payments.other[$index] = {
                    type: item.type,
                    partner: item.partner,
                    amount: amount
                  }
                });
              }
            }
          } else {
            if(item == null){
              $scope.lineItems.splice($index, 1);
            }
          }
        },
        function Cancel(stuff) {}
      );

    },

    getItems: function(){
      return $scope.lineItems;
    },

    addLineItem: function(){

      var modalInstance = $modal.open({
        templateUrl: 'views/pricing/modal',
        controller: 'PricingCtrl',
        resolve: {
          lineItems: function(){
            return $rootScope.products;
          },
          editing: function(){
            return false;
          },
          partners: function(){
            return undefined;
          },
          discount: function(){ return $scope.discount; },
          parent: function(){
            return (self.getGrandTotal() - $scope.totalPayments) + $scope.discount.total;
          }
        }
      });

      modalInstance.result.then(
        function Confirm(item) {

          var isCreditCard = (item instanceof GuestCard);
          var isDiscount = typeof item.isDiscount !== 'undefined';
          var isPayment = (typeof item.option !== 'undefined');
          var isLineItem = (item instanceof LineItem && (!isCreditCard && !isDiscount && !isPayment));

          if(isLineItem) {
            $timeout(function(){ $scope.booking.pricing.addItem(item); });
          }

          if(isDiscount){
            var set = $scope.discount._value !== "undefined" && $scope.discount._value > 0
            $scope.discount.total = $scope.discount.calculate($scope.discount._value, set) || 0
          }

          if(isPayment){

            item.amount = item.amount || 0;

            var amount = item.amount && (typeof item.amount !== 'number') ? parseFloat(item.amount.replace(/[^\d.-]/g,'')).toFixed(2) : item.amount;

            if(item.option == 'cash') $scope.booking.pricing.other_payments.cash.push(amount);

            try {
              item.partner = JSON.parse(item.partner);
            } catch (e) {
              console.error(e)
              item.partner = undefined;
            } finally {
              if(item.option == 'other') $scope.booking.pricing.other_payments.other.push({
                type: item.type,
                partner: item.partner,
                amount: amount
              });
            }


            if(item.isGift){
              $scope.booking.pricing.other_payments.gifts.push(item);
            }

          }

        },
        function Cancel(stuff) {
          $log.info('Modal dismissed at: ' + new Date() + ' ' + stuff);
        }
      );

    },

    /**
     * Get the selected package details
     * @return {[type]} [description]
     */
    getPackageCost: function(){

      var guest = $scope.booking
        , package = $scope.booking.package ? $scope.booking.package : ($scope.package || null);

      if(!package && ($scope.package && $rootScope.packages)) {
        package =  $rootScope.packages.filter(function(pack){
          return pack._id == $scope.package._id;
        })[0];
      }

      if(package) package.price.child = package.price.child || 0;

      var details = {
        adults: { count: 0, price: 0 },
        children: { count: 0, price: 0 },
        total: { count: 0, price: 0 },
      }

      if(guest && package){

        package.price.perperson = package.price.perperson || true;

        details.total = {
          count: (guest.adults || 0) + (guest.children || 0)
        };

        var maxNums = $scope.vipPricing.map(function(group){
          return group.max;
        });
        
        var biggestVIP = Math.max.apply(this, maxNums);

        if($scope.booking.vip) package.price.perperson = (details.total.count > biggestVIP);

        if(package.price.perperson){

          details.adults = {
            count: guest.adults || 0,
            price: (guest.adults * package.price.adult)
          };

          details.children = {
            count: guest.children || 0,
            price: ((guest.children || 0) * package.price.child)
          };

          details.total.price = (guest.adults * package.price.adult) + ((guest.children || 0) * package.price.child)

        } else {

          delete details.adults;
          delete details.children;

          var price;

          angular.forEach($scope.vipPricing, function(group, index){
            if(details.total.count >= group.min && details.total.count <= group.max) price = group.price;
          })

          details.total.price = !$scope.booking.hotel || $scope.booking.vip ? price : price * ((details.adults.count + details.children.count) * $scope.hotel.zone.price);
        }
      }

      Pricing.packageTotal(details.total.price);

      return details;

    },

    getGrandTotal: function(){

      var price = this.getPackageCost().total.price;

      $scope.lineItems.forEach(function(val, key){
        price += val.total;
      });

      // Dont add pickup cost if the reservation is flagged for free pickup
      if($scope.booking.needs_pickup && !$scope.booking.meta.free_pickup) {
        var pickupPrice, hotel;
        var accommodations = $scope.booking.accommodations
        hotel = accommodations && accommodations.hotel;
        if(hotel && hotel.zone){
          pickupPrice = hotel.zone.price || 0;
        } else {
          pickupPrice = $scope.hotel && $scope.hotel.zone ? $scope.hotel.zone.price : 0
        }
        price += (this.getPackageCost().total.count * pickupPrice)
      }

      var totalPrice =  price || 0;

      return Pricing.Total(totalPrice);

    }

  };

  angular.extend($scope, self);

})

/**
 * Pricing Parent Directive
 */
.directive('pricing', function($rootScope, $compile) {

  var booking;

  return {
    restrict: 'AE'
  , templateUrl: 'views/pricing/panel'
  , replace: true
  , scope: {
      'booking': '=ngModel'
    }
  , controller: 'PricingDirectiveHelper'
  , compile: function(tElement, tAttrs, transclude){

      return function Link(scope, element, attrs){
        // $compile(element.contents())(scope);
      }

    }
  }
});
