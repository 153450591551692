'use strict';

var presetPrices = [ 239, 478, 2200 ];

var giftsCtrl = angular.module('RessieApp')

.controller('giftsCtrl', function ($scope, $rootScope, $state, allowed, $window, $timeout, Gifts, gifts, gift, StripeCard, Customers, $location, $http, Cards, GuestCard) {
  
  var debug = $location.search().debug;
  var company = $scope.user.activeCompany();

  if(gift){
    $scope.gift = gift;
    $scope.gift.fauxAmount = false;
    $scope.gift.manual = $scope.gift.booking ? company.nickname + $scope.gift.booking.confirmation : $scope.gift.manual;
  }

  // $scope.gift.preexisting = ($state.current.name == 'giftsCreate');

  if(debug) {
    $timeout(function(){

      $scope.gift = {
        type: 'promo',
        electronic: "true",
        sendTo: 'purchaser',
        amount: 215,
        cards: [],
        cardEntry: {
          number: '341111111111111',
          expiration: '1216',
          cvv: '123'
        },
        contact: {
          purchaser: {
            name: 'Josey Morton',
            phone: 9129266677,
            // shipping_address: {
            //   line_one: '21876 S. 233rd Ct. E.',
            //   city: 'Collardville',
            //   state: 'Tn',
            //   postal: 85132
            // },
          },
          recipient: {
            name: 'Valorie Morton'
          }
        }
      }

    })
  }

  if(!allowed) $state.go('login');

  $scope.useNew = true;

  $scope.saving = false;
  $scope.balanced = true;

  $window.addEventListener("beforeunload", function(e){
    // Edit form dirty?
    var stateName = $rootScope.state.name;
    if($scope.giftForm && $scope.giftForm.$dirty && !$scope.saving && (stateName == 'giftsCreate' || stateName == 'giftsEdit')){
      var confirmationMessage = "It appears as though you have unsaved data, continue?";
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome etc.
    }
  });

  $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState){
    // Edit form dirty?
    if(angular.isDefined($scope.giftForm)){
      if($scope.giftForm.$dirty && !$scope.saving && (fromState.name == 'giftsCreate' || fromState.name == 'giftsEdit')){
        var confirm = $window.confirm("It appears as though you have unsaved data, continue?");
       if(!confirm) e.preventDefault();
      }
    }
  })

  $rootScope.$on('$stateChangeSuccess', function(e, toState, toParams, fromState){
    // Edit form dirty?
    if(angular.isDefined($scope.giftForm)){
      $scope.giftForm.$dirty = false;
    }
  })

  if(gifts){}

  /**
   * Since currently gifts aren't using promises
   * (promises cause the default layout to flash)
   */
  $rootScope.$watch('gifts', function(n, o){
    if(n && angular.isDefined(n.results)){
      $rootScope.previous.giftId = n.results.length > 0 ? n.results[0]._id : null;
    }
    $scope.gifts = gifts = n;
  });

  $scope.$watch('gift.amount', function(n, o){

    if(n){
      var value = parseFloat(n);
      var presetIndex = _.indexOf(presetPrices, value);
      if(presetIndex < 0) {
        $scope.gift.fauxValue = n;
      } else {
        delete $scope.gift.fauxValue;
      }

      $scope.gift.cardEntry = $scope.gift.cardEntry || {};
      $scope.gift.cardEntry.amount = $scope.amountBalance();
    }

  });

  $scope.$watch('gift.discount', function(n){
    if(n) $scope.gift.cardEntry.amount = $scope.amountBalance();
  });

  $scope.$watch('gift.electronic', function(n, o){
    var bool = (n == 'true') ? true : false;
    if(bool && angular.isUndefined($scope.gift.code)) {
      $timeout(function(){
        $scope.gift.type = 'card';
        $scope.gift.cardEntry.amount = $scope.amountBalance();
        $scope.setToken();
      });
    } else {
      // $timeout(function(){ $scope.gift.type = 'card'; });
    }
  })

  $scope.$watch('gift.sendTo', function(n, o){
    if(n){
      $scope.gift.contact = $scope.gift.contact || {};
      $scope.gift.contact[n] = $scope.gift.contact[n] || {};
      $scope.gift.contact[o] = $scope.gift.contact[o] || {};

      if(n !== o){
        $scope.gift.contact[n].send_to = true;
        $scope.gift.contact[o].send_to = false;
      }
    }
  });

  $scope.eToPurchaser = function(){
    return $scope.isElectronic() && $scope.gift.contact.purchaser.send_to;
  }

  $scope.eToRecipient = function(){
    return $scope.isElectronic() && $scope.gift.contact.recipient.send_to;
  }

  /**
   * Strip away non numbers from phone number
   */
  $scope.$watch('gift.contact.purchaser.phoneFaux', function(n, o){
    if(n && typeof n == 'string') $scope.gift.contact.purchaser.phone = n.replace(/[^\d]/g, "")
  });

  /**
   * Add phone number to the `faux` field when editing
   */
  $scope.$watch('gift.contact.purchaser.phone', function(n, o){
    if(n) $scope.gift.contact.purchaser.phoneFaux = n;
  });

  $scope.$watch('gift.amount', function(n){
    if(n) {
      $scope.gift.amountFloat = parseFloat(n) || 0;
    }
  })

  $scope.$watch('gift.fauxValue', function(n, o){
    if(n){
      $scope.gift.amountFloat = parseFloat(n) || 0;
      $scope.gift.amount = $scope.gift.amountFloat;
      if(n !== o) $scope.gift.fauxAmount = true;
    }
  })

  $scope.$watch('gift.type', function(n, o){

    if(n){

      if(n == 'promo' || n == 'donation') {
        // $timeout(function(){
          $scope.gift.discount = "100%";
        // });
      }

      if(n !== o) $scope.gift.code = '';

      $scope.codeMask = n == 'card' ? '9999-9999-9999-9999' : '';

      if(!angular.isDefined($scope.gift.code) || $scope.gift.code == ''){

        if(n == 'promo') $scope.setPromo();
        if(n == 'donation') $scope.setDonation();
        if(n == 'zola') $scope.gift.code = "Z - ";

        if(n == 'card') {
          if(!$scope.isElectronic()){
            $scope.codePlaceholder = "____-____-____-____"
            $timeout(function(){
              $('#giftCodeInput').focus();
            });
          } else {
            $scope.codeMask = '';
            $scope.codePlaceholder = "eVoucher Token";
            $scope.setToken();
          }
        }

      }

    }

  });

  $scope.focus = function(q) {
    $(q).focus();
  }

  $scope.$watch('gift.type', function(n, o){
    if(n){
      $scope.gift.cardEntry.amount = $scope.amountBalance();
    }
  });

  $scope.$watch(function(){
    return $scope.isElectronic();
  }, function(n, o){

    if($scope.gift){

      $scope.gift.cardEntry = $scope.gift.cardEntry || {};

      $scope.gift.cardEntry.amount = $scope.amountBalance();

      if($scope.gift.code !== '') $scope.originalCode = angular.copy($scope.gift.code);

      if($scope.gift.type == 'card'){

        if(n){
          $scope.codeMask = '';
          $scope.codePlaceholder = "eVoucher Token";
        } else {
          $scope.codeMask = '9999-9999-9999-9999';
          $scope.codePlaceholder = "____-____-____-____";
          if(o && !/[0-9]{16}/.test($scope.originalCode)) $scope.gift.code = "";
        }

        // Wasn't electronic, now it is.
        if(!o && n){
          // Generate code, only if a code didn't exist previously
          if(n && !isToken($scope.originalCode)) {
            $scope.setToken();
          } else {
            $timeout(function(){ $scope.gift.code = $scope.originalCode });
          }

        }

      }
    }

  });

  function isToken(code){
    return /[a-z0-9]{3}-[a-z0-9]{2}-[a-z0-9]{3}/i.test(code);
  }

  $scope.$watch('gift.cards', function(n, o){
    if(n){
      $scope.gift.cardEntry = $scope.gift.cardEntry || {};
      $scope.gift.cardEntry.amount = $scope.amountBalance();
    }
  }, true)

  $scope.$watch('gift.cardEntry.expiration', function(n, o){
    if(n && n.length == 4){

      $scope.gift.cardEntry.exp = $scope.gift.cardEntry.exp || {};

      $scope.gift.cardEntry.exp.month = n.substring(0, 2);
      var cardyear = n.substring(2);
      $scope.gift.cardEntry.exp.year = "20" + cardyear;

    }
  })

  var DiscountVal = function (val){
    var perchaseAmount = parseFloat($scope.gift.amount) + ( (!$scope.isElectronic() && $scope.gift.type == 'card') ? 2.5 : 0 )
    var isPercent = /^[0-9]{1,3}%$/.test(val);
    val = (typeof val != 'number') ? parseFloat(val.replace(/%$/, '').replace(/^\$/, "")) : val;
    return (isPercent) ? perchaseAmount * (val / 100) : val;
  };

  var self = {

      setToken: function(){
        $http({method: 'GET', url: $scope.domain + "/gift/token" }).
          then(function(data) {
            $scope.gift.code = data.data.replace(/"/gm, '');
          }).catch(function(err) {});
      }

    , setPromo: function(){
        $http({method: 'GET', url: $scope.domain + "/gift/promo" }).
          then(function(data) {
            $scope.gift.code = data.data.next_code;
          }).catch(function(err) {});
      }

    , setDonation: function(){
        $http({method: 'GET', url: $scope.domain + "/gift/donation" }).
          then(function(data) {
            $scope.gift.code = data.data.next_code;
          }).catch(function(err) {});
      }

    , isElectronic: function(){
        if($scope.gift){
          return (typeof $scope.gift.electronic == 'boolean' && $scope.gift.electronic) || $scope.gift.electronic == "true";
        }
      }

    , amountBalance: function(){
        if ($scope.gift.preexisting) return 0;
        var balance = parseFloat(angular.copy($scope.gift.amount)) + ((!$scope.isElectronic() && $scope.gift.type == 'card') ? 2.5 : 0);

        var payments = 0;

        angular.forEach($scope.gift.cards, function(card, key){
          angular.forEach(card.charges, function(charge, i){
            payments += parseFloat(charge.amount);
          });
        });

        return balance - payments - (DiscountVal($scope.gift.discount || 0) || 0);
      }

    , years: _.range(year, year + 11, 1)

    , removeConfirm: function(gift){

        bootbox.dialog({
          message: "<p><strong>This can not be undone.</strong> If you still want to remove this prepaid purchase then please type <code>DELETE</code> below:</p><div class='form-group bg-light' style='padding: 26px;margin: 0 -20px -1px -20px;margin-bottom: -35px;'><input type='text' id='result' class='form-control' /></div>",
          title: "Removing <code>" + gift.code +"</code>. Are you sure?",
          buttons: {
            success: {
              label: "Remove",
              className: "btn-primary",
              callback: function() {
                var result = $('#result').val();
                if(result == 'DELETE') {

                  Gifts.remove(gift._id,
                    function Success(res){
                      gift.removed = true;
                    },
                    function Error(err){

                    }
                  );

                }
              }
            },
            cancel: {
              label: "Cancel",
              className: "btn-default",
              callback: function() {}
            }
          }
        });
      }

    , go: function(id){
        var useNew = $scope.useNew;
        if(useNew) {
          $state.go('editPrepayment', { id: id });
        } else {
          $state.go('giftsEdit', { id: id });
        }
      }

    , byInput: function(evt){

        // enter key
        if(evt.keyCode == 13){
          Gifts.search($scope.q, function Success(res){
            $scope.gifts = res;
          })
        }

        // Escape key
        if(evt.keyCode == 27){
          $scope.q = ""
          $scope.gifts = gifts;
        }

      }

    , getState: function(gift){

        var state = angular.isDefined(gift.code) ? 'Active' : 'Pending';

        if(angular.isDefined(gift.expiration)){
          var expiration = $window.moment(gift.expiration)
          if(expiration.diff($scope.today, 'days') < 0) state = 'Expired';
        }

        if(gift.redeemed) state = 'Redeemed';

        gift.state = state;

        return state;

      }

    , errors: null
    , dateOptions : {}
    , codeMask: ''
    , codePlaceholder: 'Gift Code'

    , checkCard: function(){

        var cardDetails = $scope.gift.cardEntry
          , purchaser = $scope.gift.contact.purchaser
          , card;

        card = new StripeCard({
          name: purchaser.name,
          number: cardDetails.number,
          exp_month: cardDetails.exp.month,
          exp_year: cardDetails.exp.year
        });

				if(Object.keys(card.errors).length){
          var errors = $scope.errors = card.errors;
          $scope.gift.cardEntry.charging = false
          if(errors['number']){
            // $scope.giftForm.cardNumber.$setValidity('required', false);
          }
        } else {
          card.Create(
            function Success(res){
              $timeout(function(){

                var amount = $scope.gift.cardEntry.amount;

                var token = res.id;

                function ChargeCard(id){
                  Cards.charge({
                      id: id,
                      amount: amount,
                      customer: $scope.gift.customerId,
                      update: false
                    },
                    function Success(res){

                      var cardNumber = $scope.gift.cardEntry.number;
                      $scope.gift.cardEntry = {};

                      var charge = {};

                      var _card = {
                        id: id,
                        masked: card.number.slice(-4),
                        type: card.type(),
                        charges : []
                      };

                      charge.id = res.id;
                      charge.amount = parseFloat(amount);

                      $timeout(function(){
                        _card.charges.push(charge);
                        $scope.gift.cards.push(new GuestCard(_card));
                        $scope.showCardEntry = false;
                      })

                    },
                    function Error(err){
                      alert(JSON.stringify(err));
                      $scope.gift.cardEntry.charging = false
                    }
                  );
                }

                if($scope.gift.cards.length == 0){

                  Customers.create({
                    source: res.id,
                    email: purchaser.email
                  }, function Success(customer){
                    $scope.gift.customerId = customer.id;
                    ChargeCard(customer.default_source)
                  }, function Error(err){
                    alert(JSON.stringify(err));
                    $scope.gift.cardEntry.charging = false
                  })

                } else {

                  Cards.assign({
                    customerId: $scope.gift.customerId,
                    source: res.id
                  }, function Success(res){
                    ChargeCard(res.id);
                  }, function Error(err){
                    alert(JSON.stringify(err));
                    $scope.gift.cardEntry.charging = false
                  })
                }

              });
            },
            function Error(err){
              console.error(err);
              $scope.gift.cardEntry.charging = false
            }
          );

        }

      }

    , validate: {

        code: function(val){
          if(angular.isDefined($scope.gift) && $scope.gift.code) {
            if($scope.gift.type == 'promo') return $scope.gift.code.match(/^P - /);
            if($scope.gift.type == 'donation') return $scope.gift.code.match(/^D - /);
            if($scope.gift.type == 'zola') return $scope.gift.code.match(/^Z - /);
            if($scope.gift.type == 'card'){
              if($scope.gift.electronic == 'true') {
                return $scope.gift.code.match(/^[a-z0-9]{3}-[a-z0-9]{2}-[a-z0-9]{3}/i);
              } else {
                return $scope.gift.code.match(/^[0-9]{16}/);
              }

            }
          }
          return true;
        }

      , discount: function(val){
          if(!val) return true;
          if(val){
            var perchaseAmount = $scope.gift.amountFloat + ( (!$scope.isElectronic() && $scope.gift.type == 'card') ? 2.5 : 0 )
            var isPercent = /^[0-9]{1,3}%$/.test(val);
            val = parseFloat(val.replace(/%$/, '').replace(/^\$/, ""));
            return (isPercent) ? val <= 100 : perchaseAmount >= val;
          }
        }

      , expiration: function(val){
          var thisMonth, thisYear;
          if(val){
            var split = val.split('/');
            thisMonth = split[0];
            if(split[1]) thisYear = split[1].replace('_', '');
            if(thisYear && thisYear.length == 2) thisYear = "20" + thisYear;
            if(thisMonth > 12) return false;
            if(thisYear && thisYear < year) return false;
          }
          return true
        }

      }

    , remove: function(gift, key){
        var sure = window.confirm('Are you sure you want to remove this gift?\n\r\n\rThere\'s no going back!');

        if(sure){
          Gifts.remove(gift._id,
            function Success(res){
              $scope.gifts.results.splice(key, 1);
            },
            function Error(err){

            }
          );
        }

      }

    , resend: function(gift){
        gift.sending = true;
        Gifts.send(gift._id,
          function Success(res){
            gift.sending = false;
            gift.isSent = true;
          },
          function Error(err){
            gift.sending = false;
            gift.isSent = false;
          }
        );
      }

    , submit: function(){

        $scope.saving = true;

        $scope.gift.amount = (angular.isString($scope.gift.amount)) ? $scope.gift.amount.replace('$', '') : $scope.gift.amount;

        if(angular.isUndefined($scope.gift._id)){

          Gifts.create($scope.gift,
            function Success(res){
              $state.go('gifts', { page: $scope.trans.from.page || 1 });
            },
            function Error(err){
              var message = (angular.isDefined(err.message)) ? err.message : err;
              alert(message);
              console.error(err);
              if(!$scope.errors) $scope.errors = {};
              $scope.errors[err.code] = err.message;
              $scope.saving = false;
            }
          );

        } else {

          Gifts.update($scope.gift,
            function Success(res){
              $state.go('gifts')
            },
            function Error(err){}
          );

        }
      }
  };

  angular.extend($scope, self);

});
