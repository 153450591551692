var ArrayFilter = angular.module('RessieApp.filters.object', [])

.filter('objectKeys', function () {

  return function (object) {

    if (!object) { return object; }

    return Object.keys(object);

  };

})

.filter('toArray', function () {
  return function (obj, addKey) {
    var arr = undefined;
    if ( addKey === false ) {
      arr = Object.keys(obj).map(function(key) {
        return obj[key];
      });
    } else {
      if(typeof obj == 'object'){
        arr = Object.keys(obj).map(function (key) {
          if(typeof obj[key] == 'object') {
            return Object.defineProperty(obj[key], '$key', { enumerable: false, value: key });
          }
        });
      }
    }
    return arr;
  };
})

.filter('excludeKeys', function () {
  return function (obj, keys) {
    if(obj && obj.constructor.name === 'Object'){
      var keys = keys ? keys.split(',') : [];
      angular.forEach(keys, function(removeKey){
        delete obj[removeKey];
      })
      return obj;
    }
    return obj;
  };
});
