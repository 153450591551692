var CardFilter = angular.module('RessieApp.filters.card', [])

.filter('card', [function () {

  return function (number) {

    if (!number) { return ''; }

    if(number.length == 16){
      number = number.match(new RegExp('.{1,4}', 'g')).join("-");
    }

    return number

  };

}])

.filter('cardName', [function () {

  return function (string) {

    if (!string) { return ''; }
    if(/american express$/i.test(string)) return 'Amex';
    if(/master( )?card$/i.test(string)) return 'MC';
    if(/discover$/i.test(string)) return 'Disc';
    return string

  };

}]);
