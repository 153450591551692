angular.module('RessieApp.directives.hallo', [])

.directive('hallo', ['$window', '$timeout', '$parse', function($window, $timeout, $parse){
  
  return {
      restrict: 'AC'
    , scope: {
        'ngModel' : '='
      }
    , compile: function(tElement, tAttrs) {

        return function Link(scope, elm, attr) {

          function Update(){}

          var params = scope.$eval(attr.hallo) || {}
            , bound = angular.isDefined(attr.ngModel)
            , contents = bound ? scope.ngModel : elm.html();

          scope.event = null;

          scope.$watch('ngModel', function(n, o){
            if(bound){
              if(!scope.event || scope.event.type !== 'hallomodified') elm.html(n);
            }
            scope.event = null;
          });

          elm
            .hallo(params)
            .html(contents)
            .addClass('editable');
                  
          if(bound) {
            elm.bind('hallomodified', function(event, data) {
              scope.$apply(function(){
                contents = data.content;
                scope.ngModel = contents;
                scope.event = event;
              });
            });
          }

        }

      }
  };

}]);