'use strict';

var PickupsCtrl = angular.module('RessieApp')

.controller('pickupsCtrl', function (allowed, $timeout, $scope, pickups, $rootScope, $state, $stateParams, $window, Bookings) {

  if(!allowed) $state.go('login');

  AppCtrl.activeTab($stateParams, $rootScope);

  /**
   * Conditional variables based on resolver data
   */
  if (pickups.bookings) $scope.bookings = pickups.bookings;
  if (pickups.meta.drivers) $scope.drivers = pickups.meta.drivers;
  if (pickups.meta.flights) $scope.flights = pickups.meta.flights;

  var self = {

    getTime: function(time, format){
      return $window.moment.utc(time).format(format);
    },

    toDate: function(pickup){
      var arr = pickup.pickup.time.replace(/am|\s/gi, '').split(':');
      return $window.moment().hours(arr[0]).minutes(arr[1]).toDate();
    },

    notAssigned: function(selected, booking){
      if(selected){
        var pre_selected = [];
        var drivers = booking.pickup.drivers;
        angular.forEach(drivers, function(driver, key){
          pre_selected.push(JSON.parse(driver)._id);
        })
        $.inArray(selected._id, pre_selected) == -1
      } else {
        return true;
      }
    },

    assignFlight: function(booking) {
      var tmpFlight = JSON.parse(booking.tmpFlight);

      function Assign(flight, count) {
        booking._flight = flight._id;
        // if (booking.flight.length) {
        //   angular.forEach(booking.flight, function(flight, i){
        //     $scope.unassignFlight(booking, flight.flight);
        //   })
        // }
        Bookings.assignFlight(booking,
          function Success(res) {
            booking.flight = res.flight;
            angular.forEach(res.flight, function(assigned, i){
              if(assigned.flight === flight._id) {
                console.log(flight);
                booking.flight[i].flight = flight;
              }
            })
          },
          function Error(err) {
            console.error(err)
          }
        );
      }

      Assign(tmpFlight, booking.adults + (booking.children || 0))

    },

    unassignFlight: function(booking, flight, cb) {
      Bookings.unassignFlight(booking,
        function Success(res) {
          booking.flight = booking.flight.filter(function (assigned) {
            return assigned.flight._id !== flight._id;
          })
        },
        function Error(err) {
          var message = (angular.isDefined(err.message)) ? err.message : err;
          alert(message)
        }
      );
    },

    addDriver: function(booking){
      booking.pickup.drivers.push(JSON.parse(booking.newDriver));
      booking.newDriver = null;
    },

    update: function(booking){

      var reservation = angular.copy(booking);

      var drivers = [];
      // Cast driver object to id before save
      angular.forEach(reservation.pickup.drivers, function(driver){
        drivers.push(driver._id);
      });

      reservation.pickup.time = reservation.pickup._time;

      reservation.pickup.drivers = drivers;
      delete reservation.accommodations; // arent changing it, dont need it.

      Bookings.update(reservation, function Success(res){
        $timeout(function () {
          console.log(reservation.pickup.time)
          booking.pickup.time = reservation.pickup.time;
          booking.editable = false;
        })
      }, function Error(err){
        console.error(err);
      });
    },

    buildTip: function(booking){

        function Header(text){
          return html + "<h6 style='margin-bottom: 0;'>"+ text +"</h6>";
        }

        function Paragraph(text){
          return html + "<p>" + text + "</p>";
        }

        var html = "";
        if(booking.notes){
          if(booking.notes.instructions){
            html = Header('Special Instructions');
            html = Paragraph(booking.notes.instructions);
          }
          if(booking.notes.internal){
            html = Header('Internal Notes');
            html = Paragraph(booking.notes.internal);
          }
          if(booking.notes.memo){
            html = Header('Memo');
            html = Paragraph(booking.notes.memo);
          }
          if(booking.notes.memo){
            html = Header('Occasion');
            html = Paragraph(booking.notes.occasion);
          }
        }

        if(booking.pickup){
          if(booking.pickup.notes){
            html = Header('Notes for Driver');
            html = Paragraph(booking.pickup.notes);
          }
        }

        if(!booking.notes && !booking.pickup){
          html = false;
        }

        return html ? "<div style='text-align: left;'>" + html + "</div>" : html;
      }

  };

  angular.extend($scope, self);

});
