angular.module('RessieApp.directives.scrollable', [])

.directive('scrollable', ['$window', '$timeout', function($window, $timeout){
  
  var win = $(window);

  return {
    restrict: 'CA'
  , controller: function($scope, $rootScope){

      var element;

      /**
       * This ensures that the user starts at the top of the scrollable pane 
       * when they change states.
       */
      $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState){
        $timeout(function(){
          element.scrollTop(0);
        }, 0);
      });

      $scope.init = function(elm){
        element = elm;
      };

      $scope.resize = function(extra){

        var extra = extra || null
          , height = win.height()
          , offset = element.offset()
          , newHeight = height - offset.top;

        if(extra) newHeight = newHeight + extra;

        element.css({
          height: newHeight
        });

      };

      $rootScope.$watch('fetch.shown', function(n, o){
        $scope.resize()
      });

    }
  , link: function(scope, element, attrs){

      scope.element = element;

      scope.init(element);

      scope.resize();

      win.resize(function(){
        scope.resize();
      })

    }

  }

}])

.directive('infinite', ['$window', '$q', '$rootScope', '$timeout', '$state', function ($window, $q, $rootScope, $timeout, $state) {

  return {
      restrict: 'AE'
    , require: '^scrollable'
    , link: function (scope, elem, attrs) {

        scope.active = false;

        var scroller = scope.$parent.element
          , previousScroll = 0
          , scroll = function(data){

              function scrollDir(){
                var direction
                  , currentScroll = $(data.currentTarget).scrollTop();

                 direction = (currentScroll > previousScroll) ? 'down' : 'up';
                 previousScroll = currentScroll;
                 return direction;
              };
              
              var scrollDirection = scrollDir()
                , deferred = $q.defer()
                , infinite = $state.current.infinite || false
                , parentBottom = scroller.height() + parseInt(scroller.css('paddingTop')) + parseInt(scroller.css('paddingBottom'))
                , elmFromTop = Math.ceil((scroller.scrollTop() + elem.offset().top) - scroller.offset().top)
                , elmBottom = (elem.height() + elmFromTop) - scroller.scrollTop()
                , buffer = 100;
              
              function promise(){
                if(!scope.active) {
                  deferred.resolve();
                  $rootScope.spinner.active = true;
                  scope.active = true;
                } else {
                  deferred.reject()
                }

                return deferred.promise;

              } 

              if(infinite){

                if(scrollDirection == 'down' && elmBottom - parentBottom <= buffer) {
                  var promise = promise();

                  promise.then(
                    function Success(res){
                      // using `$timeout` to avoid `$digest` warnings
                      $timeout(function(){                      
                        scope.$eval(attrs.infinite).promise.then(
                          function Success(res){
                            $timeout(function(){ 
                              scope.active = false;
                              $rootScope.spinner.active = false; 
                            }, 500);
                          },
                          function Error(err){}
                        );

                      });
                    },
                    function Error(err){}
                  );
                }

              }

            };      

        scroller.unbind('scroll');
        scroller.bind('scroll', scroll);

      }
  };

}]);



