var parser = new NameParse();

var NameFilters = angular.module('RessieApp.filters.name', [])

.service('Name', function () {

  var shortcuts = {
      first: 'firstName'
    , last: 'lastName'
    , salutation: 'salutation'
    , initials: 'initials'
    , suffix: 'suffix'
  };

  function Name(name){
    this._name = name;
    if(typeof name == 'string') this.name = parser.parse(name);
    if(typeof name == 'object') this.name = name;
    return this;
  }
  

  Name.prototype.getSection = function(section){
    return this.name[shortcuts[section]];
  }

  Name.prototype.parsed = function(){
    return {
      salutation: this.getSection('salutation'),
      first: this.getSection('first'),
      initials: this.getSection('initials'),
      last: this.getSection('last'),
      suffix: this.getSection('suffix')
    }
  }

  /**
   * Return the fully concatenated name
   * Formalized, with salutation, first, middle initial, last and suffix.
   * @return {String} Formal Name
   */
  Name.prototype.getFormal = function(){
    var name = 
      (this.name.salutation.match(/\.$/g) ? this.name.salutation + " " : (this.name.salutation + ". ") || "") +
      (this.name.firstName || this.name.first) + " " +
      ((this.name.initials !== "") ? (this.name.initials + ".") : "") + " " +
      (this.name.lastName || this.name.last).replace(/,$/g, "") + "" +
      ((this.name.suffix) ? ", " + this.name.suffix : "");
    return name.trim().replace(/\s+/g, " ");
  }

  /**
   * Simplified Full name.
   *
   * Only returns first and last name, strips away salutation, middle initials and suffix if present.
   * @return {String} First and Last name
   */
  Name.prototype.getFull = function(){

    var name = 
      (this.name.firstName || this.name.first) + " " +
      (this.name.lastName || this.name.last).replace(/,$/g, "");

    return name.trim().replace(/\s+/g, " ");
  }

  return Name;

})

.filter('name', function (Name) {

  return function (string, section) {

    return new Name(string).getSection(section)

  };

});

