var APIUsers = angular.module('RessieApp.services.api.users', [])

.service('Users', function(Request, API, $rootScope, $state, $timeout, $window){

  var self = this;

  self.queued = {};
  self.active = {};

  self.endpoints = function(key, company){

    if(!company) company = angular.isDefined($rootScope.user) && angular.isDefined($rootScope.user.useCompany) ? $rootScope.user.Company() : null;

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

      auth: {
          method: 'POST'
        , url: '/users/auth'
        , isArray: false
      }

    , get: {
          method: 'GET'
        , url: '/user/:id'
        , isArray: false
        , headers: {
            'Authorization': key,
            'Company': company
          }
      }

    , passwordReset: {
          method: 'GET'
        , url: '/reset/:_id'
        , isArray: false
        , headers: {
            'Authorization': key,
            'Company': company
          }
      }

    , forVerification: {
          method: 'GET'
        , url: '/verify/:company/:user'
        , isArray: false
        , headers: {}
      }

    , setPassword: {
          method: 'PUT'
        , url: '/verify/:company/:user'
        , params: { company : '@company', user: '@user' }
        , isArray: false
        , headers: {}
      }

    , update: {
          method: 'PUT'
        , url: '/user/:id'
        , params: { id : '@_id' }
        , isArray: false
        , headers: {
            'Authorization': key,
            'Company': company
          }
      }

    , delete: {
          method: 'DELETE'
        , url: '/user/:_id'
        // , params: { id : '@_id' }
        , isArray: false
        , headers: {
            'Authorization': key,
            'Company': company
          }
      }

    , save: {
          method: 'POST'
        , url: '/user'
        , isArray: false
        , headers: {
            'Authorization': key,
            'Company': company
          }
      }

    , list: {
          method: 'GET'
        , url: '/users'
        , isArray: false
        , headers: {
            'Authorization': key,
            'Company': company
          }
      }

    , locations: {
          method: 'GET'
        , url: '/users/location'
        , isArray: true
        , headers: {
          'Authorization': key,
          'Company': company
        }
      }

    , ofType: {
          method: 'GET'
        , url: '/users/:type'
        , isArray: true
        , headers: {
            'Authorization': key,
            'Company': company
          }
      }

    }

  };

  self.resource = function(key, company){
    company = company || null;
    var endpoints = self.endpoints(key, company);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource(endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */

  self.request = function(endpoint, options, success, error){

    var company = options.payload.companyId || undefined;

    if(company) delete options.payload.companyId;

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key, company)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) $timeout(function(){ return error(response.error); });
      } else {
        Request.renew(token);
        return success(response, token);
      }

    });
  };

  self.forVerification = function(payload, success, error) {

    self.request('forVerification',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.setPassword = function(payload, success, error) {

    self.request('setPassword',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.update = function(payload, success, error) {

    self.request('update',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.locations = function (success, error) {

    self.request('locations',
      {
        payload: {}
      },
      function Success(response, token) {
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if (angular.isDefined(success)) return success(response, token);

      },
      function Error(err) {
        if (angular.isDefined(error)) return error(err);
      }

    );

  };

  self.delete = function(payload, success, error) {

    self.request('delete',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.save = function(payload, success, error) {

    self.request('save',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        // API.api_key = response.api_key;
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.auth = function(payload, success, error) {

    self.request('auth',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        API.api_key = response.api_key;
        if(response.error === undefined && angular.isDefined(success)) return success(response, token);
        if(response.error !== undefined) return error(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.get = function(payload, success, error) {

    delete payload.token;

    self.request('get',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        $rootScope.groups = response.security_groups || null;
        $rootScope.roles = response.roles || null;
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.passwordReset = function(payload, success, error) {

    self.request('passwordReset',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.ofType = function(type, success, error) {

    self.request('ofType',
      {
        payload: { type: type }
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.list = function(payload, success, error) {

    if(typeof payload == 'function'){
      success = payload;
      error = success;
      payload = {};
    }

    self.request('list',
      {
        payload: payload
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response, token);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };


});
