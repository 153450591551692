var TimeFilters = angular.module('RessieApp.filters.time', [])

.service('Time', function($window){
  var self = this;

  self.shortcuts = {
    formats: {
      'long date'       : 'MMMM Do YYYY',
      'long date/time'  : 'MMMM Do YYYY, h:mm:ss a',
      'detailed'        : 'dddd, MMM. Do YYYY'
    },
    times: {
      'yesterday' : $window.moment().subtract(1, 'day'),
      'today'     : $window.moment(),
      'tomorrow'  : $window.moment().add(1, 'day')
    }
  };

  self.isUTC = function(date){
    return /\.000Z/.test(date) !== false;
  };

  self.toMoment = function(date, utc){

    utc = utc === undefined ? false : utc;

    /**
     * Year-Month-Day regex
     * Matches 2014-10-13, and 2014-10-13T06:15:00.000Z.
     * @type {RegExp}
     */
    var ymd = /^(?:(19|20\d\d)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))[T| ](?:([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]).\d{3})?Z?$/;

    /**
     * Month-Day-Year regex
     * Matches 10-16-14, and 10-16-2014
     * @type {RegExp}
     */
    var mdy = /^([1-9]|0[1-9]|1[012])-([1-9]|0[1-9]|[12][0-9]|3[01])-((19|20)?\d\d)/;

    if(typeof date == 'string'){

      var isYmd = ymd.exec(date);
      var isMdy = mdy.exec(date);

      var split = [];

      if(isYmd) {
        isYmd.splice(0,1);
        angular.forEach(isYmd, function(string){
          split.push(parseInt(string))
        });
      }

      if(isMdy) {

        var year = parseInt(isMdy[3]);

        if(year < 2000) {
          split.push(year + 2000);
        } else {
          split.push(year);
        }

        split.push(parseInt(isMdy[1]));
        split.push(parseInt(isMdy[2]));

      }

      if(split.length){
        date = utc ? new Date(Date.UTC(split[0], split[1] - 1, split[2], split[3] || 0, split[4] || 0, split[5] || 0)) :
        new Date(split[0], split[1] - 1, split[2], split[3] || 0, split[4] || 0, split[5] || 0);
      } else {
        var quickDate = date.match(/\d{1,2}[\/|\-]\d{1,2}([\/|\-])(\d{2,4})/);
        if(quickDate){
          var sep = quickDate[1];
          var yearFormat = quickDate[2].length === 4 ? 'YYYY' : 'YY';
          date = $window.moment(date, 'MM' + sep + 'DD' + sep + yearFormat).toDate();
        }
      }

    } else {
      date = date
    }

    // if the date is one of the time shortcuts it returns a moment object, otherwise build one.
    var moment = angular.isDefined(self.shortcuts.times[date]) ? self.shortcuts.times[date] : $window.moment(date);

    if(utc) return moment.utc();

    return moment;

  }

  return self;

})

.filter('ago', function(Time){

  return function(time, measurement){

    measurement = measurement || 'days';

    var shortcuts = Time.shortcuts
      , moment
      , className = time.constructor.name
      , now = window.moment();
    ;

    if(className !== 'Moment'){
      if(angular.isDefined(Time.shortcuts.times[time])) {
        moment = Time.shortcuts.times[time];
      } else {
        moment = window.moment(time, 'MM-DD-YY')
      }
    } else {
      moment = time;
    }

    if(measurement == 'days'){
      now.hours(0).minutes(0);
      moment.hours(0).minutes(0);
    }

    return now.diff(moment, measurement);

  }
})

.filter('readTime', ['$window', 'Time', function ($window, Time) {
  return function (date, format, utc, toLocal /* true to convert to users local timezone if saved as UTC*/) {
    var shortcuts = Time.shortcuts
      , moment;

    var isoDateReg = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
    // wow, I really put a lot of crap in here back in the day...
    // if a date is saved as UTC (all should be) then this will convert it to the clients local time, bypassing all of the noise blow it :/
    if(typeof date === 'string' && toLocal && isoDateReg.test(date)) {
      date = new Date(date);
    } else {

      // If timestamp is UTC and we dont tell the filter to ignore conversion.
      var isUTC = /\.000Z/.test(date) && utc !== false;

      /**
       * Year-Month-Day regex
       * Matches 2014-10-13, and 2014-10-13T06:15:00.000Z.
       * @type {RegExp}
       */
      var ymd = /^(?:(19|20\d\d)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))[T| ](?:([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]).\d{3})?Z?$/;

      /**
       * Month-Day-Year regex
       * Matches 10-16-14, and 10-16-2014
       * @type {RegExp}
       */
      var mdy = /^([1-9]|0[1-9]|1[012])-([1-9]|0[1-9]|[12][0-9]|3[01])-((19|20)?\d\d)/;

      // if the date isn't a proper date object, and isn't a date shortcut we need to create a new date object
      if(!(date instanceof Date) && !angular.isDefined(shortcuts.times[date])) {

        if(typeof date == 'string'){

          var isYmd = ymd.exec(date);
          var isMdy = mdy.exec(date);

          var split = [];

          if(isYmd) {
            isYmd.splice(0,1);
            angular.forEach(isYmd, function(string){
              split.push(parseInt(string))
            });
          }

          if(isMdy) {

            var year = parseInt(isMdy[3]);

            if(year < 2000) {
              split.push(year + 2000);
            } else {
              split.push(year);
            }

            split.push(parseInt(isMdy[1]));
            split.push(parseInt(isMdy[2]));

          }

          if(split.length){
            date = isUTC ? new Date(Date.UTC(split[0], split[1] - 1, split[2], split[3] || 0, split[4] || 0, split[5] || 0)) :
            new Date(split[0], split[1] - 1, split[2], split[3] || 0, split[4] || 0, split[5] || 0);
          } else {
            var quickDate = date.match(/\d{1,2}[\/|\-]\d{1,2}([\/|\-])(\d{2,4})/);
            if(quickDate){
              var sep = quickDate[1];
              var yearFormat = quickDate[2].length === 4 ? 'YYYY' : 'YY';
              date = $window.moment(date, 'MM' + sep + 'DD' + sep + yearFormat).toDate();
            }
          }

          if (toLocal) console.log(split);

        } else {
          date = date
        }

      } else {
        date = date
      }
    }

    // Format can either be manually passed or can be one of the above shortcuts
    format = angular.isDefined(shortcuts.formats[format]) ? shortcuts.formats[format] : format;

    // if the date is one of the time shortcuts it returns a moment object, otherwise build one.
    moment = angular.isDefined(shortcuts.times[date]) ? shortcuts.times[date] : $window.moment(date);

    if(angular.isDefined(utc) && utc) return moment.utc().format(format);

    return moment.format(format);

  };

}])

.filter('addTime', ['$window', function ($window) {
  return function (date, focus, num) {

    var shortcuts = {

        times: {
            'yesterday' : $window.moment().subtract(1, 'day')
          , 'today'     : $window.moment()
          , 'tomorrow'  : $window.moment().add(1, 'day')
        }

    };

    var moment = angular.isDefined(shortcuts.times[date]) ? shortcuts.times[date] : $window.moment(date, 'MM-DD-YY');

    return moment.add(num, focus);

  };

}])

.filter('subTime', ['$window', function ($window) {
  return function (date, focus, num) {

    var shortcuts = {

        times: {
            'yesterday' : $window.moment().subtract(1, 'day')
          , 'today'     : $window.moment()
          , 'tomorrow'  : $window.moment().add(1, 'day')
        }

    };

    var moment = angular.isDefined(shortcuts.times[date]) ? shortcuts.times[date] : $window.moment(date, 'MM-DD-YY');

    return moment.subtract(num, focus);

  };

}])

.filter('rawTime', ['$window', '$timeout', 'Time', function ($window, $timeout, Time) {

  return function (date, format, utc) {

    if(format){
      if(format.constructor.name === 'Boolean'){
        utc = format;
        format = 'MM-DD-YY';
      }
    }
    
    utc = utc !== undefined ? utc : false;
    
    date = Time.toMoment(date, utc)

    var isTime;

    // if(date.match(/\d{2}:\d{2}/gi)) isTime = !$window.moment('06:15am').isValid() && date.match(/\d{2}:\d{2}/gi)[0].split(':');

    if(isTime){
      date = $window.moment().hours(isTime[0]).minutes(isTime[1]).toDate();
    }

    $timeout(function(){

      var shortcuts = {

          times: {
              'yesterday' : $window.moment().subtract(1, 'day')
            , 'today'     : $window.moment()
            , 'tomorrow'  : $window.moment().add(1, 'day')
          }

      };

      var moment = angular.isDefined(shortcuts.times[date]) ? shortcuts.times[date] : utc ? $window.moment.utc(date, format) : $window.moment(date, format);

      return moment.toDate();

    });

  };

}])

.filter('momentjs', function(Time){
  return function(date, utc) {
    if(!date) return '';
    return Time.toMoment(date, utc);
  }
})
