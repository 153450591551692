'use strict';

var emailReg = new RegExp(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i);

AppCtrl.factory('PendingBookings', function(){
  var self = {
    setPendings: function(arr){
      self.pendings = arr;
    },
    clearPendings: function(){
      self.pendings = [];
    },
    pendings: []
  };
  return self;
});

var showOtherLabel = false;
var ReservationsCtrl = angular.module('RessieApp')

// a way to default providers ... sloppy but I'll take it
.value('allowed', true)
.value('bookings', true)
.value('bundles', [])

.controller('contactModalCtrl', function($scope, $modalInstance, agents, concierges, booking, contact, type, Contacts, $timeout){

  $scope.type = type;
  $scope.contact = contact || {};
  $scope.contact.meta = $scope.contact.meta || {};
  $scope.contact.meta.type = type;
  $scope.booking = booking;
  $scope.creatingContact = false;
  $scope.agents = agents;
  $scope.concierges = concierges;

  $scope.update = function(booking, contact){
    var data;
    if(contact.name !== undefined){
      data = contact;
      delete data.extras;
      $scope.creatingContact = true;
      Contacts.save(data,
        function Success(res){
          if(res.meta.agent) booking.agent = res;
          $modalInstance.close({ booking: booking, contact: res });
          $scope.creatingContact = false;
        },
        function Error(err){
          $scope.creatingContact = false;
          alert(JSON.stringify(err));
        }
      );
    } else {
      $modalInstance.close({ booking: booking, contact: null, type: type });
    }

  }
})

.controller('bundleModal', function ($scope, $modalInstance, booking, oldBundle, newBundle) {
  $scope.old = oldBundle;
  $scope.new = newBundle;

  $scope.clear = function() {
    $modalInstance.close({
      choice: 'clear',
      removal: newBundle._id === undefined
    });
  }

  $scope.keep = function() {
    $modalInstance.close({
      choice: 'keep',
      removal: newBundle._id === undefined
    });
  }

  $scope.cancel = function () {
    $modalInstance.dismiss();
  }

})

.controller('reservationsCtrl', function ($modal, $filter, $scope, $rootScope, allowed, $state, $stateParams, $window, bookings, bundles, $timeout, Time, StripeCard, Customers, Cards, Pricing, GuestCard, Bookings, Upload, Events, Legacy, Pickups, $templateCache, $compile, Messages, Contacts, Socket) {

  var Package; // placeholder for selected package

  $scope.hotelsList = [];

  $scope.bundles = bundles || [];

  if(!allowed) $state.go('login');
  if($rootScope.user.restricted) $state.go('gifts');

  // $rootScope.$on('$stateChangeSuccess', function(e, toState, toParams, fromState){
  //   // Edit form dirty?
  //   if(angular.isDefined($scope.BookingForm)){
  //   }
  // });

  Socket.on('cancelledBooking', function(res){
    var response = res.response;
    var company = response.company;
    var booking = $filter('filter')($scope.bookings, { '_id': response._id, company: response.company });
    booking = booking && booking.length ? booking[0] : undefined;
    if(booking) {
      booking.status = 'cancelled';
      booking.notify = true;
      var guestCount = booking.adults + (booking.children || 0)
      $scope.event.capacity.remaining += guestCount;
      $scope.event.capacity.booked -= guestCount;
    }
  })

  /**
   * Conditional variables based on resolver data
   */
  if(bookings){

    if(typeof bookings == 'boolean' && angular.isDefined($scope.$parent.bookings)) {
      bookings = $scope.$parent.bookings;
    }

    if(angular.isArray(bookings)) $scope.bookings = bookings;

    /**
     * Meta data required for certain pages
     */
    if(angular.isDefined(bookings.meta)){
      var meta = bookings.meta;
      $rootScope.vipPricing = meta.vip_pricing;
      angular.forEach(meta, function(data, key){
        // if(key == 'hotels') $scope.hotelsList = $filter('toArray')(meta[key]);
        if(angular.isDefined(meta[key])) $scope[key] = $rootScope[key] = data;
      });
      if(bookings.accommodations !== undefined && bookings.accommodations.other && bookings.accommodations.other !== undefined){
        $scope.hotelsList.push({ _id: undefined, name: bookings.accommodations.other + " <div class='extras'><span class='label lter bg-info'>other</span></div>" });
        bookings.accommodations.hotel = $scope.hotelsList[$scope.hotelsList.length - 1];
      }
    }

    if(angular.isDefined(bookings.reservation)){
      $scope.reservation = bookings.reservation;
      $timeout(function(){
        if(angular.isDefined(bookings.meta.capacity)) $scope.reservation.capacity = bookings.meta.capacity;
      });
    }

  }

  if($state.current.templateUrl === 'views/booking/form'){
    Contacts.byType('hotel', function Success(contacts){
      $scope.hotelsList = $filter('toArray')(contacts.contacts);
      $scope.hotels = contacts.contacts;
    })
  }

  $scope.isHeavy = function(booking) {
    return booking.meta.weights.find((w) => w >= 275) !== undefined;
  }

  $scope.viewing = function(booking){
    try {
      return this.openedBookings[booking.company._id][booking.confirmation];
    } catch (error) {}
  };

  $scope.listViewing = function(booking){
    return $scope.viewing(booking).map(function(user){
      return user.name;
    }).join(', ')
  };

  function watchPricing(){
    $scope.$watch($scope.reservation.pricing.Balance(), function(n, o){
      if(n && $scope.reservation.payment) $scope.reservation.payment.balance = n;
    });

    $scope.$watch($scope.reservation.pricing.items, function(n){
      $scope.reservation.line_items = n;
    }, true);

    $scope.$watch($scope.reservation.pricing.discount_value, function(n){
      if($scope.reservation.payment) $scope.reservation.payment.discount = n;
    });

    $scope.$watch($scope.reservation.pricing.discount_name, function(n){
      if($scope.reservation.payment) $scope.reservation.payment.discount_name = n;
    });

  }

  function RemoveBundledItems(bundle) {
    var bundleItemIds = bundle.items.map(function(item) {
      return item.product._id || item.product;
    });
    $scope.reservation.line_items = $scope.reservation.line_items.filter(function(item) {
      return !bundleItemIds.includes(item.product._id)
    });
  }

  function RemoveBundledDiscount(bundle) {
    $timeout(function () {
      $scope.reservation.payment = $scope.reservation.payment || {};
      $scope.reservation.payment.discount = "0";
      // $scope.reservation.pricing = new Pricing($scope.reservation)
    })
  }

  function BundleItems(bundle) {
    var items = $scope.reservation.line_items || [];
    angular.forEach(bundle.items, function (item) {
      items.push(item);
    })
    $scope.reservation.line_items = items;
  }

  function BundlePackage(bundle) {
    $timeout(function() {
      if (bundle.package) {
        $scope.reservation._package = bundle.package;
        $scope.reservation.package = bundle.package;
        $timeout(function () {
          $('#reservationPackage').trigger('change');
        });
      }
    })
  }

  function BundleHotel(bundle) {
    $scope.reservation.accommodations = $scope.reservation.accommodations || {};
    $scope.reservation.accommodations.hotel = bundle.hotel;
  }

  function BundleConcierge(bundle) {
    $scope.reservation.accommodations = $scope.reservation.accommodations || {};
    $scope.reservation.accommodations.concierge = bundle.concierge;
  }

  function BundleTravelAgent(bundle) {
    if (bundle.agent) {
      $scope.reservation.agent = $scope.reservation.agent || {};
      $scope.reservation.agent = bundle.agent._id || bundle.agent;
    }
  }

  function BundleWaitlist(bundle) {
    if(bundle.waitlist) {
      $scope.reservation.status = 'waiting';
      $scope.reservation.waiting = true;
    }
  }

  function BundleDiscount(bundle) {
    $timeout(function () {
      if (bundle) {
        $scope.reservation.payment = $scope.reservation.payment || {};
        $scope.reservation.payment.discount = bundle.discount;
      }
    })
  }

  function AssignAll(bundle) {
    $timeout(function () {
      BundleItems(bundle);
      BundlePackage(bundle);
      BundleHotel(bundle);
      BundleConcierge(bundle);
      BundleTravelAgent(bundle);
      BundleWaitlist(bundle);
      BundleDiscount(bundle);
      $scope.reservation.pricing = new Pricing($scope.reservation)
    })
  }

  function HandleChange(oldBundle, newBundle) {
    var modalInstance = $modal.open({
      templateUrl: 'views/booking/bundleModal',
      controller: 'bundleModal',
      resolve: {
        oldBundle: function () {
          return oldBundle;
        },
        newBundle: function () {
          return newBundle;
        },
        booking: function () {
          return $scope.reservation;
        },
      }
    });

    modalInstance.result.then(function (obj) {
      var choice = obj.choice;
      var removal = obj.removal;
      if (choice === 'clear') {
        RemoveBundledItems(oldBundle);
        RemoveBundledDiscount(oldBundle);
        $scope.reservation.pricing = new Pricing($scope.reservation)
        if (!removal) {
          AssignAll(newBundle);
        }
        $scope.reservation.bundle = null;
      }

      if (choice === 'keep') {
        AssignAll(newBundle);
      }
      if (choice === 'cancel') {
        $scope.reservation.bundle = oldBundle;
      }
    }, function () {
      $scope.reservation.bundle = oldBundle;
    });
  }

  $scope.$watch(function () {
    return ($scope.reservation.adults || 0) + ($scope.reservation.children || 0);
  }, function (n, o) {
    if (n && n !== 0) {
      BundleDiscount($scope.reservation.bundle);
      if ($scope.reservation.bundle) {
      }
    }
  })

  $scope.$watch('reservation.bundle', function (n, o) {
    // First setting, no warning.
    if (n && (o === undefined || o === null)) {
      AssignAll(n);
    } else if (o && n && o._id !== n._id) {
      if (o._id !== undefined) {
        HandleChange(o, n);
      }
    }
  });

  /**
   * Watch the pricing service for the balance
   * To be used outside of the pricing directives.
   * @return {[type]} [description]
   */
  if(angular.isDefined($scope.reservation)){
    watchPricing();
  }

  $scope.$parent.$watch('showCover', function(n){
    if(!n && $scope.modifiers) $scope.modifiers = false;
  })

  $scope.$watch('modifiers', function(n, o){
    $scope.$parent.showCover = n;
  })

  $scope.$watch('reservation.package', function(n, o){
    if(n){
      if(typeof n == 'string') n = JSON.parse(n);
      if($scope.reservation._package !== n._id) $scope.reservation._package = n._id;

      if (n.free_pickup) {
        $scope.reservation.meta.free_pickup = true
      }

      var needs_pickup = $scope.reservation.needs_pickup;
      var is_vip = $scope.reservation.vip;
      var is_group = $scope.reservation.group;

      Package = $scope.getPackage();

      var template = Package.templates.general;

      if (is_group) {
        if (needs_pickup) {
          template = Package.templates.pickup_group !== '' && Package.templates.pickup_group;
        } else {
          template = Package.templates.group !== '' && Package.templates.group;
        }
      }

      if(is_vip) {
        if(needs_pickup) {
          template = Package.templates.pickup_vip !== '' && Package.templates.pickup_vip;
        } else {
          template = Package.templates.vip !== '' && Package.templates.vip;
        }
      } else {
        if(needs_pickup) {
          template = Package.templates.pickup !== '' && Package.templates.pickup;
        }
      }

      $scope.reservation.communication = $scope.reservation.communication || {};
      $scope.reservation.communication.template = template;

    }
  }, true)

  $scope.$watch('reservation._package', function(n, o){
    if(n && n !== ""){
      Package = $scope.reservation.package = $scope.getPackage();
      $scope.BookingForm._package.$setValidity('required', true);
    } else {
      if($scope.BookingForm){
        $scope.BookingForm._package.$setValidity('required', false);
        $scope.BookingForm._package.$setPristine();
      }
    }
  })

  $scope.$watch('reservation.needs_pickup', function(n, o){

    function getTemplate(){
      var vip_template = !n && Package.templates.vip;
      var vip_pickup_template = n && $scope.reservation.vip && Package.templates.pickup_vip;
      var pickup_template = n && Package.templates.pickup;
      var group_template = !n && $scope.reservation.group && Package.templates.group;
      var group_pickup_template = n && $scope.reservation.group && Package.templates.pickup_group;
      var standard_template = Package.templates.general;
      return vip_template || vip_pickup_template || group_template || group_pickup_template || pickup_template || standard_template;
    }

    $scope.reservation.communication = $scope.reservation.communication || {};

    if(n !== o) {
      $scope.calcPickup();
      $timeout(function(){ $('#reservationPackage').trigger('change'); });
      if(angular.isDefined($scope.reservation.package)){
        console.log('come on ...')
        $scope.reservation.communication.template = getTemplate();
      }
    }

  });

  $scope.$watch('reservation.day', function(n, o){
    if(n){
      var date = $window.moment.utc(n, 'MM/DD/YYYY');
      // $scope.calday = date.toDate();
      $scope.date = date;

      if(n !== o) {
        $scope.dateChanged = true;

        if((angular.isDefined($state.params.day) && angular.isDefined($state.params.tab)) || $state.params.action == 'add' || $state.params.confirmation !== undefined){
          if($state.current.name !== 'day'){
            Bookings.confirmation({ day: date.format('MM-DD-YYYY') },
              function Success(response){
                if(!response.meta.meetingTime) {
                  alert('No Meeting Time Defined for ' + date.clone().format('MMMM Do, YYYY') + ', Closed.');
                } else {
                  $scope.meetingTime = response.meta.meetingTime;
                  $scope.date = date;
                  $scope.reservation.day = date.format('MM/DD/YYYY');
                }
              },
              function Error(err){
                deferred.reject(err);
              }
            );
          }

        }

      }

    } else {
      $scope.date = null;
    }
    // location.skipReload().path('booking/add/' + date.format('MM-DD-YY')).replace();
  });

  $scope.$watch('reservation.email', function(n, o) {
    var booking = $scope.reservation;
    if(n) {
      if(!booking.photo_email || booking.photo_email === o || booking.photo_email === '') booking.photo_email = n;
      if(!booking.receipt_email || booking.receipt_email === o || booking.receipt_email === '') booking.receipt_email = n;
    }
  })

  $scope.$watch('reservation.meta', function () {
    $timeout(function () {
      $('#reservationPackage').trigger('change');
    });
  }, true);

  $scope.$watch('reservation.vip', function(){
    $timeout(function(){ $('#reservationPackage').trigger('change'); });
  });

  $scope.$watch('reservation.group', function(n, o){
    if(n !== o) {
      var standard_template = Package.templates.general;
      var group_template = Package.templates.group;
      var pickup_group_template = Package.templates.pickup_group;
      var template = group_template || standard_template;
      $timeout(function(){ $('#reservationPackage').trigger('change'); });
      if(angular.isDefined($scope.reservation.package)){
        if (n) {
          if ($scope.reservation.needs_pickup) {
            template = pickup_group_template
          }
          $scope.reservation.communication.template = template;
        } else {
          $scope.reservation.communication.template = standard_template;
        }
      }
    }

  });

  $scope.$watch('reservation.hotel', function(n, o){
    if (n && n !== o) {
      $timeout(function(){ 
        $('#reservationPackage').trigger('change');
        $scope.reservation.needs_pickup = n;
      })
    }
  })

  $scope.$watch('reservation.accommodations.hotel', function(n, o){
    if(n){
      if(!o || n._id != o._id) $scope.calcPickup(true);
      if(n._id !== undefined && o && o._id === undefined){
        $scope.reservation.accommodations.other = undefined;
      }
    }
  });

  $scope.$watch(function Package(){
    return ($scope.reservation && $scope.reservation.package) ? $rootScope.packages.filter(function(pack){
      return pack._id == $scope.reservation.package._id;
    })[0] : null;
  }, function(n){
    if(n){
      if(n.adults) $scope.reservation.children = 0;
    }
  });

  $scope.$watch('card.number', function(n, o){
    if(n) {
      if($scope.card.failed_validation) {
        delete $scope.card.errors.number;
      }
      if($scope.card.errors && $scope.saving) {
        $scope.saving = false;
        delete $scope.card.errors.number;
      }
    }
  });

  $scope.$watch('card.cvv', function(n, o){
    if(n && $scope.card.failed_validation) delete $scope.card.errors.cvv;
  });

  function createFields(i, n) {
    while(i < n){
      $scope.reservation.meta.weights.push('')
      i++;
    }
  }

  $scope.prepClone = function() {
    $scope.reservation._id = undefined;
    $scope.reservation.customerId = undefined;
    $scope.reservation.payment.cards = [];
  }

  $scope.cleanWaiver = function(waiver) {
    console.log(waiver);
    delete waiver.data.title;
    delete waiver.data.waiverId;
    delete waiver.data.templateId;
    delete waiver.data.typedSignatures;
    delete waiver.data.clientIP;
    delete waiver.data.autoTag;
  };


  //
  // REMINDER - Update these values over in dayview/messageModal.js
  // 
  $scope.smsMessages = function(){
    return [
      {
        type: 'sms',
        template: 'No Reply - {company.name} - Your flight has been cancelled due to weather. Call {company.phone} to rebook btw 9am-5pm daily.',
        name: 'Weather Cancellation'
      }, {
        type: 'sms',
        template: 'NO REPLY - {company.name} - We can’t wait to see you tomorrow morning at {pickup.time} @ {accommodations.hotel.name}. Please be ready a few minutes before your {pickup.time} pick up. If you have not already done so, be sure everyone in your party signs our Release: {waiverLink} For your convenience here is your confirmation with other important information - {confLink}. See you soon!',
        name: 'Pick-Up Reminder'
      }, { 
        type: `sms`,
        template: `No Reply - {company.name} - We can’t wait to see you tomorrow morning at the {company.description}. Please arrive a few minutes before {meetingTime} with waivers completed by everyone in your group. Our waiver can be signed here: {waiverLink}. Your confirmation with other important information can be viewed here: {confLink}. See you soon!`,
        name: `Booking Reminder`
      }, {
        type: 'sms',
        template: 'No Reply - {company.name} - Thank you for booking your hot air balloon ride with us on {day}. You have opted in to receive text message updates related to your reservation. Your confirmation number is {conf} - {confLink}. Please be sure everyone in your party signs our electronic waiver {waiverLink} before arriving on {day}. See you soon! Reply STOP to opt out.',
        name: 'Flight Confirmation'
      }, {
        type: 'sms',
        template: 'No Reply - {company.name} - Thank you for booking with us. If you have not already done so, please help us make your morning run smooth by pre-signing our Release: {waiverLink}',
        name: 'Sign Waiver'
      }, {
        type: 'sms',
        template: '',
        name: 'Custom Message'
      }
    ];
  };
  
  $scope.$watch('selected_sms', function(n, o) {
    $scope.smsMessageBody = n;
  })

  $scope.conciergeFromHotels = function() {
    var hotels = $scope.hotelsList;
    console.log(hotels)
    return 'asdfasdf';
  }

  $scope.filterHotels = function() {
    var reservation = $scope.reservation;
    var pkg = $scope.reservation.package;
    switch (true) {
      case !pkg:
      case !reservation.meta.free_pickup:
        return $scope.hotelsList;
      case reservation.meta.free_pickup:
        return $scope.hotelsList.filter(function(hotel){
          return hotel.pickup;
        })
      default:
        return [];
    }
  }

  $scope.age = function(date) {
    return $window.moment().diff($window.moment(date), 'years');
  }

  $scope.$watch(function(){
    return $scope.reservation && (parseInt($scope.reservation.adults || 0) + parseInt($scope.reservation.children || 0));
  }, function(n, o){

    var available = $scope.reservation && angular.isDefined($scope.reservation.capacity) ? $scope.reservation.capacity.remaining : null;

    if(available && available < n) {
      alert('Sorry, there were only ' + available + ' available openings for this day when you started this booking');
      $timeout(function(){
        $scope.reservation.day = "";
        $scope.showCal = true;
        $('#bookingDate').focus();
      }, 500)
    }

    $scope.reservation.meta = $scope.reservation.meta || {};
    $scope.reservation.meta.weights = $scope.reservation.meta.weights || [];
    var weights = $scope.reservation.meta.weights;

    if($scope.getPackage() && $scope.getPackage().require_weights && !$scope.reservation.meta.weights.length) {
      createFields(0, n);
    }

    validateWeights();

    // add new fields
    if(n > o){
      createFields($scope.reservation.meta.weights.length, n);
    // remove end fields
    }
  });

  function validateWeights(which){
    var weights = $scope.reservation.meta.weights;
    var guestCount = $scope.reservation.adults + ($scope.reservation.children || 0);
    var isValid = weights.length === guestCount;
    angular.forEach($scope.reservation.meta.weights, function(weight, i){
      var inputName = 'guestWeight'+i;
      var field = $scope.BookingForm[inputName];
      field && field.$setValidity('weight', isValid);
    });
  }

  $scope.$watch(function () { return $scope.reservation && $scope.reservation.meta.weights.length }, function(n, o) {
    validateWeights();
  }, true)

  $scope.removeWeight = function(i) {
    $scope.reservation.meta.weights.splice(i, 1);
  }

  $scope.removeAge = function(i) {
    $scope.reservation.meta.child_ages.splice(i, 1);
  }

  $scope.getWaiverWeight = function(waiver) {
    var weight = undefined;
    var custom = waiver.customParticipantFields;
    for (const key in custom) {
      if (Object.hasOwnProperty.call(custom, key)) {
        const element = custom[key];
        if (element.displayText === 'Guest Weight') {
          weight = element.value + 'lbs';
          break;
        }
      }
    }
    return weight;
  }

  $scope.contactModal = function (target) {

    target = target || 'hotel';

    var template;

    if(target === 'hotel') template = 'views/booking/hotelModal';
    if(target === 'concierge') template = 'views/booking/conciergeModal';

    $scope.reservation.accommodations = $scope.reservation.accommodations || { other: "" };

    var modalInstance = $modal.open({
      templateUrl: 'views/booking/contactModal',
      controller: 'contactModalCtrl',
      resolve: {
        type: function(){
          return target;
        },
        booking: function () {
          return $scope.reservation;
        },
        agents: function($q){
          var deferred = $q.defer();
          Contacts.byType('partner',
            function Success(contacts){
              var options = [{
                "name": "-- None --",
                "_id": ""
              }]
              deferred.resolve(options.concat($filter('toArray')(contacts.contacts).filter(function(contact){
                return contact.meta.agent;
              })));
            },
            function Error(err){
              console.error(err);
              deferred.reject();
            });
          return deferred.promise;
        },
        concierges: function ($q) {
          var deferred = $q.defer();
          Contacts.byType('concierge',
            function Success(contacts) {
              var options = [{
                "name": "-- None --",
                "_id": ""
              }]

              deferred.resolve(options.concat($filter('toArray')(contacts.contacts).filter(function (contact) {
                return contact.meta.type === 'concierge';
              })));
            },
            function Error(err) {
              console.error(err);
              deferred.reject();
            });
          return deferred.promise;
        },

        contact: function($q) {
          var deferred = $q.defer();
          Contacts.get('new',
            function Success(res){
              var data = res;
              data.meta = data.meta || {};
              data.meta.type = $stateParams.id;
              deferred.resolve(data);
            },
            function Error(err){
              console.error(err);
              deferred.reject();
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (obj) {

      // add hotel to list of hotels and select it
      if(obj.contact){
        var contact = obj.contact;

        if(contact.meta.type === 'hotel'){

          $scope.hotels[contact._id] = contact;
          $scope.hotelsList.push(contact);

          $timeout(function(){
            $scope.reservation.accommodations.hotel = $scope.hotelsList[$scope.hotelsList.length - 1];
          }, 500);

        }

        if(contact.meta.type === 'concierge'){
					$scope.hotels[contact.parentId].children = $scope.hotels[contact.parentId].children || {};
          $scope.hotels[contact.parentId].children[contact._id] = contact;
          $scope.reservation.accommodations.concierge = contact;
        }

      // add other to list of hotels and select it
      } else {
        if(obj.type === 'hotel'){
          $scope.hotelsList.push({ _id: undefined, name: obj.booking.accommodations.other + " <div class='extras'><span class='label lter bg-info'>other</span></div>"});
          $scope.reservation.accommodations.hotel = $scope.hotelsList[$scope.hotelsList.length - 1];
          $scope.reservation.accommodations.other = obj.booking.accommodations.other;
        }
      }
    }, function () {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  };

  var self = {

      showHistory: function(item) {
        if(item.reason !== 'open'){
          // if(item.reason === 'update' && item.diff.length === 0) return false;
          if(!item.diff.length && item.reason === 'update') return false;
          return true;
        } else {
          return false;
        }
      }

    , excludeDiffs: function(item) {

        var exclude = [
          new RegExp('^event.id'),
          new RegExp('(.*)\._id\.id')
          
        ];

        var returnable = true;

        if(item.path === 'pickup.time'){
          var old_time = moment.utc(item.old_value).format('h:mma');
          var new_time = moment.utc(item.new_value).format('h:mma');
          if(old_time === new_time) return false;
        }

        if(item.path === 'payment.cash') {
          if(item.old_value === "" && item.new_value === 0){
            return false;
          }
        }

        angular.forEach(exclude, function(reg){
          if(reg.test(item.path)) returnable = false;
        });

        return returnable;
      }

    , bookings: bookings.results || bookings

    , countPaymentTypes: function(payments){
        var total = 0;
        var details = {
          cards: {
            amount: 0,
            count: 0,
          },
          cash: {
            amount: 0,
            count: 0
          },
          gifts: {
            amount: 0,
            count: 0
          },
          billto: {
            amount: 0,
            count: 0
          }
        };
        angular.forEach(payments.cards, function(card){
          angular.forEach(card.charges, function(charge){
            if(parseFloat(charge.total) > 0){
              details.cards.amount += parseFloat(charge.total)
              details.cards.count++;
              total++;
            }
          });
        });

        angular.forEach(payments.bill_to, function(payment){
          if(parseFloat(payment.total) > 0){
            details.billto.amount += parseFloat(payment.total);
            details.billto.count++;
            total++;
          }
        });

        angular.forEach(payments.cash, function(payment){
          if(parseFloat(payment.total) > 0){
            details.cash.amount += parseFloat(payment.total);
            details.cash.count++;
            total++;
          }
        });

        angular.forEach(payments.prepayments, function(payment){
          if(parseFloat(payment.total) > 0){
            details.gifts.amount += parseFloat(payment.total);
            details.gifts.count++;
            total++;
          }
        });
        details.total = total;
        return details;
      }

    , cancelledAction: function(booking){
        var cancelled = booking.trace.filter(function(val, key){
          return val.reason == 'cancelled'
        }).sort(function(a, b){
          return a.date < b.date;
        });

        return cancelled.length > 0 ? cancelled[0] : null
      }

    , showCancelled: true

    , rebook: function(booking){
        angular.forEach($scope.bookings, function(val, i){
          val.rebook = false;
        });
        booking.rebook_day = $window.moment.utc(booking.day).format('MM/DD/YYYY');
        booking.rebook = true;
      }

    , changeDate: function($event, booking){

        if($event.type == "blur" && !$event.relatedTarget) {
          booking.rebook = false;
        } else {
        // var fire = ($event.type == "blur" && !angular.element($event.relatedTarget).hasClass('panel')) || ($event.type == "keydown" && $event.keyCode == 13);
        // if(fire){
          if($event.type == "click" || ($event.type == 'keypress' && $event.keyCode == 13)){
            setTimeout(function () {

              if(booking.rebook_day !== $window.moment.utc(booking.day).format('MM/DD/YYYY')){
                booking.working = true;
                booking.day = booking.rebook_day;
                booking.rebook = false;
                Bookings.update({ _id: booking._id, day: booking.day }, function Success(res){
                  booking.hide = true;
                  $scope.event.capacity.remaining += booking.adults + (booking.children || 0);
                  $scope.event.capacity.booked -= booking.adults + (booking.children || 0);
                  booking.working = false;
                }, function Error(err){
                  booking.working = false;
                  alert(err);
                  console.error(err)
                });

              } else {
                booking.working = false;
                booking.rebook = false;
              }

            }, 10);

          }
        // }
        }
      }

    , averageRating: function(booking){

        var cards = booking.payment.cards;
        var ratings = [];
        var charges = [];

        angular.forEach(booking.payment, function(payGroup, key) {
          if(payGroup.constructor === Array && payGroup.length) {
            if(key === 'cards') {
              angular.forEach(payGroup, function(card, i){
                charges = charges.concat(card.charges);
              });
            } else {
              // not capturing ratings on cash yet
              if(key !== 'cash'){
                charges = charges.concat(payGroup);
              }
            }
          }
        });


        ratings = ratings.concat(charges.map(function(charge){
          if(charge.rating != 0) return charge.rating;
        })).filter(function(rating){
          return rating !== undefined;
        });

        return (ratings.length > 0) ? ratings.reduce(function(prev, now){
          return (prev || 0) + now;
        }, 0) / ratings.length : 0;

      }

    , totals : function(bookings){

        console.log('erm ...')

        var packages = {};
        var payment = { 'Credit Card' : 0, 'Cash': 0, 'Tips': 0 };
        var paymentTotal = 0;
        var items = {};
        var grandTotal = 0;
        var countTotal = 0;

        angular.forEach(bookings, function(booking){

          if(booking.status == 'active'){

            booking.pricing = new Pricing(booking);

            var subtotal = 0;
            grandTotal += booking.pricing.package_cost || 0;

            angular.forEach(booking.pricing.items, function(item){
              if(angular.isDefined(items[item.product.name])){
                items[item.product.name].count += item.count;
                items[item.product.name].cost += item.total || 0;
              } else {
                items[item.product.name] = {
                  count: item.count,
                  cost: item.total || 0
                }
              }
              countTotal += item.count;
              grandTotal += item.total;
            })

            // Package Calc
            if(angular.isDefined(packages[booking.package.name])){
              packages[booking.package.name].count += (booking.adults + booking.children || 0);
              packages[booking.package.name].cost += booking.pricing.package_cost || 0;
            } else {
              if(!booking.vip){
                packages[booking.package.name] = {
                  count: (booking.adults + booking.children || 0),
                  cost: booking.pricing.package_cost || 0
                }
              } else {
                packages['VIP'] = {
                  count: (booking.adults + booking.children || 0),
                  cost: booking.pricing.package_cost || 0
                }
              }
            }

            // Package Inc
            countTotal += (booking.adults + booking.children || 0)

            angular.forEach(booking.pricing.charges, function(charge){
              paymentTotal += (charge.amount - (charge.tip || 0));
              payment['Credit Card'] += (charge.amount - (charge.tip || 0));
              payment.Tips += (charge.tip || 0);
            });

            if(booking.pricing.other_payments.cash.length > 0){
              var cash = booking.pricing.other_payments.cash.reduce(function(prev, cur){
                return (prev + cur)
              });
              paymentTotal += cash;
              payment['Cash'] += cash;
            }

            angular.forEach(booking.pricing.other_payments.other, function(billTo){
              payment[billTo.type] = payment[billTo.type] || 0;
              payment[billTo.type] += billTo.amount;
              paymentTotal += billTo.amount;
            });

            angular.forEach(booking.pricing.other_payments.gifts, function(gift){
              payment['Gift'] = payment['Gift'] || 0;
              payment['Gift'] += gift.amount;
              paymentTotal += gift.amount;
            });

          }

        })

        return {
          packages: packages,
          items: items,
          count: countTotal,
          total: grandTotal,
          payments: payment,
          paymentTotal: paymentTotal
        }
      }

    , dateDiff: function(a, b){
        b = b || new Date();
        return moment(a).diff(b, 'days');
      }

    , resendConf: function(message, text){
        message.working = true;
        var newMessage = typeof message.newTemplate !== 'undefined' && message.template != message.newTemplate;
        var payload = { id: message._id, text: text || false };
        if(newMessage) payload['template'] = message.newTemplate
        Messages.resend(payload, function Success(res){
          message.working = false;
          res.user = $rootScope.user;
          $scope.reservation.messages.push(res);
        }, function Error(err){
          message.working = false;
          console.error(err);
        })
      }

    , sendSms: function(booking, message) {
        $scope.sms_working = true;
        Messages.sms({ id: booking._id, body: message }, function Success(res) {
          $scope.sms_working = false;
          $scope.smsMessageBody = '';
        }, function Error(err) {
          $scope.sms_working = false;
        })
      }

    , sendReceipt: function(booking){
        booking.sending_receipt = true;
        Bookings.sendReceipt(booking, function Success(res){
          booking.sent_receipt = true;
          booking.sending_receipt = false;
        }, function Failure(err){
          booking.sending_receipt = false;
        });
      }

    , templateIsUpdated: function(){
        var updated = false;
        setInterval(function(){
          updated = $('#newTemplate').hasClass('isModified');
        }, 500);
        return updated;
      }

    , sendConf: function(booking){
        var payload = { id: booking._id, template: booking.communication.template || null };
        booking.working = true;
        Bookings.sendConfirmation(payload, function Success(res){
          booking.working = false;
          res.user = $rootScope.user;
          $scope.reservation.messages.push(res);
        }, function Error(err){
          booking.working = false;
          console.error(err);
        })
      }

    , buildTip: function(booking){

        if(!booking) return;

        function Header(text){
          return html + "<h6 style='margin-bottom: 0;'>"+ text +"</h6>";
        }

        function Paragraph(text){
          return html + "<p>" + text + "</p>";
        }

        var html = "";
        if(booking.notes){
          if(booking.notes.instructions){
            html = Header('Special Instructions');
            html = Paragraph(booking.notes.instructions);
          }
          if(booking.notes.internal){
            html = Header('Internal Notes');
            html = Paragraph(booking.notes.internal);
          }
          if(booking.notes.memo){
            html = Header('Memo');
            html = Paragraph(booking.notes.memo);
          }
          if(booking.notes.memo){
            html = Header('Occasion');
            html = Paragraph(booking.notes.occasion);
          }
        }

        if(booking.pickup){
          if(booking.pickup.notes){
            html = Header('Notes for Driver');
            html = Paragraph(booking.pickup.notes);
          }
        }

        if(!booking.notes && !booking.pickup){
          html = false;
        }

        return html ? "<div style='text-align: left;'>" + html + "</div>" : html;
      }

    , tester: function(booking){
        var body = "";
        if(angular.isDefined(booking.notes)) {
          var instructionsHead = angular.copy(header);
          body += '<h5>Special Instructions</h5>';
        }
        return body;
      }

    , pickups: null

    , getPickups: function(){

        $scope.pickups = 'active';

        Pickups.forDay(moment($scope.reservation.day).format("MM-DD-YYYY"), true, function(response){
          $scope.pickups = response.bookings;
        });

      }

    , getPackage: function(){

        function lookup(id){
          return $rootScope.packages.filter(function(pack){
            return pack._id == id;
          })[0];
        }

        var pack = (angular.isDefined($scope.reservation._package)) ? lookup($scope.reservation._package) : null;

        if($scope.reservation.package
          && $scope.reservation._package === $scope.reservation.package._id) {
          var rawPackage = lookup(pack._id);
          pack = $scope.reservation.package;
          pack.templates = rawPackage.templates;
          pack.require_weights = rawPackage.require_weights;
        }
        return pack;
      }

    , selectFormat: function(state){
        var modifiers = $('<span/>', { class: 'pull-right extras' })
        , label     = $('<span />', { class: 'label lter' })
        , vipMod    = label.clone().addClass('bg-success').text('VIP')
        , hotelMod  = label.clone().addClass('bg-warning').text('Hotel')
        , groupMod  = label.clone().addClass('bg-info').text('Group')
        , brunchMod = label.clone().addClass('bg-info').text('No Brunch')
        , freepickupMod = label.clone().addClass('label-default').text('Free Pickup')
        , booking   = $scope.reservation;
        
        booking.meta = booking.meta || {};
        if(booking.vip)   modifiers.append(vipMod);
        if(booking.group) modifiers.append(groupMod)
        if(booking.hotel) modifiers.append(hotelMod);
        if(booking.meta.free_pickup) modifiers.append(freepickupMod);
        if(booking.meta.no_brunch) modifiers.append(brunchMod);

        return state.text + modifiers[0].outerHTML;
      }

    , otherLocation: function(state){

        var modifiers = $('<span/>', { class: 'pull-right extras' })
          , label     = $('<span />', { class: 'label lter' })
          , isOther  = label.clone().addClass('bg-info').text('Other Location')
          , booking   = $scope.reservation;

        booking.accommodations = booking.accommodations || {};

        if((!booking.accommodations.hotel || booking.accommodations.hotel._id === '') && booking.accommodations.other && booking.accommodations.other !== '') {
          modifiers.append(isOther);
        }

        return state.text + modifiers[0].outerHTML;

      }

    , cancelAll: function(day){

        var buttons = {
          success: {
            label: "Proceed",
            className: "btn-primary",
            callback: function(){

              Bookings.cancelAll({
                day: $filter('readTime')(day, 'MM-DD-YYYY'),
                cancellation: $scope.cancellationReason
              },
                function Success(res){
                  angular.forEach($scope.bookings.results, function(booking, index){
                    booking.status = 'cancelled';
                  });
                }, function Error(err){
                  alert(err);
                }
              );

            }
          },
          cancel: {
            label: "Nevermind",
            className: "btn-link",
            callback: function() {
              $scope._booking = null;
              $scope.cancellationReason = null;
            }
          }
        };

        bootbox.dialog({
          title: "Do you really want to cancel all flights for " + day + "?",
          message: $compile($templateCache.get('views/bootbox/cancel'))($scope),
          buttons: buttons
        });

      }

    , import: function(day){
        if(day === 'tomorrow') day = moment.utc().add(1, 'day').hours(0).format('MM-DD-YY')
        Legacy.importDay(day,
          function Success(res){
            console.info(res);
            location.reload();
          },
          function Error(err){}
        )
      }

    , cleanup: function(day){
        if (day === 'tomorrow') day = moment.utc().add(1, 'day').hours(0).format('MM-DD-YY')
        Bookings.cleanupCache({day: day},
          function Success(res){
            console.info(res);
            location.reload();
          },
          function Error(err){}
        )
      }


    // , total: Pricing.Balance()

    , setDate: function(day){
        $scope.reservation.day = moment.utc(day).hours(0).format('MM/DD/YYYY');
        $scope.showCal = false;
      }

    , reservation: (angular.isDefined(bookings) && angular.isDefined(bookings._id)) ? bookings : angular.extend({
        day: ($scope.reservation && $scope.reservation.day) || ($scope.date && $scope.date.format('MM/DD/YYYY')),
        payment: angular.extend({
          cards: []
        }, ($scope.reservation || {}).payment)
      }, {})

    , dateUri: function(){
        return moment.utc($scope.reservation.day, 'MM/DD/YYYY').hours(0).format('MM-DD-YY')
      }

    , tmpFiles: []

    , initiateUpload: function(){
        $('#Upload').trigger('click');
      }

    , selectFiles: function($files){
        $files.forEach(function(file, index){
          $scope.upload = $upload.upload({
            url: $scope.domain + "/booking/upload",
            // data: { myObj: $scope.myModelObj },
            file: file
          }).progress(function(evt) {

          }).success(function(data, status, headers, config) {
            // file is uploaded successfully
            $timeout(function(){
              $scope.tmpFiles.push({
                tmpPath: data.path,
                filename: data.name,
                name: data.name
              });
            })
          });
        });
      }

    , card: {

        add: function(obj){

          var guest = $scope.reservation;

          var guestCard = new GuestCard(obj, {
            name: guest.first_name + " " + guest.last_name,
            phone: guest.phone,
            email: guest.email
          });

          $scope.reservation.pricing.addCard(guestCard);
          $scope.reservation.payment.cards.push(guestCard);

          if($scope.reservation._id){
            $scope.submit(false);
          }

        },

        /**
         * Basically these fields must be filled before they can enter a card
         * @return {[type]} [description]
         */
				readyForEntry: function(){
					var res = $scope.BookingForm;
          if(res){
            return (res._package.$valid && res.first_name.$valid && res.last_name.$valid && res.adults.$valid && res.phone.$valid && res.email.$valid) || false;
          }
				},

        verify: function(){

          $scope.saving = true;

          var booking = $scope.reservation;

          var expiration = $scope.card.expiration
            , card_obj = {
                number: $scope.card.number,
                cvc: $scope.card.cvv,
              };

          card_obj.exp_month = expiration.substring(0, 2);
          card_obj.exp_year = "20" + expiration.substring(2);

          var creditCard = new StripeCard(card_obj);

					if(Object.keys(creditCard.errors).length){
						$scope.card.errors = creditCard.errors;
					} else {
						creditCard.Create(function Success(res){

              function saveState(){
                var isNew = !$scope.reservation._id;
                $scope.submit(false, $scope.reservation._id ? $scope.reservation.status : 'pending', function(res){
                  if(res._id){
                    $scope.reservation._id = res._id;
                    $scope.reservation.confirmation = res.confirmation;
                    $scope.reservation.status = !isNew ? res.status : undefined;
                    $scope.saving = false;
                  }
                });
              }

							$timeout(function(){
								$scope.card.failed_validation = false;

								function addCard(id){
									self.card.add({
										id: id,
										card: creditCard,
										masked: creditCard.Number().slice(-4),
										type: creditCard.type()
									});
								}

								if($scope.reservation.payment.cards.length == 0){
                  var userCompany = $scope.user.activeCompany();
                  var meta = {
                    agent: $scope.user._id,
                    agent_name: $scope.user.name,
                    company: userCompany._id,
                    company_name: userCompany.name
                  };
                  if(booking.group){
                    meta.guest_group_name = booking.group_name;
                  } else {
                    meta.guest_name = booking.first_name + " " + booking.last_name;
                    meta.guest_booking_date = booking.day;
                    meta.guest_phone = booking.phone;
                    meta.guest_adults = booking.adults;
                    meta.guest_children = booking.children || 0;
                  }

                  if(booking.confirmation){
                    meta.confirmation = userCompany.nickname + booking.confirmation;
                  }

                  var concierge = $scope.reservation.accommodations && $scope.reservation.accommodations.concierge;

                  $scope.reservation.email = !$scope.reservation.email && (concierge && concierge.meta.confirmation) ? concierge.email : $scope.reservation.email;

									Customers.create({
										source: res.id,
										email: $scope.reservation.email,
                    metadata: meta
									}, function Success(res){
                    $scope.cardError = false;
                    $scope.saving = false;
										$scope.reservation.customerId = res.id;
										addCard(res.default_source);
										saveState();
									}, function Error(err){
										alert(JSON.stringify(err.message || err, null, 2));
                    $scope.saving = false;
                    $scope.cardError = true;
									})

								} else {
									Cards.assign({
										customerId: $scope.reservation.customerId,
										source: res.id
									}, function Success(res){
                    $scope.cardError = false;
                    $scope.saving = false;
										addCard(res.id);
										saveState();
									}, function Error(err){
										console.info(err);
										alert(err.error || err)
                    $scope.cardError = true;
                    $scope.saving = false;
									})
								}

								angular.extend($scope.card, {
									number: null,
									expiration: null,
									cvv: null,
									billing: null,
									zip: null
								});

							})

						},
						function Error(err){
							console.error(err);
							$scope.card.failed_validation = true;
							$scope.card.errors = err;
              $scope.cardError = true;
              $scope.saving = false;
						}
					);
					}


        }

      }

    , validate: {

        expiration: function(val){
          var thisMonth, thisYear;
          if(val){
            var split = val.split('/');
            thisMonth = split[0];
            if(split[1]) thisYear = split[1].replace('_', '');
            if(thisYear && thisYear.length == 2) thisYear = "20" + thisYear;
            if(thisMonth > 12) return false;
            if(thisYear && thisYear < year) return false;
          }
          return true
        }

      , checkEmail: function(val, allow_empty){
          var concierge, travel_agent;
          travel_agent = $scope.reservation.agent;
          concierge = $scope.reservation.accommodations ? $scope.reservation.accommodations.concierge : false;
          if(val){

            var mails, concierge, passes;
            mails = val.split(',');
            passes = true;

            angular.forEach(mails, function(mail){
              var email = mail.trim();
              if(!emailReg.test(email)) passes = false;
            });

            return passes;
          } else {
            if(concierge && concierge.meta.confirmation || travel_agent && travel_agent.meta.confirmation) {
              return true;
            }
          }
        }

      }

    , submit: function(redir, status, cb){

        $scope.saving = true;

        redir = angular.isDefined(redir) ? redir : true;

        if(typeof status == 'function'){
          cb = status;
          status = undefined;
        }

        var reservation = $scope.reservation
          , id = reservation._id || null;

        var data = angular.copy($scope.reservation);

        if(status && (status == 'pending' || status == 'active')) data.status = status;

        data.meta = {};

        // I hate this, but early on I passed these values to a 'meta' key
        // Then I ended up making 'meta' a thing on a booking
        // so in order to not save these values to the booking I'm ignoring them before save ...
        // TODO: move non booking values out of the booking 'meta' field.
        var ignore_meta = [
          'balloons', 'capacity', 'drivers', 'hotels', 'meetingTime', 'packages', 'products', 'vip_pricing',
        ];

        angular.forEach($scope.reservation.meta, function(val, key){
          if(ignore_meta.indexOf(key) === -1) {
            data.meta[key] = val;
          }
        });

        if(data.meta.weights.length){
          // var metaWeights = data.meta.weights;
          // data.meta.weights = metaWeights;
        } else {
          delete data.meta.weights;
        }

        data.tmpFiles = $scope.tmpFiles;

        data.package = Package;
        $scope.reservation.pricing = $scope.reservation.pricing || {};
        data.payment.discount = $scope.reservation.pricing.discount_value;
        data.payment.discount_name = $scope.reservation.pricing.discount_name;

        if(data.accommodations){
          if(data.accommodations.hotel && data.accommodations.hotel._id == "") {
            delete data.accommodations.hotel;
          } else {
            if(data.accommodations.hotel) data.accommodations.hotel = data.accommodations.hotel._id;
          }
          if(data.accommodations.concierge == "") delete data.accommodations.concierge;
        }

        $scope.reservation.pricing.other_payments.other.forEach(function(val, index){
          $scope.reservation.pricing.other_payments.other[index].amount = parseFloat(val.amount)
        })

        $scope.reservation.pricing.other_payments.cash.forEach(function(val, index){
          $scope.reservation.pricing.other_payments.cash[index] = parseInt(val);
        })

        var gifts = [];
        $scope.reservation.pricing.other_payments.gifts.forEach(function(gift, index){
          gifts.push(gift._id);
        })

        data.line_items = $scope.reservation.pricing.items;

        data.payment.cash = $scope.reservation.pricing.other_payments.cash;
        data.payment.other = $scope.reservation.pricing.other_payments.other;
        data.payment.gifts = gifts;

        if(angular.isDefined(data.payment.cards) && data.payment.cards.length > 0){
          data.payment.cards.forEach(function(card, index){
            delete card.card;
          });
        }

        if(data.waiting) {
          data.status = 'waiting';
        } else {
          var reset_status = status === 'waiting' ? 'active' : status;
          data.status = reset_status || 'active';
        }

        var success = function(res){
          if(redir) {
            if(status == 'pending'){
              $state.go('notifications');
            } else {
              $state.go('day.bookings', { day: moment(data.day).utc().format('MM-DD-YY') });
            }
            $scope.saving = false;
          } else {
						$scope.saving = false;
            if(cb){
              cb(res);
            }
          }
        };

        var error = function(err){
          $scope.saving = false;
          var message = angular.isDefined(err.message) ? err.message : err;
          console.error(message);
          alert(message);
        };

        data.day = moment(data.day).hours(0).utc().format();

        if(!id) {
          Bookings.create(data, success, error);
        } else {
          Bookings.update(data, success, error);
        }

      }

    , activate: function(booking){
        Bookings.activate(booking._id, function Success(res){
          booking.status = 'active';
        }, function Error(err){})
      }

    , unread: function(booking){
        Bookings.markUnread(booking._id, function Success(res){
          booking.trace = res.trace;
          booking.unread = true;
        }, function Error(err){})
      }

    , cancel: function(booking, redir){

        redir = redir !== undefined ? redir : false;

        var payments = booking.payment;

        var guestTotal = booking.adults + (booking.children || 0)

        $scope._booking = booking;

        var buttons = {
          success: {
            label: "Proceed",
            className: "btn-primary",
            callback: function(){

              var FORM = $scope.BookingForm;
              // $scope.reservation = booking;
              
              booking.cancellation = $scope.cancellationReason;
              
              function saveBooking(cb){
                $scope.submit(false, function(err, res) {
                  cb();
                })
              }

              function cancelBooking() {
                Bookings.cancel(booking,
                  function Success(res) {
                    $scope.cancellationReason = null;
                    $scope._booking = null;
                    booking.status = 'cancelled';
                    if ($scope.event && angular.isDefined($scope.event.capacity)) {
                      $scope.event.capacity.booked -= guestTotal;
                      $scope.event.capacity.remaining += guestTotal;
                    }
                    if (redir) {
                      $state.go('day.bookings', {
                        day: moment(booking.day).utc().format('MM-DD-YY')
                      });
                    }
                  },
                  function Error(err) {
                    $scope.cancellationReason = null;
                    $scope._booking = null;
                  }
                );
              }

              // do the thing
              if (FORM !== undefined && FORM.$dirty) {
                saveBooking(cancelBooking);
              } else {
                cancelBooking()
              }

            }
          }
        };

        if(booking.payment.cardCharges && booking.payment.cardCharges > 0){

          buttons.refund = {
            label: "Refund",
            className: "btn-danger",
            callback: function() {

              $timeout(function(){

                booking.cancellation = $scope.cancellationReason;
                booking.refund = 'all';

                Bookings.cancel(booking,
                  function Success(res){
                    $scope.event.capacity.booked -= guestTotal;
                    $scope.event.capacity.remaining += guestTotal;
                    $scope.cancellationReason = null;
                    $scope._booking = null;
                    booking.status = 'cancelled';
                  }, function Error(err){
                    $scope._booking = null;
                    $scope.cancellationReason = null;
                  }
                );

              });

            }
          };

        }

        buttons.cancel = {
          label: "Nevermind",
          className: "btn-link",
          callback: function() {
            $scope._booking = null;
            $scope.cancellationReason = null;
          }
        };

        bootbox.dialog({
          title: "Do you really want to cancel NV" + booking.confirmation + "?",
          message: $compile($templateCache.get('views/bootbox/cancel'))($scope),
          buttons: buttons
        });

      }

    , getDefaultCancellation: function(){
        return $scope.user.activeCompany().cancellations.filter(function(can){
          return can.default;
        })[0];
      }

    , getConciergeList: function(hotel){
        $scope.hotels = $scope.hotels || {};
        if(hotel && typeof $scope.hotels[$scope.reservation.accommodations.hotel._id] !== 'undefined'){
          var hotel = $scope.hotels[$scope.reservation.accommodations.hotel._id];
          return $filter('toArray')(hotel.children);
        }

      }

    , calcPickup: function(force){

        force = force !== undefined ? force : false;

        if($scope.reservation.needs_pickup){

          $scope.reservation.pickup = $scope.reservation.pickup || {};
          var splitTime = $scope.meetingTime.split(':');

          var hotel = $scope.getHotel();

          if(typeof hotel !== 'undefined' && hotel && hotel.zone){
            $scope.reservation.pickup = $scope.reservation.pickup || {};
            var minus = hotel.zone.time
              , hour = parseInt(splitTime[0])
              , minute = parseInt(splitTime[1].replace('am', ''));

            var updated = $window.moment.utc().hours(hour).minutes(minute).add({'minutes': -minus}).format('hh:mmA');

            $scope.reservation.pickup.time = force ? updated : $scope.reservation.pickup.time ||  updated;

          } else {
            $scope.reservation.pickup.time = $scope.meetingTime;
          }

        }
      }

    , getHotel: function(){
        $scope.hotels = $scope.hotels || {};
        var accommodations, hotelAssigned;
        accommodations = $scope.reservation.accommodations;
        hotelAssigned = accommodations !== undefined && accommodations.hotel !== undefined;
        if(hotelAssigned){
          var hotel = $scope.reservation.accommodations.hotel ? $scope.hotels[$scope.reservation.accommodations.hotel._id] : null;
          // $scope.calcPickup();
          return hotel;
        }

        return null

      }

    , calendar: {
          today: $window.moment()
        , min: this.today
      }

    , toggles: {

          pricing: false
        , emailTemplate: false
        , attachments: false
        , emailHistory: false
        , reservationHistory: false

      }

    , setEmailTemplate: function(message){
        if (message.type === 'email') {
          $scope.activeMessage = null;
          message.newTemplate = angular.copy(message.template);
          $scope.activeMessage = message;
        }
      }

    , editCapacity: false

    , updateCapacity: function(event, save){

        if(!save){

          event.capacity.remaining = event.capacity.total - event.capacity.booked;

        } else {

          Events.update({ _id: event._id, capacity: event.capacity.total },
            function Success(res){
              $scope.editCapacity = false;
            },
            function Error(err){

            }
          );

        }
      }


  };

  angular.extend($scope, self);

  function dirtyCheck(){

    var stateName = $rootScope.state.name;
    var isDirty = false;

    angular.forEach($scope.BookingForm, function(value, key) {
      if(key[0] == '$') return;
      if(value.$dirty) {
        isDirty = true;
      }
    });

    if(isDirty && !$scope.saving && (stateName == 'editBooking' || stateName == 'booking')){
      return true
    }
  }

  $window.addEventListener("beforeunload", function(e){
    if($stateParams.confirmation) {
      Socket.emit('leftBooking', { confirmation: $stateParams.confirmation.replace(/^[a-z]+/i, '') })
    }
    // Edit form dirty?
    if(dirtyCheck()){
      var confirmationMessage = "It appears as though you have unsaved data, continue?";
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome etc.
    }
  });

  $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams){
    if(fromParams.confirmation) {
      Socket.emit('leftBooking', { confirmation: fromParams.confirmation.replace(/^[a-z]+/i, '') })
    }
    // Edit form dirty?
    if(!$scope.saving && angular.isDefined($scope.BookingForm) && dirtyCheck()){
      var confirm = $window.confirm("It appears as though you have unsaved data, continue?");
      if(!confirm) e.preventDefault();
    }
  });

});
