'use strict';

var throttle = _.throttle;
var updateThrottle = throttle(function(cb){
  return cb()
}, 500, { trailing: false });

var dontSkipDateWatch = true;

var ReportsCtrl = angular.module('RessieApp')

.controller('reportsCtrl', function ($scope, $stateParams, $rootScope, $state, allowed, Events, Pickups, Pricing, LineItem, Reports, $location, $timeout, ipCookie, Bookings) {

  var dateCookie = ipCookie('reportDateFilter');

  if(!allowed) $state.go('login');

  var day = $rootScope.state.report !== 'sales' && (dateCookie && dateCookie.start) ? moment.utc(dateCookie.start).hours(0) : $stateParams.start === "" ? moment.utc(new Date()).hours(0) : moment.utc($stateParams.start, 'MM-DD-YY').hours(0);

  if($stateParams.start === "" && moment().hours() > 9){
    day.add(1, 'days');
  }

  var end = $rootScope.state.report !== 'sales' && (dateCookie && dateCookie.end) ? moment.utc(dateCookie.end).hours(0) : $stateParams.end === "" ? day.clone() : moment.utc($stateParams.end, 'MM-DD-YY').hours(0);

  function GetEvent(active, cb){
    $scope.working = true;
    Events.get(day.format('MM-DD-YYYY'), function(response){
      $scope.working = false;
      $scope.active = active;
      cb(response);
    });
  };

  $scope.filter = ipCookie('reportFilter') || {
    'ar': {
      'paid': 'all'
    }
  };

  $scope.dateFilter = ipCookie('reportDateFilter') || {
    'start': day,
    'end': end
  }

  $scope.setFilter = function(value, filter){
    var reportCookie = ipCookie('reportFilter') || {};
    reportCookie[$scope.active] = reportCookie[$scope.active] || {};
    reportCookie[$scope.active][filter] = value;
    $scope.filter = reportCookie;
    ipCookie('reportFilter', reportCookie, { path: '/' });
  }

  $scope.reports = [
    'itSheet', 'pickupSheet', 'salesSlip', 'photos', 'glance', 'sales', 'batch', 'billTo', 'tips', 'commissions', 'merchandise'
  ];

  var reload = function(start, finish){
    $timeout(function(){
      switch($scope.active){
        case 'itSheet':
          $scope.itSheet();
          break;
        case 'pickupSheet':
          $scope.pickupSheet();
          break;
        case 'salesSlip':
          $scope.salesSlip();
          break;
        case 'photos':
          $scope.photos();
          break;
        case 'glance':
          $scope.getEvents();
          break;
        case 'billTo':
          $scope.getBillTo();
          break;
        case 'sales':
          $scope.getSalesAgain();
          break;
        case 'batch':
          $scope.getBatch(start, finish);
          break;
        case 'tips':
          $scope.getTips();
          break;
        case 'commissions':
          $scope.getCommissions();
          break;
        case 'merchandise':
          $scope.merchandise();
          break;
      }
    });
  };

  // Date selection for report
  // rerenders data
  $scope.$watch('datePicker', function(n, o){
    var _day = o.date.startDate;
    var _end = o.date.endDate;
    day = n.date.startDate;
    end = n.date.endDate;

    var dayDiff = day.format('MM/DD/YYYY') !== _day.format('MM/DD/YYYY');
    var endDiff = end.format('MM/DD/YYYY') !== _end.format('MM/DD/YYYY');
    var newDate = Object.keys($scope.previousState).length  && ($scope.previousState.start !== $stateParams.start || $scope.previousState.end !== $stateParams.end);
    // update the report if days aren't the same
    if((dayDiff || endDiff) && dontSkipDateWatch){

      ipCookie('reportDateFilter', {
        'start': day,
        'end': moment.utc(end).subtract(1, 'day')
      }, {
        path: '/reports',
        expirationUnit: 'minutes',
        expires: 1
      });

      reload(day, end);
    }
    dontSkipDateWatch = true;
  }, true)

  $scope.$watch('active', function(n, o){
    var newReport = $scope.previousState.report !== $stateParams.report;
    var newDate = $scope.previousState.start !== $stateParams.start || $scope.previousState.end !== $stateParams.end;
    if(newReport){
      reload(day, end);
    }
  });

  var pickerOptions = {
    showDropdowns: true,
    locale: {
      format: 'MM/DD/YYYY',
      separator: ' thru '
    },
    singleDatePicker: $scope.range,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  };

  $scope.$watch('range', function(n, o){
    $scope.datePicker.options.singleDatePicker = !n;
  });

  var cashMoneyCheck = {};

  var self = {

    goToDate: function(start, end){

      start = moment.utc(start)
      var cookie = {
        start: start,
      }
      var newLocation = [
        location.protocol + '/',
        location.host,
        'reports',
        $state.current.report,
        start.format('MM-DD-YY')
      ]
      if(end) {
        end = moment.utc(end);
        cookie.end = end;
        newLocation.push(end.format('MM-DD-YY'));
      }

      ipCookie('reportDateFilter', cookie, {
        path: '/reports',
        expirationUnit: 'minutes',
        expires: 1
      });

      newLocation = newLocation.join('/');
      window.location = newLocation;
    },

    datePicker: {
      date: {
        startDate: day.clone(),
        endDate: end.clone()
      },
      options: pickerOptions
    },

    isReport: function(){
      return _.indexOf($scope.reports, $stateParams.report) !== -1
    },

    lastObject: function(obj){
      var arr = [];
      angular.forEach(obj, function(val, key){
        arr.push(key);
      });
      return parseFloat(obj[arr[arr.length - 1]]) || 0;
    },

    countRows: function(obj){
      var count = 1;
      var pricing = obj.pricing;
      if(pricing.discount_value !== "0") count+=1;
      if(pricing.other_payments.cash.length > 0) count+=1;
      count += pricing.items.length;
      count += pricing.other_payments.gifts.length;
      count += pricing.other_payments.other.length;
      count += pricing.charges.length;
      return count;
    },

    blanks: function(rows, wanted){
      var blanks = [];
      while(blanks.length < (wanted - rows)){
        blanks.push(blanks.length)
      }
      return blanks;
    },

    prepPrice: function(booking){
      booking.pricing = new Pricing(booking);
      return booking;
    },

    runningTotal: function(prev, current, pack){
      var amount = (current.value * current.count);
      if(angular.isUndefined(prev)){
        current.running = amount + pack;
      } else {
        current.running = prev.running + amount;
      }
      return current.running;
    },

    active: $stateParams.report,

    working: false,

    day: day.format('MM/DD/YYYY'),

    end: end.format('MM/DD/YYYY'),

    increaseDay: function(){
      $scope.daySelect = end.add(1, 'day');
      $scope.daySelectEnd = $scope.daySelect;
    },

    decreaseDay: function(){
      $scope.daySelect = day.subtract(1, 'day');
      $scope.daySelectEnd = $scope.daySelect;
    },

    data: null,

    getTotal: function(arr) {
      arr = arr || [];
      var mapped = arr.map(function(val){
        return val.amount || val
      });

      if(mapped.length > 0) {
        return mapped.reduce(function(prev, curr){
          return prev + curr;
        });
      } else {
        return 0;
      }
    },

    totalGrouping: function(group){

      var total = [0]

      angular.forEach(group, function(item_array, name){
        total = total.concat(item_array);
      });

      return total.reduce(function(prev, curr){
        return prev + curr;
      });
    },

    totalTips: function(pilot) {

      var tips = [0]

      angular.forEach(pilot, function(tips_array, name){
        tips = tips.concat(tips_array);
      });

      return tips.reduce(function(prev, curr){
        return prev + curr;
      });

    },

    totalRetail: function(items){
      var total = [0]

      angular.forEach(items, function(item_array, name){
        total = total.concat(item_array);
      });

      return total.reduce(function(prev, curr){
        return prev + curr;
      });

    },

    totalBillTo: function(obj){
      var total = [];
      angular.forEach(obj, function(amount, name){
        total = total.concat(amount);
      });
      if(total.length > 0) {
        return total.reduce(function(prev, curr){
          return prev + curr;
        });
      } else {
        return 0;
      }
    },

    updateBillTo: function(item){
      Bookings.updateBillTo(item, function Win(res){

      })

    },

		salesTrack: [],

    updateLocation: function(path, start, finish, extra){
      start = start ? start : day.clone().format('MM-DD-YY');
      path += '/' + start;
      if(($scope.range && finish) && finish !== start) {
        finish = finish ? finish : end.clone().format('MM-DD-YY');
        path += '/' + finish;
      }
      return $location.path('/reports/'+ path, false);
    },

    merchandise: function(){
      $scope.range = true;
      $scope.title = "Merchandise";
      $scope.working = true;

      Reports.sales(
        day.clone().format('MM-DD-YY'),
        end.clone().format('MM-DD-YY'),
        function Win(res){
          var data = angular.copy(res);
          $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
          $scope.days = angular.copy($scope.data);
          $scope.Reservations = $scope.data.Reservation;
          $scope.Prepayments = $scope.data.Prepayment;
          delete $scope.days.Prepayment;
          delete $scope.days.Reservation;
          delete $scope.days.batches;
          $scope.working = false;
        }
      );
    },

    updateBatchTable: function(res){
      $scope.batches.push(res);
    },

    getBatch: throttle(function(start, endDate){
      var startDate;
      var startFormatted = start.format('MM-DD-YY');
      var endFormatted = end.format('MM-DD-YY');
      var todayFormatted = moment().format('MM-DD-YY');
      if($stateParams.end === '' && startFormatted == todayFormatted, endFormatted == todayFormatted) {
        endDate = moment.utc();
        startDate = undefined;
      } else {
        startDate = start || day;
        endDate = end;
      }

      $scope.range = true;
      $scope.locked = true;
      $scope.working = true;

      Reports.sales(
        startDate ? startDate.clone().format('MM-DD-YY') : undefined,
        endDate.clone().format('MM-DD-YY'),
        function Win(res){

          var data = angular.copy(res);

          $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
          $scope.batches = $scope.data.batches;
          $scope.locked = $scope.data.locked;
          delete $scope.data.batches;
          delete $scope.data.locked;

          if(!startDate) {
            var firstDay = Object.keys(res).filter(function(key){
              return /^\d{1,2}\//.test(key);
            }).sort(-1)[0];

            if(!firstDay){
              firstDay = moment.utc($scope.batches[0].end).add(1, 'day').format('MM/DD/YYYY')
            }

            dontSkipDateWatch = false;
            day = moment.utc(firstDay, 'MM/DD/YYYY')
            end = endDate.clone();
            $scope.datePicker.date = {
                startDate: day,
                endDate: end
            }
          }


          if($scope.data.Reservation !== undefined){
            $scope.data.is_batch = false;
            $scope.days = angular.copy($scope.data);
            $scope.Prepayment = $scope.data.Prepayment;
            $scope.Reservation = $scope.data.Reservation;
            delete $scope.days.Prepayment;
            delete $scope.days.Prepayment;
          } else {
            $scope.data.is_batch = true;
          }
          $scope.title = "Batch Report";
          $scope.working = false;

        },
        function Err(err){
          alert(err.message);
        },
        true
      );

    }),

    getPeriod: function(guest, start, end){
      var zeroed, sameAsStart, sameAsEnd;
      if(guest){
        zeroed = moment.utc(guest.date || guest.day || guest.created).hours(0).minutes(0).seconds(0);
        sameAsStart = zeroed.format('MM/DD/YY') === start.format('MM/DD/YY');
        sameAsEnd = zeroed.format('MM/DD/YY') === end.format('MM/DD/YY');
        return {
          isBefore: !sameAsStart && zeroed.isBefore(start),
          isAfter: !sameAsEnd && zeroed.isAfter(end),
          isInRange: (!zeroed.isBefore(start) && !zeroed.isAfter(end)) || sameAsStart || sameAsEnd,
          date: zeroed,
          start: start,
          end: end
        }
      }
    },

    getBillTo: function(){
      $scope.range = true;
      $scope.working = true;
      var due = [];
      var payments;
      Reports.sales(
        day.clone().format('MM-DD-YY'),
        end.clone().format('MM-DD-YY'),
        function Win(res){
          $scope.active = 'billTo';
          var data = angular.copy(res);
          $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
          $scope.days = angular.copy($scope.data);
          $scope.Prepayment = $scope.data.Prepayment;
          $scope.Reservation = $scope.data.Reservation;
          delete $scope.days.Prepayment;
          delete $scope.days.Reservation;
          $scope.title = "Bill To Report";
          angular.forEach($scope.Reservation, function(val){
            if(val.payment.other.length){
              payments = val.payment.other;
              angular.forEach(payments, function(bill_to){
                if($scope.getPeriod(val, day.clone(), end.clone()).isInRange){
                  bill_to.reference = bill_to.reference || {};
                  bill_to.booking_status = val.status;
                  bill_to.reference.model = bill_to.reference.model || 'Reservation';
                  bill_to.reference._id = bill_to.reference._id || val._id;
                  bill_to.date = bill_to.date || val.day;
                  due.push(bill_to);
                }
              })
            }
          });

          $scope.bill_to_grouped = _.groupBy(due, function(payment){
            return payment.partner ? payment.partner._id : '';
          });

          $scope.showBooking = function(filter, charge) {
            if(filter.billTo.paid === 'all' && charge.booking_status !== 'cancelled') return true;
            if(filter.billTo.paid === 'paid' && charge.paid) return true;
            if(filter.billTo.paid === 'unpaid' && !charge.paid && charge.booking_status !== 'cancelled') return true;
            if(filter.billTo.paid === 'cancelled' && charge.booking_status === 'cancelled') return true;
          }

          $scope.totalAR = function(group, filter){
            var total = 0;
            angular.forEach(group, function(charge){
              // dont total up cancelled bookings unless on the cancelled tab
              if(filter !== 'cancelled' && charge.booking_status !== 'cancelled'){
                if(filter === 'all') total += parseFloat(charge.amount);
                if(filter === 'paid' && charge.paid) total += parseFloat(charge.amount);
                if(filter === 'unpaid' && !charge.paid) total += parseFloat(charge.amount);
              } else if(filter === 'cancelled' && charge.booking_status === 'cancelled') {
                total += parseFloat(charge.amount);
              }
            });
            return total;
          }

          $scope.bill_to_payments = due;
          $scope.working = false;
        }
      );
    },

    clearSales: function(){
      Reports.clearSales(
        day.clone().format('MM-DD-YY'),
        end.clone().format('MM-DD-YY'),
        function win(res){
          if(res.success){
            alert(res.removed + ' cached records were cleared. Reloading report.');
            location.reload();
          }
        },
        function err(err){
          alert(err.message)
        }
      )
    },

    getSalesAgain: function(today){

      if(today) {
        dontSkipDateWatch = false;
        day = moment($scope.today).clone()
        end = day.clone();
        $scope.datePicker.date = {
            startDate: day,
            endDate: end
        }
      }

      $scope.range = true;
      $scope.working = true;
      Reports.sales(
        day.clone().format('MM-DD-YY'),
        end.clone().format('MM-DD-YY'),
        function Win(res){
          var data = angular.copy(res);
          $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
          $scope.days = angular.copy($scope.data);
          $scope.Prepayment = $scope.data.Prepayment;
          $scope.Reservation = $scope.data.Reservation;
          delete $scope.days.Prepayment;
          delete $scope.days.Reservation;
          $scope.title = "Sales Report";
          $scope.working = false;
        }
      );
    },

    salesReport: {

      getPeriod: function(item){
        var zeroed = moment.utc(item.date || item.day).hours(0).minutes(0).seconds(0)
        return {
          isBefore: zeroed.isBefore(day),
          isAfter: zeroed.isAfter(end),
          isInRange: !zeroed.isBefore(day) && !zeroed.isAfter(end)
        }
      },

      fees: 0,

      getCustomer: function(ref, charge){
        var id, card, payment;
        if(charge.reference.model === 'Reservation'){
          id = charge.stripe;
          card = ref.payment.cards.filter(function(card){
            return card.masked === charge.masked && card.charges.length;
          })[0];
          payment = card ? card.charges.filter(function(charge){
            return charge.id === id;
          })[0] : null;
        };
        return payment
      },

      charges: {
        addCharges: function(charges, obj, type){
          var total = 0;
          if(charges && charges.constructor.name !== 'Array') charges = [charges];
          if(type === 'discounts') {
            angular.forEach(obj.sales_report.items, function(item){
              total += parseFloat(item.discount) * -1;
            });
          }
          angular.forEach(charges, function(charge){
            total += parseFloat(charge.total);
          })
          return total;
        },
        cash: function(cash){

          var total = 0;

          if(cash && cash.length) {
            angular.forEach(cash, function(payment){
              total += parseFloat(payment.total);
            })
          }

          if(cash && cash.length && cashMoneyCheck[cash[0].reference._id] === undefined){
            cashMoneyCheck[cash[0].reference._id] = true;
            return total;
          } else {
            return 0;
          }

        },
        depositsRedeemed: function(charges){
          var total = 0;
          if(charges){
            var payments = charges.filter(function(charge){
              return charge.period.before_day === true;
            });
            angular.forEach(payments, function(charge){
              total += parseFloat(charge.total);
            })
          }
          return total;
        },
        total: function(charges){
          var total = 0;
          angular.forEach(charges, function(val, key){
            if(val) total += val;
          });
          return total;
        }
      },
      // super duplicated, clean up after confirming data is good
      // dont waste time on it now, since this report has seems to have to be built from scratch
      // weekly based on whims
      totals: {
        aggregate: function(col, group, target){
          var total = 0, group, ref;
          angular.forEach(col, function(charge){
            var ref = $scope[charge.reference.model][charge.reference._id];
            try {
              if(charge[group]) {
                total += charge[group][target];
              }
            } catch (e) {
              console.error(e);
            }
          });
          return total;
        },
        calc: function(group, key){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report[key]);
          })
          return total;
        },
        flights: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.package);
          })
          return total;
        },
        tips: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.tips);
          })
          return total;
        },
        mdse: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.mdse);
          })
          return total;
        },
        photos: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.photos);
          })
          return total;
        },
        brunch: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.brunch);
          })
          return total;
        },
        deposits: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.deposits);
          })
          return total;
        },
        refunds: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.refunds);
          })
          return total;
        },
        sales: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) {
              total += parseFloat(ref.report.sales);
            }
          })
          return total;
        },
        paid: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(ref.report) total += parseFloat(ref.report.paid);
          })
          return total;
        },
        balance: function(group){
          var total = 0; var ref;
          angular.forEach(group, function(charge){
            ref = $scope[charge.reference.model][charge.reference._id];
            if(charge.reference.model === 'Reservation' && ref.report) total += parseFloat(ref.report.balance);
          })
          return total;
        },
      },

      totalSales: function(item){
        var total = 0;
        angular.forEach(item.report, function(item){
          if(item) total += parseFloat(item);
        });
        return total;
      },

      calcFees: function(obj){

        var totals = {
          charged: 0,
          refunded: 0
        };
        angular.forEach(obj, function(day){
          if(day.stripe_fees){
            totals.charged += (day.stripe_fees.charged * -1);
            totals.refunded += day.stripe_fees.refunded;
          }
        });
        totals.total = totals.charged + totals.refunded;
        return totals;

      },

      groupCharges: function(obj, single){
        single = single === undefined ? true : single;
        var charges = [];
        var booking_ids = [];
        angular.forEach(obj, function(day){
          day.cash = day.cash || [];
          day.charges = day.charges || [];
          day.bill_to = day.bill_to || [];
          day.prepayments = day.prepayments || [];
          day.refunds = day.refunds || [];
          // NOTE: ensures that we dont add a prepayment twice if it already exists in charges
          // this can happen because some prepayments are 100% discounted so dont show up
          // in charges, meaning we need to add them while others have actual payments tied to them
          // and should already be present ...
          day.prepayments = day.prepayments.filter(function(gift){
            return !day.charges.filter(function(charge){
              return charge.reference.model === 'Prepayment' && charge.reference._id === gift.reference._id;
            }).length;
          });
          day.discounts = day.discounts || [];
          day.discounts.forEach(function(discount){
            discount.name = discount.name || 'Discount';
          });
          var _charges = [].concat(day.cash, day.charges, day.bill_to, day.prepayments, day.discounts, day.refunds);
          if(day.stripe_fees) $scope.salesReport.fees += day.stripe_fees.total;
          angular.forEach(_charges, function(charge){
            var ref = $scope[charge.reference.model][charge.reference._id];
            charge.reference.name = {
                first: charge.reference.model === 'Reservation' ? ref.first_name : ref.contact.purchaser.name.split(' ')[0],
                last: charge.reference.model === 'Reservation' ? ref.last_name : ref.contact.purchaser.name.split(' ')[1]
            }
            if(booking_ids.indexOf(charge.reference._id) === -1){
              if(charge.reference.model === 'Prepayment') {
                ref.sales_report = ref.sales_report || {};
                ref.sales_report.discounts =  ref.sales_report.discounts || day.discounts.filter(function(val){
                  return val.reference.model === 'Prepayment' && val.reference._id === ref._id;
                });
              }
              if(!charge.date || (moment.utc(charge.date).isBefore($scope.start) && charge.stripe) || moment.utc(charge.date).isBetween($scope.start, $scope.end, 'days', '[]')){
                if(!charge.date){
                  charge.name = charge.name || 'Cash';
                  charge.date = charge.flightDay;
                }
                if(single) booking_ids.push(charge.reference._id);
                charges = charges.concat(charge);
              } else {
              }
            }
          })
        });
        return charges;
      },

      aggregate: function(obj, key1, key2, filter){
        var arr, total = 0;
        try {
          arr = obj[key1][key2];
        } catch (e) {
          arr = null;
        } finally {
          if(arr && arr.length){
            angular.forEach(arr, function(item){
              var period = $scope.salesReport.getPeriod(item);
              var ref = $scope[item.reference.model][item.reference._id];
              var bookingPeriod = $scope.salesReport.getPeriod(ref);
              total += bookingPeriod[filter] ? parseFloat(item.total) : 0;
            })
          }
          return total;
        }
      },

      calcTips: function(arr){
        var total = 0;
        if(arr && arr.length){
          total = parseFloat($scope.salesReport.calcItems(arr));
        }
        return total;
      },

      calcItems: function(arr){
        var total = 0;
        if(arr && arr.length){
          angular.forEach(arr, function(item){
            total += parseFloat(item.total);
          })
        }
        return total;
      },

      calcPhotos: function(arr){
        var total = 0;
        if(arr && arr.length){
          var photos = arr.filter(function(item){
            return /photo/i.test(item.name)
          });
          if(photos.length){
            total = parseFloat($scope.salesReport.calcItems(photos));
          }
        }
        return total;
      },

      calcBrunch: function(arr){
        var total = 0;
        if(arr && arr.length){
          var brunch = arr.filter(function(item){
            return /brunch/i.test(item.name)
          });
          if(brunch.length){
            total = parseFloat($scope.salesReport.calcItems(brunch));
          }
        }
        return total;
      },

      calcDeposits: function(charges){
        var total = 0;
        angular.forEach(charges, function(charge){
          if(charge.period.is_deposit){
            total += parseFloat(charge.total);
          }
        })
        return total;
      },

      calcRefunds: function(refunds){
        var total = 0;
        angular.forEach(refunds, function(charge){
          if(charge.period.on_day){
            total += parseFloat(charge.total);
          }
        })
        return total*-1;
      },

      calcPaid: function(obj){
        var total = 0;
        var payments = [];
        if(obj.sales_report){
          payments = payments.concat(obj.sales_report.charges);
          payments = payments.concat(obj.sales_report.cash);
          payments = payments.concat(obj.sales_report.bill_to);
          payments = payments.concat(obj.sales_report.prepayments_redeemed);
        }

        angular.forEach(payments, function(payment){
          var period = $scope.salesReport.getPeriod(payment);
          total += (period.isAfter && payment.type !== 'billTo')
            || !period.isAfter ? parseFloat(payment ? payment.total : 0) : 0
        });

        return total;
      }

    },

    getTips: function(today){

      $scope.range = true;
      $scope.working = true;
      Reports.sales(
        day.clone().format('MM-DD-YY'),
        end.clone().format('MM-DD-YY'),
        function Win(res){
          var data = angular.copy(res);
          $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
          $scope.days = angular.copy($scope.data);
          $scope.Prepayment = $scope.data.Prepayment;
          $scope.Reservation = $scope.data.Reservation;
          delete $scope.days.Prepayment;
          delete $scope.days.Reservation;
          $scope.title = "Sales Report";
          $scope.working = false;
        }
      );
    },

    getOldSales: function(tips){
      $scope.range = true;
      $scope.working = true;
      Reports.oldSales(
        day.clone().format('MM-DD-YY'),
        end.clone().format('MM-DD-YY'),
        function Win(res){
          $scope.active = tips ? 'tips' : 'oldSales';
          var byDay = angular.copy(res);
          // delete byDay.all;
          $scope.data = byDay;
          $scope.title = tips ? "Pilot Tips Report" : "Old Sales Report";
          $scope.working = false;
        }
      );
    },

    getCommissions: function(){
      $scope.range = true;
      $scope.working = true;
      Reports.commissions(
        day.clone().format('MM-DD-YY'),
        end.clone().format('MM-DD-YY'),
        function Win(res){
          $scope.active = 'commissions';
          var byDay = angular.copy(res);
          // delete byDay.all;
          $scope.data = byDay;
          $scope.title = "Commissions Report";
          $scope.working = false;
        }
      );
    },

    updateFlightValue: function(bookings){
      var totals = {
        amount: 0,
        count: 0,
        commissionable: 0
      };
      if(bookings){
        angular.forEach(bookings, function(booking){
          if(booking.paid_value){
            totals.amount += booking.paid_value || 0;
            totals.count += (booking.adults || 0) + (booking.children || 0);
          }
          if(booking.commissionable){
            totals.commissionable += booking.commissionable;
          }
        });
      }
      return totals;
    },

    toNumber: function(string){
      var isPercent, obj = { number: string || 0, percent: false };
      if(string && isNaN(string)){
        isPercent = /%$/.test(string);
        obj.percent = isPercent;
        if(isPercent) {
          obj.number = parseFloat(string.replace('%', '')) / 100;
        } else {
          obj.number = parseFloat(string.replace('$', '')) || 0;
        }
      }
      return obj;
    },

    aggregateTips: function(charges, items){
      var total = 0, count = 0;
			var pilots = {};

      angular.forEach(charges, function(val){
        if(val.tip > 0){
          count += 1;
          total += val.tip;
          if(val.pilots.length === 0) val.pilots.push('Pilot Not Assigned');
          angular.forEach(val.pilots, function(pilot){
            if(pilot){
              pilots[pilot] = pilots[pilot] || 0;
              pilots[pilot] -= (val.tip / val.pilots.length);
            }
          });
        }
      });
      return {
        count: count,
        total: total * -1,
				pilots: pilots
      };
    },

    ignoreBillTo: function(val){
      return val.card !== 'bill_to';
    },

    onlyBillToDeposits: function(deposits){
      if(deposits){
        return deposits.filter(function(data){
          return data.card === 'bill_to';
        })
      } else {
        return [];
      }
    },

		aggregateSales: function(){
      var last, total, count, credit, filter;
      last = arguments[arguments.length -1] !== undefined ? arguments[arguments.length -1] : true;
      total = 0;
      count = 0;
      filter = function(val){
        return true;
      };

      // the first param can be an function to be used as a filter
      if(typeof arguments[0] === 'function') {
        filter = arguments[0];
      }
      if(typeof arguments[0] === 'string') {
        filter = self[arguments[0]];
      }

      credit = (last.constructor.name === 'Boolean') ? last : true;
      angular.forEach(arguments, function(arg){
        if(arg !== undefined && /Object|Array/.test(arg.constructor.name)){

          angular.forEach(arg, function(val){
            var nested = val.constructor.name === 'Array';
            if(nested){
              angular.forEach(val, function(nest){
                if(filter(nest)) calc(nest);
              });
            } else {
              if(filter(val)) calc(val);
            }
            function calc(val){
              count += 1;
              total += val.amount || val.product && val.product.total || (val.value * val.count) || 0;
            };
          });

        }
      });
      return {
        count: count,
        total: credit ? total : total * -1
      };
		},

    calculatePackageCost: function(booking){
      var pack, adults, children, total, guestCount;
      pack = booking.package;

      if(pack){

        booking.adults = parseInt(booking.adults || 0);
        booking.children = parseInt(booking.children || 0);

        guestCount = booking.adults + booking.children;

        function vipPricing(){
          var max = 0;
          angular.forEach($scope.data.meta.vipPrices, function(group, i){
            if(guestCount >= group.min && guestCount <= group.max) total = group.price;
            if(max < group.max) max = group.max;
          });

          if(!total && guestCount > max){
            nonVipPricing();
          }
        }

        function nonVipPricing(){
          if(!pack.price.adult && pack.price.vip && pack.price.perperson) {
            pack.price.perperson = false;
          }

          if(pack.price.perperson){
            adults = booking.adults * pack.price.adult;
            children = (booking.children || 0) * (pack.price.child || 0);
            total = adults + children;
          } else {
            total = pack.price.vip;
          }
        }

        if(booking.vip && $scope.data.meta){
          vipPricing();
        } else {
          nonVipPricing();
        }

        if(booking.needs_pickup){
          var hotel = booking.accommodations.hotel;
          var pickupPrice = hotel && hotel.zone ? parseFloat(hotel.zone.price) : 0;
          total += (pickupPrice * guestCount);
        }

        if(isNaN(total)){
          console.error('booking with confirmation %s%d is having trouble calculating a package total', $scope.user.activeCompany().nickname, booking.confirmation);
          console.info(booking, pack);
        }

        return total;

      }

    },

    calculatePackageTotal: function(bookings){
      var count = 0, total = 0, guest_count = 0;
      angular.forEach(bookings, function(booking, id){
        if(!booking.hide && booking.status === 'active' && booking.package){
          count += 1;
          total += $scope.calculatePackageCost(booking);
          guest_count += (booking.adults || 0) + (booking.children || 0);
        }
      });
      return {
        count: count,
        total: total,
        guest_count: guest_count
      };
    },

    getEvents: function(){
      $scope.range = false;
      $scope.working = true;
      var cols = {};
      Events.range(
        day.clone().format('MM-DD-YY'),
        day.clone().add(6, 'days').format('MM-DD-YY'),
        function Win(res){
          var count = 0;
          var skipping_brunch = 0;
          angular.forEach(res, function(day){
            count += day.capacity.booked;
            skipping_brunch += (day.capacity.skipping_brunch || 0);
            angular.forEach(day.reportable, function(count, item){
              cols[item] = cols[item] || 0;
              cols[item] += count;
            })
          });
          $scope.data = { days: res, total: count, total_skipping_brunch: skipping_brunch, extra_columns: cols };
          $scope.active = 'glance';
          $scope.title = "Daily Overview";
          $scope.working = false;
        }
      );
    },

    getFlight: function(flight){
      $scope.range = false;
      return $scope.data.flights.filter(function(fl){
        return fl._id == flight.flight;
      })[0];
    },

    itSheet: function(){
      $scope.range = false;
      GetEvent('itSheet', function(res){
        $scope.title = 'IT Sheet'
        $scope.data = res;
      });

    },

    photos: function(){
      $scope.range = false;
      GetEvent('photos', function(res){
        $scope.title = "Photo Email List";
        $scope.data = res;
      });

    },

    salesSlip: function(){
      $scope.range = false;
      GetEvent('salesSlip', function(res){
        $scope.title = "Sales Slip Report";
        $scope.data = res.reservations;
      });

    },

    pickupSheet: function(){
      $scope.range = false;
      $scope.working = true;
      Pickups.forDay(day.format('MM-DD-YYYY'), function(response){
        $scope.working = false;
        $scope.title = "Pick-ups Report";
        $scope.active = 'pickupSheet';

        $scope.data = response;
      });

    },

    assignedBookings: [],

    addToAssigned: function(id){
      if(_.indexOf($scope.assignedBookings, id) == -1){
        $scope.assignedBookings.push(id);
      }
    },

    isAssigned: function(id){
      return _.indexOf($scope.assignedBookings, id) >= 0;
    }

  };

  angular.extend($scope, self)

});
