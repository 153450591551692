var APIGifts = angular.module('RessieApp.services.api.gifts', [])

.service('Gifts', function(Request, API, $rootScope, $state, $timeout){
  var self = this;

  self.queued = {};
  self.active = {};

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        list: {
            method: 'GET'
          , url: '/gifts'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , search: {
            method: 'GET'
          , url: '/gift/find'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , create: {
            method: 'POST'
          , url: '/gifts'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , redeem: {
            method: 'PATCH'
          , url: '/gift/:id'
          , isArray: false
          , params: { id: '@id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , unlink: {
            method: 'DELETE'
          , url: '/gift/:id/:booking'
          , isArray: false
          , params: { id: '@id', booking: '@booking' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , get: {
            method: 'GET'
          , url: '/gift/:id'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , send: {
            method: 'GET'
          , url: '/gift/:id/send'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , update: {
            method: 'PUT'
          , url: '/gift/:id'
          , params: { id: '@_id' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , remove: {
            method: 'DELETE'
          , url: '/gift/:id'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource('bookings', endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){

        // #TODO: Need to add in error handling based on returned codes
        if(response.error_type == 'token'){
          if(angular.isDefined(error)) $timeout(function(){
            var confirm = window.confirm(response.error);
            if(confirm){
              $window.location.href = '/login';
            }
            return error(response);
          });
        } else {
          return error(response.error)
        }

      } else {
        Request.renew(token);
        return success(response, token);
      }

    });
  };

  self.list = function(payload, success, error) {

    self.request('list',
      {
        payload: payload
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.search = function(query, success, error) {

    var payload = {};

    if(query.constructor.name === 'String') {
      payload['q'] = query;
    } else {
      payload = query;
    }

    self.request('search',
      {
        payload: { q: query }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.get = function(id, success, error) {
    self.request('get',
      {
        payload: { 'id': id }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.redeem = function(id, booking, date, success, error) {
    self.request('redeem',
      {
        payload: { 'id': id, booking: booking, date: date }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.unlink = function(id, booking, success, error) {
    self.request('unlink',
      {
        payload: { 'id': id, booking: booking }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.send = function(id, success, error) {
    self.request('send',
      {
        payload: { 'id': id }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.create = function(payload, success, error) {
    self.request('create',
      {
        payload: payload
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.update = function(payload, success, error) {
    self.request('update',
      {
        payload: payload
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.remove = function(id, success, error) {
    self.request('remove',
      {
        payload: { 'id': id }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

});
