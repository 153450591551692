'use strict';

var EventsCtrl = angular.module('RessieApp')
.value('event', null)
.controller('eventsCtrl', function ($scope, event, Flights, Bookings, $rootScope, $state, ipCookie, allowed, $window, $stateParams, Events, $timeout) {

  if(!allowed) $state.go('login');

  var Balloon;

  function getBalloon(){

    if(angular.isDefined($scope.flight)){
      Balloon = $scope.balloons.filter(function(val, index){
        if(val._id == $scope.flight.balloon) return val;
      })[0];

      return Balloon;
    }

  }

  function getDriver(id){

    var driver;

    driver = $scope.drivers.filter(function(val, index){
      if(val._id == id) return val;
    })[0];

    return driver;

  }

  function getChaser(id){

    var chaser;

    chaser = $scope.chasers.filter(function(val, index){
      if(val._id == id) return val;
    })[0];

    return chaser;

  }

  AppCtrl.activeTab($stateParams, $rootScope);

  /**
   * Conditional variables based on resolver data
   */
  if(event){

    $scope.flights = event.flights;

    $scope.reservations = event.reservations;

    angular.forEach($scope.reservations, function(res){
      res.remainingGuests = res.adults + (res.children || 0);
      if(res.flight){
        angular.forEach(res.flight, function(flight){
          res.remainingGuests -= flight.count;
        })
      }
    })

    $scope.event = event.event;

    /**
     * Meta data required for certain pages
     */
    if(angular.isDefined(event.meta)){
      var meta = event.meta;
      angular.forEach(meta, function(data, key){
        if(angular.isDefined(meta[key])) $scope[key] = data;
      });
    }

  }

  /**
   * Method used to capture selected crew and add them to an array.
   * This array is used to ensure that duplicates aren't possible.
   * @return {[type]} [description]
   */
  var getSelectedCrew = function(){

    var drivers = $scope.flight.crew.drivers
      , chasers = $scope.flight.crew.chasers
      , pilot   = $scope.flight.pilot
      , balloon = $scope.flight.balloon
      , hospitality = $scope.event ? $scope.event.hospitality : {}
      , extras = [];

    angular.forEach($scope.flights, function(flight, index){

      if(flight.pilot) extras.push(flight.pilot._id);
      if(flight.balloon) extras.push(flight.balloon._id);

      angular.forEach(flight.crew.drivers, function(driver, index){
        extras.push(driver._id);
      });

      angular.forEach(flight.crew.chasers, function(chaser, index){
        extras.push(chaser._id);
      });

    });


    var selected = _.compact(_.union(drivers, chasers, hospitality, [pilot], [balloon], extras));

    return selected;

  }

  $scope.$watch('flights', function(n){
    if(n){
      self.flight.selected = getSelectedCrew();
    }
  }, true);

  /**
   * Watch for changes in chosen chasers
   * Update the selected check array
   */
  $scope.$watch('flight.crew.chasers', function(n, o){
    if(n) {
      self.flight.selected = getSelectedCrew();
    }
  }, true);

  /**
   * Watch for changes in chosen drivers
   * Update the selected check array
   */
  $scope.$watch('flight.crew.drivers', function(n, o){
    if(n) {
      self.flight.selected = getSelectedCrew();
    }
  }, true);

  /**
   * Watch for changes to the pilot
   * Update the selected check array
   */
  $scope.$watch('flight.pilot', function(n, o){
    if(n) {
      self.flight.selected = getSelectedCrew();
    }
  });

  /**
   * Watch for changes to the balloon
   * Update the selected check array
   */
  $scope.$watch('flight.balloon', function(n, o){
    if(n) {
      self.flight.selected = getSelectedCrew();
    }
  });

  /**
   * Watch for changes in the faux driver field
   * This will then be pushed to crew.drivers
   */
  $scope.$watch('flight.driver', function(n, o){
    if(n) {
      self.flight.crew.drivers.push(n);
      $scope.flight.driver = null;
    }
  });

  /**
   * Watch for changes in the faux chaser field
   * This will then be pushed to crew.chasers
   */
  $scope.$watch('flight.chaser', function(n, o){
    if(n) {
      self.flight.crew.chasers.push(n);
      $scope.flight.chaser = null;
    }
  });

  /**
   * Watch for changes in the faux hospitality field
   * This will then be pushed to days hospitality
   */
  $scope.$watch('event.setHospitality', function(n, o){
    if(n) {
      $scope.event.hospitality.push(JSON.parse(n));
      $scope.event.setHospitality = null;
      self.flight.selected = getSelectedCrew();
    }
  });

  var self = {

      heading: 'Balloons'

    , note: ""

    , addNote: function(note, event){
        Events.addNote({ id: event._id, note: note }, function Success(res){
          event.notes.push(res);
          $scope.newNote = false;
          $scope.note = "";
        }, function Error(err){
          alert(JSON.stringify(err));
        });
      }

    , hideNote: function(note, event){
        Events.hideNote(note._id, function Success(res){
          note.hide = true;
        }, function Error(err){
          alert(JSON.stringify(err));
        });
      }

    , changed: function(balloon, orig){

      }

    , addCrew: function(flight){

        var chaser = getChaser(flight.chaser);

        flight.crew.chasers.push({
          _id: chaser._id,
          origId: chaser._id,
          name: chaser.name
        });

        flight.chaser = null;

      }

    , buildTip: function(booking){

        function Header(text){
          return html + "<h6 style='margin-bottom: 0;'>"+ text +"</h6>";
        }

        function Paragraph(text){
          return html + "<p>" + text + "</p>";
        }

        var html = "";
        if(booking.notes){
          if(booking.notes.instructions){
            html = Header('Special Instructions');
            html = Paragraph(booking.notes.instructions);
          }
          if(booking.notes.internal){
            html = Header('Internal Notes');
            html = Paragraph(booking.notes.internal);
          }
          if(booking.notes.memo){
            html = Header('Memo');
            html = Paragraph(booking.notes.memo);
          }
          if(booking.notes.memo){
            html = Header('Occasion');
            html = Paragraph(booking.notes.occasion);
          }
        }

        if(booking.pickup){
          if(booking.pickup.notes){
            html = Header('Notes for Driver');
            html = Paragraph(booking.pickup.notes);
          }
        }

        if(!booking.notes && !booking.pickup){
          html = false;
        }

        return html ? "<div style='text-align: left;'>" + html + "</div>" : html;
      }

    , setForm: function(FORM) {
        $scope.FORM = FORM;
      }

    , removeCrew: function(flight, index){
        flight.crew.chasers.splice(index, 1);
        $scope.FORM.$setDirty();
      }

    , addDriver: function(flight){

        var driver = getDriver(flight.driver);

        flight.crew.drivers.push({
          _id: driver._id,
          origId: driver._id,
          name: driver.name
        });

        flight.driver = null;

      }

    , removeDriver: function(flight, index){
        flight.crew.drivers.splice(index, 1);
        $scope.FORM.$setDirty();
      }

    , updateFlight: function(flight){

        var params = angular.copy(flight);

        params.crew.drivers = [];
        params.crew.chasers = [];

        angular.forEach(flight.crew.drivers, function(value, key){
          params.crew.drivers.push(value._id);
        });

        angular.forEach(flight.crew.chasers, function(value, key){
          params.crew.chasers.push(value._id);
        });

        params.pilot = flight.pilot._id;

        params.balloon = flight.balloon._id;

        Flights.update(params,
          function Success(res){
            flight.edit_crew = false;
          },
          function Error(err){
            console.error(err.error);
          }
        );

      }

      /**
       * Used to determine if a crew member was previously assigned for the day.
       */
    , notSelected: function(id, ignore){

        var selected = angular.copy($scope.flight.selected);

        if(angular.isDefined(ignore)) {
          var i;
          selected.forEach(function(val, index){
            if(val == ignore){
              i = index;
              selected.splice(index, 1);
            }
          });

        }

        return $.inArray(id, selected) == -1;

      }

    , bookingFlight: function(booking, flight){
        var index = _.findIndex(booking.flight, { 'flight': flight });
        return (index >= 0) ? booking.flight[index] : null;
      }

      /**
       * Assign a guest to a flight
       */
    , assign: function(booking){

        // booking.flight = booking.flight || [];

        function Assign(flight, count){

          booking.flight.push({ flight: flight._id, count: count });

          flight.reservations.push(booking);

          flight.guests += count;

          booking.count = count;

          Bookings.assignFlight(booking,
            function Success(res){
              booking.remainingGuests = totalGuests - count;
            },
            function Error(err){
              booking._flight = null;
              $scope.unassign(booking, flight, true)
              console.error(err)
            }
          );


        }

        var totalGuests = booking.remainingGuests;

        $scope.flights.filter(function(flight, index){

          if(flight._id == booking._flight){

            var remaining = flight.balloon.capacity - flight.guests;
            flight.guests = flight.guests || 0;

            if(remaining < totalGuests){
              bootbox.dialog({
                title: "The guests for NV" + booking.confirmation + " will be seperated!",
                message: "<p><strong>There are only " + remaining + " remaining spots available on " + flight.balloon.name + "</strong></p><p>If you proceed then the remaining guests will need to be assigned to another balloon as well.</p>",
                buttons: {
                  success: {
                    label: "Proceed",
                    className: "btn-primary",
                    callback: function() {
                      Assign(flight, remaining);
                    }
                  },
                  refund: {
                    label: "Nevermind",
                    className: "btn-link",
                    callback: function() {}
                  }

                }
              });
            } else {
              Assign(flight, totalGuests);
            }

          }
        });

      }

      /**
       * remove a guest from a flight
       */
    , unassign: function(booking, flight, faux){

        faux = faux || false;

        angular.forEach(flight.reservations, function(reservation, index){

          var count = $scope.bookingFlight(reservation, flight._id).count;

          if((reservation.flight == booking.flight) && (booking._id == reservation._id)){

            booking._flight = flight._id;

            flight.guests -= count;

            flight.reservations.splice(index, 1);

            var origIndex = _.findIndex($scope.reservations, { _id: booking._id });

            if(!faux){

              Bookings.unassignFlight(booking,
                function Success(res){
                  $scope.reservations[origIndex].remainingGuests += count;
                },
                function Error(err){
                  var message = (angular.isDefined(err.message)) ? err.message : err;
                  alert(message)
                }
              );
            }

          }

        });

      }

      /**
       * Change a previous Flights 'section'
       * @param  {String} section Section to change (eg. 'balloon', or 'pilot')
       * @param  {Object} target  Target Object, or flight
       * @param  {String} data    String formatted Object
       */
    , change: function(flight, array, target, index){

        var flightTarget = target.toObject(flight);

        if(angular.isDefined(index)) flightTarget = flightTarget[index];

        angular.copy(array).filter(function(val, key){
          if(val._id == flightTarget._id) {
            if(angular.isDefined(index)) {
              target.toObject(flight)[index] = val;
            } else {
              target.toObject(flight, val);
            }
          }
          return val;
        });

        $scope.FORM.$setDirty();

      }

    , removeHospitality: function(id){

        var self = this;

        $scope.event.hospitality.filter(function(val, index){
          if(val == id) {
            $scope.event.hospitality.splice(index, 1);
          }
        });

        $scope.flight.selected.filter(function(val, index){
          if(val == id) {
            $scope.flight.selected.splice(index, 1);
          }
        });

      }

      /**
       * New Flight details
       * @type {Object}
       */
    , flight: {

          day: $scope.day === 'tomorrow' ? $window.moment().add(1, 'day').hours(0).format('MM-DD-YY') : $scope.day
        , showNew: false
        , selected: []
        , pilot: null
        , balloon: null
        , crew: {
              drivers: []
            , chasers: []
            , remove: function(target, id){
                var self = this;
                self[target].filter(function(val, index){
                  if(val == id) {
                    self[target].splice(index, 1);
                  }
                });
              }
          }
        , save: function(){

            var self = this;

            Flights.create($scope.flight,
              function Success(res){

                self.reset();
                res.reservations = [];
                $scope.flights.push(res);

              },
              function Error(err){
                console.error(err);
              }
            );

          }

        , reset: function(){

            var balloon = $scope.flight.balloon
              , pilot = $scope.flight.pilot;

            $scope.flight.selected.splice($.inArray(pilot, $scope.flight.selected), 1);
            $scope.flight.selected.splice($.inArray(balloon, $scope.flight.selected), 1);

            $scope.flight.pilot = null;
            $scope.flight.balloon = null;
            $scope.flight.crew.drivers = [];
            $scope.flight.crew.chasers = [];

            $scope.flight.showNew = false;

          }

        , remove: function(id){

            var flightCopy = angular.copy($scope.flights);

            flightCopy.filter(function(val, index){
              if(val._id == id) {

                Flights.remove(id, function Success(res){

                  angular.forEach($scope.flights[index].reservations, function(booking, i){
                    angular.forEach($scope.reservations, function(res){
                      if(res._id == booking._id){
                        res.flight.splice(_.findIndex(res.flight, function(flight){
                          return flight.flight == id;
                        }), 1);
                        res.remainingGuests += res.adults + (res.children || 0);
                        // res.flight = null;
                      }
                    });

                  });
                  $scope.flights.splice(index, 1);
                });

              }
            });

          }
      }

    , update: function(){
        $scope.event.updating = true;
        var hospitality = [];
        var data = angular.copy($scope.event);

        angular.forEach(data.hospitality, function(element, index){

          if(typeof element == 'string'){
            element = JSON.parse(element);
          }
          hospitality.push(element._id);
        });

        data.hospitality = hospitality;

        Events.update(data,
          function Success(res){
            $scope.event.updating = false;
          },
          function Error(err){
            $scope.event.updating = false;
          }
        );
      }

    , editCapacity: false

    , updateCapacity: function(event, save){

        if(!save){

          event.capacity.remaining = event.capacity.total - event.capacity.booked;

        } else {

          Events.update({ _id: event._id, capacity: event.capacity.total },
            function Success(res){
              $scope.editCapacity = false;
            },
            function Error(err){

            }
          );

        }
      }

    , totalWeight: function(balloon){
        var bookings = balloon.reservations || [];
        var weights = 0;
        angular.forEach(bookings, function(booking){
          weights += booking.meta.weights.reduce(function(a, b){
            return a + b
          });
        })
        return weights;
      }

  };

  angular.extend($scope, self);

});
