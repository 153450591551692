var ArrayFilter = angular.module('RessieApp.filters.array', [])

.filter('arrayFilter', function () {

  return function (array, condition) {

    if (!condition || !array) { return array; }

    return array.filter(function(val){
      return eval(condition);
    });

  };

})

.filter('map', function () {
  return function (array, condition) {
    if (!condition || !array) { return array; }
    return array.map(function (val) {
      return eval(condition);
    });
  };
})

.filter('arrayCount', function () {

  return function (array) {
    if(!array) return 0;
    return array.length;
  };

})

.filter('uniqueArray', function(){
  return function(array){
    return array.filter(function(value, index, self) { 
      return self.indexOf(value) === index;
    });
  }
})

.filter('joinArray', function () {

  return function (array, separator) {
    if(!array) return undefined;
    return array.join(separator);
  };

});
