'use strict';

function getPeriod(guest, start, end) {
  var zeroed, sameAsStart, sameAsEnd;
  if (guest) {
    zeroed = moment.utc(guest.date || guest.day || guest.created).hours(0).minutes(0).seconds(0);
    sameAsStart = zeroed.format('MM/DD/YY') === start.format('MM/DD/YY');
    sameAsEnd = zeroed.format('MM/DD/YY') === end.format('MM/DD/YY');
    return {
      isBefore: !sameAsStart && zeroed.isBefore(start),
      isAfter: !sameAsEnd && zeroed.isAfter(end),
      isInRange: (!zeroed.isBefore(start) && !zeroed.isAfter(end)) || sameAsStart || sameAsEnd,
      date: zeroed,
      start: start,
      end: end
    }
  }
}

var partnerExcludes = ['Square POS', 'FareHarbor', 'FAREHARBOR', 'fareharbor', 'Fareharbor', 'CLOVER', 'clover', 'Clover'];

angular.module('RessieApp.directives.reports', [])

.service('salesReportGuest', function($window){

  var moment = $window.moment;

  function onSameBatch(date1, date2) {
    if(date1.constructor.name !== 'Moment') date1 = moment.utc(date1);
    if(date2.constructor.name !== 'Moment') date2 = moment.utc(date2);
    var date1IsBatched = this.dayIsBatched(date1);
    var date2IsBatched = this.dayIsBatched(date2);
    return date1IsBatched && date2IsBatched ? getPeriod(date2, moment.utc(date1IsBatched.start), moment.utc(date1IsBatched.end, 'YYYY-MM-DDTHH:mm:ssZ')).isInRange : false;
  }

  function Aggregate(arr, filter, agg){
    agg = agg || false;
    filter = filter || function() { return true; };
    var total = 0;
    if(arr && arr.length){
      angular.forEach(arr.filter(filter), function(item){
        if(!agg) {
          total += parseFloat(item.total || 0);
        } else {
          total += agg(item);
        }
      });
    }
    return total;
  }

  function AggregateCharges(cards, start, end){
    var total = 0;
    angular.forEach(cards, function(card){
      angular.forEach(card.charges, function(charge){
        total += !charge.date || getPeriod(charge, start, end).isInRange ? charge.amount : 0;
      })
    });
    return total;
  }

  function AggregateRefunds(cards, start, end){
    var total = 0;
    angular.forEach(cards, function(card){
      angular.forEach(card.charges, function(charge){
        angular.foreach(charge.refunds, function(refund){
          total += getPeriod(charge, start, end).isInRange ? (charge.amount * -1) : 0;
        });
      })
    });
    return total;
  }

  var prepGifts = {
    aggregateFromReport: function(parent, group){
      var days = parent._raw.days;
      var section = [];
      angular.forEach(days, function(day){
        section = section.concat((day[group] || []).filter(function(charge){
          return charge.reference.model === parent.reference.reference_model
            && charge.reference._id === parent.reference._id;
        }))
      });
      if(section.length && group === 'discounts') section = section[0]
      return section;
    }
  }

  function Guest(guest, report){
    this.start = report.start;
    this.end = report.end;
    this.period = getPeriod(guest, report.start, report.end),
    this.reference = guest;
    this._raw = report;
    return this;
  }

  Guest.prototype.dayIsBatched = function(day){

    if(day.constructor.name !== 'Moment') {
      day = moment.utc(day);
    }

    var isBatched = this._raw.batches && this._raw.batches.filter(function(batch){
      if(batch.dates.indexOf(day.format('YYYY-MM-DDT00:00:00.000[Z]')) >= 0) {
        return batch
      }
    });

    return isBatched && isBatched.length ? isBatched[0] : false;

  }

  Guest.prototype.sales_report = function(){
    var ref = this.reference.reference_model;
    return ref === 'Reservation' ? this.reference.sales_report : {
      charges: prepGifts.aggregateFromReport(this, 'charges'),
      bill_to: prepGifts.aggregateFromReport(this, 'bill_to'),
      cash: prepGifts.aggregateFromReport(this, 'cash'),
      refunds: prepGifts.aggregateFromReport(this, 'refunds'),
      discounts: prepGifts.aggregateFromReport(this, 'discounts'),
      items: prepGifts.aggregateFromReport(this, 'items')
    }
  }

  Guest.prototype.mdse = function(){
    var self = this;
    var report = this.sales_report();
    var ref = self.reference.reference_model;
    var prepaid = (ref === 'Prepayment'
      && self.reference.type === 'card'
      && !self.reference.electronic);

    if(prepaid) {
      // manually add prepaid shipping and handling to the merchandise report ... hack
      report.items = report.items.concat([
        {
          count: 1,
          total: self.reference.shipping ? self.reference.shipping : "2.5",
          date: self.reference.created,
          product_id: {
            name: "GC Shipping",
            "independent": true
          },
          reference: {
            'model': ref,
            '_id': self.reference._id
          }
        }
      ]);
    }

    var guest_items = report ? report.items.filter(function(item){
      // only items in range to simplify next filter;
      return getPeriod(self.reference, self.start, self.end).isInRange
    }).filter(function(item){
      if(item.product_id && (/tip/i).test(item.product_id.name)) return false;
      var dateAdded = item.date;
      item.date_added = dateAdded;
      item.date = self.reference.day || self.reference.date || dateAdded;
      var cancelled_but_independent = (item.product_id &&
      item.product_id.independent) &&
      self.reference.status === 'cancelled';
      // NOTE: Show up on product add date - disabled per @link https://www.pivotaltracker.com/story/show/132872007/comments/153948917
      // return active_and_independent && getPeriod(item, self.start, self.end).isInRange;
      return (ref === 'Prepayment' && prepaid) || cancelled_but_independent || self.reference.status === 'active';
    }) : [];

    return guest_items;

  }

  Guest.prototype.groupSales = function(){
    var self = this;
    var report = this.sales_report();
    var ref = self.reference.reference_model;
    var SnH = (ref === 'Prepayment'
      && self.reference.type === 'card'
      && !self.reference.electronic) ? this.reference.shipping ? this.reference.shipping :2.5 : 0;
    var cart = ref === 'Reservation' ? self.reference.cart : undefined;

    var cc_tips = report && self.period.isInRange ? Aggregate(report.tips) : 0;
    var retail_tips = report && self.period.isInRange ? Aggregate(report.items, function(item){
      return (/^tip/i).test(item.name);
    }) : 0;

    var refunds = report.refunds.filter(function(refund){
      return getPeriod(refund, self.start, self.end).isInRange
    });

    var flightCost = parseFloat(report.package ? report.package.total : 0);

    var vipPrice = ((self.reference.package || {}).vipPricing || []).find(function(vip){
      var count = (self.reference.adults + self.reference.children || 0);
      return vip.min <= count && vip.max >= count;
    })

    if (self.reference.hotel && report.pickups) {
      flightCost -= (parseFloat(report.pickups.total))
    }

    function depositFilter(charge) {
      if(self.reference.reference_model !== 'Reservation') return undefined;
      var booking_day = moment.utc(self.reference.day);
      var charged_before_flight = getPeriod(charge, booking_day, booking_day).isBefore;
      var charged_in_range = getPeriod(charge, self.start, self.end).isInRange;
      return charged_before_flight && charged_in_range ? charge : undefined;
    };

    var credits = {
      'flights': (function(){
        
        if (self.reference.vip && vipPrice) return vipPrice.price;

        return report
          && self.period.isInRange
          && report.package
          && self.reference.status !== 'cancelled' ? flightCost : 0
        })(),
      'tips': cc_tips + retail_tips,
      'mdse': (report ? Aggregate(report.items, function(item){
        if((/tip/i).test(item.name)) return false;
        if(item.product_id && (/tip/i).test(item.product_id.name)) return false;
        var active_and_independent = item.product_id &&
        item.product_id.independent ||
        self.reference.status === 'active';
        // NOTE: Show up on product add date - disabled per @link https://www.pivotaltracker.com/story/show/132872007/comments/153948917
        // return active_and_independent && getPeriod(item, self.start, self.end).isInRange;
        return active_and_independent && getPeriod(self.reference, self.start, self.end).isInRange;
      }) : 0) + SnH,
      'pickup': self.reference.status === 'active' &&
        report.pickups &&
        self.period.isInRange ? parseFloat(report.pickups.total) : 0,
      'gifts': ref === 'Prepayment' && getPeriod(self.reference, self.start, self.end).isInRange ? self.reference.amount : 0,
      'deposits_received': report ? Aggregate(report.charges, depositFilter) : 0,
      'refunds': {
        total: Aggregate(report.refunds, function(refund){
          var chargeIsDeposit = self.reference.reference_model === 'Reservation' && getPeriod(refund.parent, moment.utc(self.reference.day), moment.utc(self.reference.day)).isBefore;
          return getPeriod(refund, self.start, self.end).isInRange
        }) || 0,
        list: refunds
      }
    }
    return credits;

  }

  Guest.prototype.groupDebits = function(){

    var self = this;
    var report = this.sales_report();

    var refunds = report.refunds.filter(refundedDepositFilter);

    var flightRefunds = Aggregate(report.refunds, function(refund){
      if (self.reference.reference_model !== "Reservation") return false;
      refund.date = moment.utc(refund.date);

      var cards = self.reference.payment.cards;
      var parent = cards.reduce(function(obj, card){
        var charges = card.charges;
        var charge = charges.filter(function(ch){
          return ch.refunds.filter(function(re){
            return re.id === refund.stripe;
          }).length > 0;
        })[0];
        if(charge) return charge;
        return obj;
      }, {});

      var sameBatch = (onSameBatch.bind(self, refund.date, parent.date))();
      var chargeIsBatched = self.dayIsBatched(moment.utc(parent.date));
      var refundIsBatched = self.dayIsBatched(moment.utc(refund.date));
      var chargeBeforeRefund = getPeriod(parent, refund.date, refund.date).isBefore;
      var chargeInReportRange = getPeriod(parent, self.start, self.end).isInRange;
      var refundInReportRange = getPeriod(refund, self.start, self.end).isInRange;

      var neitherIsBatched = !chargeIsBatched && !refundIsBatched;
      var bothAreBatched = chargeIsBatched && refundIsBatched;

      var sameBatchSameReport = sameBatch && (chargeInReportRange && refundInReportRange);
      var chargeBatchedOutside = !sameBatch && (!chargeInReportRange && chargeIsBatched);
      var bothBatchedDiffReports = bothAreBatched && (chargeInReportRange && !refundInReportRange);
      var bothBatchedSameReport = sameBatch && (chargeInReportRange && refundInReportRange);

      if(refundInReportRange && chargeBatchedOutside) return true

    });

    function chargeIsDeposit(charge) {
      var booking_day = moment.utc(self.reference.day);
      var charged_before_flight = getPeriod(charge, booking_day, booking_day).isBefore;
      return charged_before_flight ? charge : undefined;
    }

    function refundedDepositFilter(refund) {
      if (self.reference.reference_model !== "Reservation") return;
      var cards = self.reference.payment.cards;
      var flight_day = moment.utc(self.reference.day);
      // find the original charge that was linked to the refund.
      // this data was returned by the api, but we'll need this fallback to avoid
      // forcing a full recache :/
      var parent = cards.reduce(function (obj, card) {
        var charges = card.charges;
        var charge = charges.filter(function (ch) {
          return ch.refunds.filter(function (re) {
            return re.id === refund.stripe;
          }).length > 0;
        })[0];
        if (charge) {
          return charge;
        }
        return obj;
      }, {});

      var chargeIsBatched = self.dayIsBatched(moment.utc(parent.date));

      return getPeriod(parent, flight_day, flight_day).isBefore
        && getPeriod(refund, self.start, self.end).isInRange;
    }

    function depositsRedeemedFilter (charge) {
      var booking_day = moment.utc(self.reference.day);
      var booking_in_range = getPeriod(self.reference, self.start, self.end).isInRange;
      var charged_before_flight = getPeriod(charge, booking_day, booking_day).isBefore;
      var charged_in_range = getPeriod(charge, self.start, self.end).isInRange;
      var passing = /*!charged_in_range && */booking_in_range && charged_before_flight
        && (
          self.reference.status === 'active'
          || (self.reference.status === 'cancelled' && (parseFloat(charge.total) > 0))
        );
      return passing;
    }

    function creditCardFilter(charge) {
      var charge_in_report_range = getPeriod(charge, self.start, self.end).isInRange;
      var deposit = chargeIsDeposit(charge);
      var redeemed_deposit = deposit && depositsRedeemedFilter(deposit);
      var charge_refunded = refunds.find(function (refund) {
        return refund.parent.id === charge.stripe
      }) !== undefined;
      var refunded_deposit = (deposit && charge_refunded);
      return /*!redeemed_deposit && */charge_in_report_range && (!charge_refunded || refunded_deposit);
    };

    var debits = {
      'credit_card': {
        total: Aggregate(report.charges, creditCardFilter),
        list: report.charges.filter(creditCardFilter)
      },
      'cash': {
        total: Aggregate(report.cash, function (charge) {
          charge.date = charge.flightDay || self.reference.day;
          return getPeriod(charge, self.start, self.end).isInRange;
        }),
        list: report.cash.filter(function (charge) {
          charge.date = charge.flightDay;
          return getPeriod(charge, self.start, self.end).isInRange;
        })
      },
      'gifts_redeemed': {
        total: Aggregate(report.prepayments_redeemed, function (charge) {
          var charge_period = getPeriod(charge, self.start, self.end);
          var booking_period = getPeriod(self.reference, self.start, self.end);
          return (self.reference.status === 'active' && (charge_period.isInRange || charge_period.isAfter)) && booking_period.isInRange;
        }, function(item){
          var redeemedAmount = self.reference.payment.gifts.filter(function(g){
            return g.code === item.code;
          }).reduce(function(prevAmount, gift) {
            var used = (gift.used || []).reduce(function(prev, cur){
              if (cur.reservation === self.reference._id) {
                return prev + cur.amount;
              } else {
                return prev;
              }
            }, 0);
            return prevAmount + (used || gift.amount)
          }, 0);
          return redeemedAmount;
        }),
        list: report.prepayments_redeemed
      },
      'deposits_redeemed': {
        // TODO: check for refunded deposits and if they happen before teh date range remove
        // TODO: [continued] the amount of the refund from the deposit total.
        // TODO: [continued] This should ensure that we keep deposits redeemed but dont overbalance due to refunds.
        total: (Aggregate(report.charges, depositsRedeemedFilter, function (deposit) {
          return parseFloat(deposit.total) - (Aggregate(report.refunds, function(re){
            return re.parent.id === deposit.stripe && getPeriod(re, self.start, self.end).isBefore
          }) + parseFloat(deposit.refund || 0));
        }) || 0),
        list: report.charges.filter(depositsRedeemedFilter)
      },
      'ar': {
        total: Aggregate(report.bill_to, function (billTo) {
          if (partnerExcludes.filter(function(exclude) {
            return billTo.name === exclude // Quick hack to let the team enter square sales as billto without them showing up in this report
          }).length) return false;
          return getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        }),
        list: report.bill_to.filter(function (billTo) {
          if (partnerExcludes.filter(function (exclude) {
              return billTo.name === exclude // Quick hack to let the team enter square sales as billto without them showing up in this report
            }).length) return false;
          return getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        })
      },
      'square': {
        total: Aggregate(report.bill_to, function (billTo) {
          return billTo.name === 'Square POS' && getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        }),
        list: report.bill_to.filter(function (billTo) {
          return billTo.name === 'Square POS' && getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        })
      },
      'fareharbor': {
        total: Aggregate(report.bill_to, function (billTo) {
          return /fareharbor/i.test(billTo.name) && getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        }),
        list: report.bill_to.filter(function (billTo) {
          return /fareharbor/i.test(billTo.name) && getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        })
      },
      'clover': {
        total: Aggregate(report.bill_to, function (billTo) {
          return /clover/i.test(billTo.name) && getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        }),
        list: report.bill_to.filter(function (billTo) {
          return /clover/i.test(billTo.name) && getPeriod(self.reference, self.start, self.end).isInRange && self.reference.status !== 'cancelled';
        })
      },
      'discounts': {
        total: self.reference.status !== 'cancelled' && report.discounts && getPeriod(report.discounts, self.start, self.end).isInRange ? parseFloat((report.discounts.total * -1) || 0) : 0,
      },
      'flights_refunded': report ? flightRefunds : 0,

      // Original charge was before flight date, and refund is within date range of report
      'deposits_refunded': report ? Aggregate(report.refunds, refundedDepositFilter) : 0,

    };

    // console.log(debits['gifts_redeemed'], report.prepayments)

    // NOTE: Deposits redeemed automatically removes deposits refunded if deposit refunds are in report range
    debits['deposits_redeemed'].total = debits['deposits_redeemed'].total > 0 ? debits['deposits_redeemed'].total - debits['deposits_refunded'] : 0;

    return debits;
  }

  Guest.prototype.GuestSales = function(){

    var item = self = this;
    var ref = item.reference.reference_model;
    var flightStatus = item.reference.status;
    var report = this.sales_report();
    var cart = ref === 'Reservation' ? item.reference.cart : undefined;
    var gift_name;

    if(ref !== 'fees') {

      if(ref && item.reference.reference_model === 'Prepayment') {
        gift_name = item.reference.contact.purchaser.name !== undefined ? item.reference.contact.purchaser.name : item.reference.contact.recipient.name;
        gift_name = gift_name || "Given, NoName";
      }
  
      item.sort = {
        first: item.reference.reference_model === 'Reservation' ? item.reference.first_name : gift_name.split(' ')[0],
        last: item.reference.reference_model === 'Reservation' ? item.reference.last_name : gift_name.split(' ')[1]
      }
  
      var credits = this.groupSales();
  
      credits['total'] = Object.keys(credits).reduce(function(p, c){
        var credit = credits[c];
        var amount = credit.constructor.name === 'Object' ? credit.total : credit;
        return p + amount;
      }, 0)
  
      // NOTE: sets discount date to the flight date, since they're not yet timestamped
      if(report && report.discounts) {
        // prepayment discounts are formated as an array
        report.discounts = report.discounts.constructor.name === 'Array'
          ? report.discounts[0] : report.discounts;
        if(report.discounts) {
          report.discounts.date = report.discounts.date || report.discounts.flightDay;
        }
      }
  
      var debits = this.groupDebits();
  
      debits['total'] = Object.keys(debits).reduce(function(p, c){
        var debit = debits[c];
        var amount = debit.constructor.name === 'Object' ? debit.total : debit;
        return p + amount;
      }, 0);

      // Should appear on the sales table
      credits.available = function(){
        return item.reference.reference_model === 'Prepayment'
          || (credits.total !== 0 || debits.total !== 0)
      };
  
      // should appear on the charges table
      debits.available = function(){
        return item.reference.reference_model === 'Prepayment'
          || (credits.refunds.total && credits.refunds.total !== 0)
          || (debits.total !== 0)
      };

      var balanceDue = (credits.total - debits.total);
      
      return {
        'reference': item.reference,
        credits: credits,
        debits: debits,
        balance: balanceDue * -1
      };
      
    }

  }


  Guest.prototype.report = function(){
    return this.GuestSales();
  }

  return Guest;

})

.service('salesReport', function(salesReportGuest, Reports){

  function Report(data, start, end){
    var self = this;
    this.start = moment.utc(start);
    this.end = moment.utc(end);
    this.data = data;
    this.credits = {
      'flights': 0,
      'tips': 0,
      'mdse': 0,
      'pickup': 0,
      'gifts': 0,
      'deposits_received': 0,
      'cash_refunds': 0,
      'refunds': 0, // cc is credit, 'items' are debit
      'total': 0
    };
    this.debits = {
      'credit_card': 0,
      'cash': 0,
      'ar_received': 0, //debit
      'gifts_redeemed': 0,
      'deposits_redeemed': 0,
      'ar': 0,
      'discounts': 0,
      'deposits_refunded': 0,
      'flights_refunded': 0, // items_refunded
      'total': 0
    };
    self.days = {};
    self.references = {};
    angular.forEach(Object.keys(data), function(key){
      if(key.match(/(\d{2}\/\d{2}\/\d{2,4})/)){
        self.days[key] = data[key];
      } else {
        self.references[key] = data[key];
      }
    });
    return self;
  }

  Report.prototype.fees = function(){
      var totals = {
        charged: 0,
        refunded: 0
      };
      angular.forEach(this.days, function(day){
        if(day.stripe_fees){
          totals.charged += (day.stripe_fees.charged * -1);
          totals.refunded += day.stripe_fees.refunded;
        }
      });
      totals.total = totals.charged + totals.refunded;
      return totals;
  };

  Report.prototype.batch = function(dont_transfer, cb){
    dont_transfer = dont_transfer !== undefined ? dont_transfer : false;
    var fees = this.fees();
    var self = this;
    this.working = true;
    Reports.createBatch({
      start: this.start,
      end: this.end,
      batch: {
        balance: this.balance,
        fees: {
          sales: Math.abs(fees.charged),
          refunds: fees.refunded,
          total: fees.total
        },
        debits: this.debits,
        credits: this.credits,
      },
      skip_transfer: dont_transfer
    }, function Success(res){
      self.working = false;
      cb(res);
    }, function Error(err){
      self.working = false;
    })
  };

  Report.prototype.build = function(){
    var guests = [];
    var mdse = [];
    var self = this;

    // Build Pilot object
    var pilots = {};

    self.batches = this.references['batches'];
    var fees = this.references['fees'];

    delete this.references['requesterInformation'];
    delete this.references['requestUrl'];
    delete this.references['serverInformation'];
    delete this.references['batches'];
    delete this.references['fees'];

    // loop over data points (bookings/gifts)
    angular.forEach(this.references, function(references, model){
      // each booking/gift
      angular.forEach(references, function(reference, _id){

        var flightDay, guest, guest, guestReport;

        flightDay = moment.utc(reference.day).format('MM-DD-YY');
        
        if (typeof reference === 'object') {
          reference.reference_model = model;
        }
        
        guest = new salesReportGuest(reference, self);

        mdse = mdse.concat(guest.mdse());

        // .filter(function(item){
        //   return ((item.product_id && item.product_id.independent) || typeof item.product_id === 'string') || reference.status === 'active'
        // });

        guestReport = guest.report();

        if(reference.status === 'active'
          || (reference.status === 'cancelled' && guestReport.debits.total !== 0)
          || (reference.status === 'cancelled' && guestReport.debits.deposits_refunded !== 0)
          || (reference.status === 'cancelled' && guestReport.credits.total !== 0)
          || (reference.status === 'cancelled' && guestReport.credits.refunds.total !== 0)
          || model === 'Prepayment'){

          guest.report = guestReport;

          // Automatically map credits to grand totals
          Object.keys(guestReport.credits).forEach(function(key){
            var credit = guestReport.credits[key];
            var keyType = credit ? credit.constructor.name : false;
            if(keyType === 'Object' || keyType === 'Number') {
              self.credits[key] = self.credits[key] || 0;
              self.credits[key] += (credit.total !== undefined ? credit.total : credit) || 0;
            }
          });

          // Automatically map debits to grand totals
          Object.keys(guestReport.debits).forEach(function(key){
            var debit = guestReport.debits[key];
            var keyType = debit ? debit.constructor.name : false;
            if(keyType === 'Object' || keyType === 'Number') {
              self.debits[key] = self.debits[key] || 0;
              self.debits[key] += (debit.total !== undefined ? debit.total : debit) || 0;
            }
          });

          // NOTE: Maps pilots to tips
          if(reference.flight && reference.flight.length && self.credits.tips){
              angular.forEach(reference.flight, function(flight){
                flight = flight.flight;
                if(flight) {
                  var pilot = flight.pilot;
                  if(pilot){
                    if(getPeriod({date: flightDay}, self.start, self.end).isInRange){
                      pilots[pilot.name] = pilots[pilot.name] || {};
                      pilots[pilot.name][flightDay] = pilots[pilot.name][flightDay] || 0;
                      pilots[pilot.name][flightDay] += (guestReport.credits.tips / reference.flight.length) || 0;
                    }
                  }
                }
              });
          }

          guests.push(guest);

        }
      });
    });

    self.tips = {
      pilots: pilots,
      days: {}
    }

    angular.forEach(pilots, function(pilot, name){
      angular.forEach(pilot, function(tips, day){
        self.tips.days[day] = self.tips.days[day] || 0;
        self.tips.days.total = self.tips.days.total || 0;
        self.tips.days[day] += tips;
        self.tips.days.total += tips;
        
        self.tips.pilots[name].total = self.tips.pilots[name].total || 0;
        self.tips.pilots[name].total += tips;
      })
    })

    self.balance = (self.debits.total - self.credits.total);

    mdse = mdse.filter(function(item){
      return getPeriod(item, self.start, self.end).isInRange;
    });

    self.mdse = {
      'days': _.groupBy(mdse, function(item){
        return moment.utc(item.flightDay || item.date).format('MM-DD-YY')
      }),
      'items': _.groupBy(mdse, function(item){
        return item.product_id ? item.product_id.name : item.name;
      }),
      'total': mdse.reduce(function(total, obj){
        return total + parseFloat(obj.total)
      }, 0)
    };

    angular.forEach(self.mdse.items, function(items, key){

      self.mdse.items[key] = {
        days: _.groupBy(items, function(item){
          return moment.utc(item.flightDay || item.date).format('MM-DD-YY')
        }),
        count: items.reduce(function(val, obj){
          return val + parseInt(obj.count)
        }, 0),
        total: items.reduce(function(total, obj){
          return total + parseFloat(obj.total)
        }, 0)
      }

      angular.forEach(self.mdse.items[key].days, function(items, day){
        self.mdse.items[key].days[day] = {
          refs: items,
          count: items.reduce(function(val, obj){
            return val + parseInt(obj.count)
          }, 0),
          total: items.reduce(function(total, obj){
            return total + parseFloat(obj.total)
          }, 0)
        }
      });

    });

    // NOTE: If deposits happened lets add any of the refunded deposits.
    // Refunds show on another line so Gabe wants it this way to show totals I guess, maybe? I dont get it...
    // if(self.credits.deposits_received > 0) {
    //   self.credits.deposits_received += self.debits.deposits_refunded;
    // }

    self.guests = guests;
    return self;
  }

  return Report;

})

.directive('salesReport', function(salesReport, $parse, $timeout){
  return {
    restrict: 'A',
    scope: true,
    controller: function($scope, Reports, $timeout){},
    compile: function(tElement, tAttrs, transclude){
      return function Link(scope, element, attrs, Ctrl){
        scope.expanded = false;

        scope.calcFees = function(obj){
          var totals = {
            charged: 0,
            refunded: 0
          };
          angular.forEach(obj, function(day){
            if(day.stripe_fees){
              totals.charged += (day.stripe_fees.charged * -1);
              totals.refunded += day.stripe_fees.refunded;
            }
          });
          totals.total = totals.charged + totals.refunded;
          return totals;
        };

        scope._ = scope.$parent.$parent;

        scope.$parent.$watch(attrs.salesReport, function(n){
          if(n) {
            scope.report = new salesReport(n, scope.start, scope.end);
            scope.fees = n.fees;
            if(!n.is_batch || n.is_batch === undefined) {
              var built = scope.report.build();
              scope.compiled = built;
            }
          }
        });
      }
    }
  }
})

.directive('salesReportGuest', function($templateCache, $parse, $compile, $filter){
  return {
    restrict: 'A',
    require: '^salesReport',
    scope: true,
    controller: function($scope, $rootScope){},
    compile: function(tElement, tAttrs, transclude){
      return function Link(scope, element, attrs, Ctrl){
        scope.guest.report.all_debits = [];

        // refunds were moved to credits, need to add them
        angular.forEach(scope.guest.report.credits, function(charge, key){
          // if list then it is an array and we need to show each
          if(charge.list){
            angular.forEach(charge.list, function(charge){
              charge.key = key;
              if(charge.key === 'refunds') {
                charge.total = charge.total * -1;
                scope.guest.report.all_debits.push(charge)
              }
            });
          }
        });

        angular.forEach(scope.guest.report.debits, function(charge, key){
          // if list then it is an array and we need to show each
          if(charge.list){
            angular.forEach(charge.list, function(charge){
              charge.key = key;
              if(charge.key === 'refunds') charge.total = charge.total * -1;
              scope.guest.report.all_debits.push(charge)
            })
          } else {
            if(key !== 'total') scope.guest.report.all_debits.push({
              key: key,
              total: charge.total
            })
          }
        });
        var row = $compile(angular.element($templateCache.get('dir/reports/table-group')))(scope);
        element.after(row);
      }

    }
  };
})
