angular.module('RessieApp.directives.date', [])

.directive('dateEntry', function(){

  return {
      restrict: 'A'
    , compile: function(tElement, tAttrs) {

        return function Link(scope, $elm, attr) {
          
          var defined = scope.$eval(attr.dateEntry) || null
            , config = { spinnerImage: '' };

          if(defined) {
            if(angular.isDefined(defined.min)) config.minDate = defined.min;
            if(angular.isDefined(defined.format)) config.dateFormat = defined.format;
          }

          $elm.dateEntry(config);

        }

      }

  };

});