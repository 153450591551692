var reports = angular.module('RessieApp.services.api.reports', [])

.service('Reports', function(Request, API, $rootScope, $state, $timeout){

  var self = this;

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        oldSales: {
            method: 'GET'
          , url: '/report/old-sales/:start/:end'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , sales: {
            method: 'GET'
          , url: '/report/sales/:start/:end'
          , cache: true
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , deposits: {
            method: 'GET'
          , url: '/report/deposits/:start/:end'
          , cache: true
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , discrepancies: {
            method: 'GET'
          , url: '/report/discrepancies/:start/:end'
          , cache: true
          , isArray: true
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }


      , tips: {
            method: 'GET'
          , url: '/report/tips/:start/:end'
          , isArray: true
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , removeSales: {
            method: 'DELETE'
          , url: '/report/sales/:start/:end/cache'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

			, commissions: {
            method: 'GET'
          , url: '/report/booked/:start/:end'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

			, gifts: {
            method: 'GET'
          , url: '/report/gifts/:start/:end'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , bookedOn: {
            method: 'GET'
          , url: '/report/bookedon/:start/:end'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , 'bookingSource': {
            method: 'GET'
          , url: '/report/sources/:start/:end'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , 'byPackage': {
            method: 'GET'
          , url: '/report/package/:start/:end'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , createBatch: {
            method: 'POST'
          , url: '/report/batch'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);

    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource('cards', endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){
      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) $timeout(function(){ return Request.error(response, error); });
      } else {
        return success(response);
      }

    });
  };

  self.oldSales = function(start, end, success, error) {

    if(typeof end == 'function'){
      success = end;
      error = success;
      end = angular.copy(start);
    }

    self.request('oldSales',
      {
        payload: { start: start, end: end }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.clearSales = function(start, end, success, error) {

    if(typeof end == 'function'){
      success = end;
      error = success;
      end = angular.copy(start);
    }

    self.request('removeSales',
      {
        payload: { start: start, end: end }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.sales = function(start, end, success, error, batch) {

    if(typeof end == 'function'){
      batch = error;
      error = success;
      success = end;
      end = angular.copy(start);
    }

    self.request('sales',
      {
        payload: { start: start, end: end, batch: batch }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.deposits = function(start, end, success, error, batch) {

    if(typeof end == 'function'){
      batch = error;
      error = success;
      success = end;
      end = angular.copy(start);
    }

    self.request('deposits',
      {
        payload: { start: start, end: end, batch: batch }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.discrepancies = function(start, end, success, error, batch) {

    if(typeof end == 'function'){
      batch = error;
      error = success;
      success = end;
      end = angular.copy(start);
    }

    self.request('discrepancies',
      {
        payload: { start: start, end: end, batch: batch }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.bookedOn = function(start, end, success, error) {

    var data = {
      'start': start,
      'end': end
    };

    self.request('bookedOn',
      {
        payload: data
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.bookingSource = function (start, end, success, error) {

    var data = {
      'start': start,
      'end': end
    };

    self.request('bookingSource',
      {
        payload: data
      },
      function Success(response) {
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if (angular.isDefined(success)) return success(response);

      },
      function Error(err) {
        console.error(err);
        if (angular.isDefined(error)) return error(err);
      }

    );

  };

  self.byPackage = function (start, end, success, error) {

    var data = {
      'start': start,
      'end': end
    };

    self.request('byPackage',
      {
        payload: data
      },
      function Success(response) {
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if (angular.isDefined(success)) return success(response);

      },
      function Error(err) {
        console.error(err);
        if (angular.isDefined(error)) return error(err);
      }

    );

  };

  self.createBatch = function(data, success, error) {

    self.request('createBatch',
      {
        payload: data
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.commissions = function(start, end, filter, success, error) {

    if(typeof filter == 'function'){
      error = success;
      success = filter;
      filter = { type: 'hotels' };
    }

    self.request('commissions',
      {
        payload: { start: start, end: end, type: filter.type, filter: filter.filter || undefined }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.gifts = function(start, end, success, error) {

    self.request('gifts',
      {
        payload: { start: start, end: end }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.tips = function(start, end, success, error) {
    self.request('tips',
      {
        payload: { start: start, end: end }
      },
      function Success(response){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }
    );
  }

});
