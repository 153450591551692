'use strict';

var BugsCtrl = angular.module('RessieApp')

.controller('bugsModalController', function($scope, $modalInstance, Bugs, $timeout, bug, alreadyVoted){

  $scope.alreadyVoted = alreadyVoted;

  $scope.$watch('bug.steps', function(n, o){
    if(n[n.length - 1].value !== ''){
      $scope.addStep();
    }
  }, true);

  $scope.addStep = function(){
    $scope.bug.steps.push({ 'value' : '' });
  };

  if(bug){
    if(bug.steps.length > 0){
      if(bug.steps[0].constructor.name === 'String'){
        var steps = [];
        angular.forEach(bug.steps, function(val){
          steps.push({ value: val });
        });
        bug.steps = steps;
      }
    } else {
      bug.steps = [{ value: '' }];
    }
  }

  $scope.bug = bug || {
    description: "",
    steps: [
      {'value': ''}
    ]
  };

  $scope.canEdit = function(){
    return $scope.bug.user._id === $scope.user._id || !$scope.bug._id;
  };

  $scope.close = function(){
    $modalInstance.close();
  };

  $scope.save = function(bug){

    var data = angular.copy(bug);

    data.steps = _.compact(data.steps.map(function(val){
      return val.value;
    }));

    Bugs.create(data, function Success(res){
      $modalInstance.close(res);
    }, function Error(err){
      alert(JSON.stringify(err));
    });
  };

  $scope.update = function(bug){

    var data = angular.copy(bug);

    data.steps = _.compact(data.steps.map(function(val){
      return val.value;
    }));

    Bugs.update(data, function Success(res){
      $modalInstance.close();
    }, function Error(err){
      alert(JSON.stringify(err));
    });
  };

})

.controller('bugsController', function ($scope, $rootScope, $state, Bugs, $modal) {

  $scope.loading = true;

  $scope.bugs = [];

  Bugs.list(function(res){
    $scope.loading = false;
    $scope.bugs = res;
  }, function Error(err){
    alert(JSON.stringify(err));
  });

  $scope.openBugModal = function (bug) {

    var modalInstance = $modal.open({
      templateUrl: 'views/bugs/modal',
      controller: 'bugsModalController',
      resolve: {
        'bug': function(){
          if(bug) return bug;
          return null;
        },
        'alreadyVoted': function(){
          return $scope.alreadyVoted(bug);
        }
      }
    });

    modalInstance.result.then(function Success(bug) {
      $scope.bugs.push(bug);
      // add hotel to list of hotels and select it
    }, function Dismissed() {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  };

  var self = {

    second: function(bug){
      Bugs.second(bug._id, function(res){
        bug.seconded.push({ _id: $scope.user._id, name: $scope.user.name });
      }, function(err){
        alert(JSON.stringify(err));
      });
    },

    vote: function(bug){
      Bugs.vote(bug._id, function(res){
        bug.voters.push({ _id: $scope.user._id, name: $scope.user.name });
      }, function(err){
        alert(JSON.stringify(err));
      });
    },

    alreadyVoted: function(bug){
			if(bug){
				return (bug.voters.filter(function(user){
					return user._id === $scope.user._id;
				}).length > 0);
			}
			return false;
    },

    pending: function(){
      return $scope.bugs.filter(function(bug){
				if(bug){
          return bug.seconded.length === 0;
        }
      });
    },

    verified: function(){
      return $scope.bugs.filter(function(bug){
        if(bug){
          return bug.seconded.length > 0;
        }
      }).sort(function(a, b){
        return a.voters.length > b.voters.length;
      });
    }
  };

  angular.extend($scope, self);

});
