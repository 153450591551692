angular.module('RessieApp.directives.code', [])

  .directive('code', function () {

    return {
      restrict: 'EA',
      compile: function (element, attrs) {

        function htmlDecode(input) {
          if(!attrs.decode) return input;
          var e = document.createElement('div');
          e.innerHTML = input;
          // handle case of empty input
          return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        }
        var rawHtml = element[0].innerHTML.replace(/^\n/, '');
        var lines = rawHtml.split('\n')
        var whitespace = lines[0].match(/^(\s+)(\S)/);
        if(whitespace) whitespace = whitespace[1];
        for (var index = 0; index < lines.length; index++) {
          var line = lines[index];
          lines[index] = htmlDecode(line.replace(whitespace, ''));
        }

        rawHtml = lines.join('\n');
        var code = angular.element(element[0].cloneNode(false));
        code.text(rawHtml.trim());
        element.replaceWith(code);
        return function(scope, elm, attr){}
      }
    };

  });