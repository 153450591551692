'use strict';

angular.module('RessieApp.directives.calendar', [])
.directive('day', function(){
  return {
    restrict: 'A',
    link: function(scope, elm, attr){
      elm.height(elm.width() * .75)
    }
  }
})

.directive('calendar', function ($locale, $state, Calendar) {
  return {
    restrict: 'EA',
    templateUrl: 'dir/calendar/template',
    scope: {
      'for': '=',
      'events': '=',
      options: '=',
      onClick: '&',
      activeDay: '&',
      activeWeek: '&'
    },
    link: function (scope) {

      var evnts = {}, date;

      scope.dates = {};

      var initial_capacity = scope.events.ranged_capacity;
      scope.ranged_capacity = initial_capacity;

      scope.$watch('events', function(n){
        angular.forEach(n.days, function(day){
          evnts[moment.utc(day.date).format('D')] = day;
        })
        initial_capacity = scope.ranged_capacity = n.capacity
        scope.dates = evnts;
      });

      scope.company = scope.$parent.user.activeCompany();

      scope.today = new Date().getDate();

      var Year = new Date().getFullYear()

      var startYear = new Date(new Date().setFullYear(new Date().getFullYear() - 5));

      scope.date = new Date();
      
      scope.years = new Array(10);

      for (var index = 0; index < scope.years.length; index++) {
        startYear = new Date(startYear.setFullYear(startYear.getFullYear()+1));
        scope.years[index] = startYear;
      }
      
      scope.$watch('for', function () {

        date = scope['for'];

        var start = date instanceof Date && new Date(date.getTime()) || new Date();
        start.setDate(1);
        scope.start = angular.copy(start);
        var nextMonth = (start.getMonth() + 1) % 12;
        scope.days = {};

        if (angular.isDefined(scope.options)) {
          scope.month = scope.options.shortmonth ? $locale.DATETIME_FORMATS.SHORTMONTH[start.getMonth()] : $locale.DATETIME_FORMATS.MONTH[start.getMonth()];
          if (scope.options.supershortday) {
            angular.forEach($locale.DATETIME_FORMATS.SHORTDAY, function (value, key) {
              scope.days[key] = value.substring(0, 2);
            });
          } else {
            scope.days = scope.options.shortday ? $locale.DATETIME_FORMATS.SHORTDAY : $locale.DATETIME_FORMATS.DAY;
          }
        } else {
          scope.month = $locale.DATETIME_FORMATS.MONTH[start.getMonth()];
          scope.days = $locale.DATETIME_FORMATS.DAY;
        }
        scope._date = start;
        scope.year = start.getFullYear();
        scope.weeks = [{ days: [] }];
        var week = scope.weeks[0];

        do {
          if (start.getDay() === 0 && start.getDate() !== 1) {
            week = { days: [] };
            scope.weeks.push(week);
          }
          week.days[start.getDay()] = start.getDate();
          start.setDate(start.getDate() + 1);
        } while (start.getMonth() !== nextMonth);

        var next = 1;
        while (week.days.length < 7) {
          week.days.push(undefined); next++;
        }

      });

      scope.next = function(){
        var nextMonth = moment(scope.month + ' 1st, ' + scope.year, 'MMMM Do, YYYY').add(1, 'month').format('MM-DD-YY');
        $state.go('calendar', { month: nextMonth });
      },

      scope.prev = function(){
        var previousMonth = moment(scope.month + ' 1st, ' + scope.year, 'MMMM Do, YYYY').subtract(1, 'month').format('MM-DD-YY');
        $state.go('calendar', { month: previousMonth });
      },

      scope.clickDay = function (day) {
        if (day !== undefined) {
          return scope.onClick({ day: day });
        }
      };

      scope.addDay = function(day) {
        var bookDay = moment(scope.month + ' ' + day + ' ' + scope.year, 'MMMM D, YYYY').format('MM-DD-YY')
        $state.go('booking', { action: 'add', day: bookDay, gift: '' });
      }

      scope.updateCapacity = function (){
        var month = moment(scope.month + ' 1st, ' + scope.year, 'MMMM Do, YYYY')
          .format('MM-DD-YY');

        Calendar.setMonth({ start: month, count: scope.ranged_capacity }, function Success(res){

          if(initial_capacity !== scope.ranged_capacity) {
            angular.forEach(scope.dates, function(day){
              if(day.capacity.total === initial_capacity) {
                day.capacity.total = scope.ranged_capacity;
                day.capacity.remaining = day.capacity.total - day.capacity.booked;
              }
            });
          }

        }, function Error(err){
          alert(err.message);
        });
      }

      scope.isDayActive = function (month, day, year) {
        var date = scope['for'];
        return scope.activeDay({ month: date.getMonth(), day: day, year: date.getFullYear() });
      };

      scope.isWeekActive = function (week) {
        return scope.activeWeek({ week: week });
      };

    }
  };
});
