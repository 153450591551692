'use strict';

var PagesCtrl = angular.module('RessieApp')

.controller('pagesCtrl', function ($scope, $rootScope, $state, allowed, $window) {

  if(!allowed) $state.go('login');

  if(typeof $rootScope.user === 'undefined'){
    $window.location.href = '/login';
  } else {
    $state.go('dashboard');
  }

  if($rootScope.user && $rootScope.user.restricted) $state.go('gifts');

  var self = {

  };

});
