'use strict';

var calendarCtrl = angular.module('RessieApp')

.controller('calendarCtrl', function ($scope, $rootScope, $state, allowed, date) {

  if(!allowed) $state.go('login');

  if($rootScope.user.restricted) $state.go('gifts');

  var self = {

    next: function(){
      var nextMonth = date.month.month($scope.startMonth.getMonth() + 1).date(1).format('MM-DD-YY');
      // return nextMonth;
      $state.go('calendar', {
        month: nextMonth
      });
    },

    prev: function(){
      $state.go('calendar', { month: date.month.month($scope.startMonth.getMonth()-1 ).date(1).format('MM-DD-YY') });
    },

    startMonth: date.month.toDate(),

    gotoDay: function(day){
      $state.go('day.bookings', { day: date.month.date(day.day).format('MM-DD-YY') });
    },

    isToday: function(opt){
      return ($scope.today.month() == opt.month && $scope.today.year() == opt.year && $scope.today.date() == opt.day);
    },

    events: date.events || []

  };

  angular.extend($scope, self);

});
