angular.module('RessieApp.directives.time', [])

.directive('time', function($timeout){
  
  return {
      restrict: 'A'
    , compile: function(tElement, tAttrs) {

        return function Link(scope, elm, attr) {
          
          var defined = scope.$eval(attr.time) || {}
            , config = { spinnerImage: '', ampmPrefix: ' ' };

          config.hours = defined.hours || 1;
          config.minutes = defined.minutes || 1;
          config.seconds = defined.seconds || 1;

          if(defined.maxTime) {
            config.maxTime = moment(defined.maxTime, 'hh:mm A').toDate()
          }

          config.timeSteps = [ config.hours, config.minutes, config.seconds ];

          $(elm).timeEntry(config);

        }

      }
  };

});