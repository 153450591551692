var APIBookings = angular.module('RessieApp.services.api.bookings', [])

.service('Bookings', function(Request, API, $rootScope, $state, $timeout, $window, Socket){

  var self = this;

  self.queued = {};
  self.active = {};

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        list: {
            method: 'GET'
          , url: '/bookings/date/:day'
          , isArray: false
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        },

        online: {
            method: 'GET'
          , url: '/bookings/online/start/:start/end/:end/limit/:limit/page/:page'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , get: {
            method: 'GET'
          , url: '/booking/:confirmation'
          , isArray: false
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , search: {
            method: 'GET'
          , url: '/bookings/search'
          , isArray: false
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , activate: {
            method: 'PUT'
          , url: '/booking/:id/activate'
          , params: { id : '@_id' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , updateBillTo: {
            method: 'PATCH'
          , url: '/billto/:id'
          , params: { id : '@_id' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , payCommission: {
            method: 'PATCH'
          , url: '/booking/:id/commission'
          , params: { id : '@id' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , deleteCommission: {
            method: 'DELETE'
          , url: '/booking/:id/commission'
          , params: { id : '@id' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , cleanupCache: {
            method: 'DELETE'
          , url: '/bookings/day/:day/clearcache'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , markUnread: {
            method: 'PUT'
          , url: '/booking/:id/unopened'
          , params: { id : '@_id' }
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , query: {
            method: 'GET'
          , url: '/bookings'
          , isArray: true
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , vips: {
            method: 'GET'
          , url: '/bookings/vip'
          , isArray: true
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , create: {
            method: 'POST'
          , url: '/booking'
          , isArray: false
          // , params: { user : $rootScope.user._id }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , confirmation: {
            method: 'POST'
          , url: '/confirmation/:id'
          , isArray: false
          , params: { id : '@id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , receipt: {
            method: 'POST'
          , url: '/receipt/:id'
          , isArray: false
          , params: { id : '@_id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , update: {
            method: 'PUT'
          , url: '/booking/:id'
          , isArray: false
          , params: { id : '@_id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , cancel: {
            method: 'PUT'
          , url: '/booking/:id/cancel/:cancellation'
          , isArray: false
          , params: { id : '@_id', cancellation: '@cancellation' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , cancelAll: {
            method: 'PUT'
          , url: '/bookings/cancel/:day'
          , isArray: false
          , params: { day : '@day' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , assignFlight: {
            method: 'PUT'
          , url: '/booking/:id/flight/:flight'
          , isArray: false
          , params: { id : '@_id', flight: '@_flight' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , updateImpression: {
            method: 'PUT'
          , url: '/booking/:id/happy/:flight'
          , isArray: false
          , params: { id : '@_id', flight: '@flight' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , unassignFlight: {
            method: 'DELETE'
          , url: '/booking/:id/flight/:flight'
          , isArray: false
          , params: { id : '@_id', flight: '@_flight' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource(endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isUndefined(error)) {
          $timeout(function(){
            var message = (angular.isDefined(response.error.message)) ? response.error.message : response.error;
            var confirm = window.alert(message);
          });
        } else {
          return error(response.error);
        }
      } else {
        Request.renew(token);
        return success(response, token);
      }

    });
  };

  self.vips = function(success, error) {

    self.request('vips',
      {
        payload: {}
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.get = function(payload, success, error) {

    self.request('list',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.online = function(payload, success, error) {
    self.request('online',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.error(err);
        if(angular.isDefined(error)) return error(err);
      }

    );
  };

  self.search = function(query, success, error) {

    self.request('search',
      {
        payload: query
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.activate = function(payload, success, error) {

    if(typeof payload == 'string') payload = { '_id' : payload };
    if(typeof payload == 'number') payload = { '_id' : payload };

    self.request('activate',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.updateBillTo = function(payload, success, error) {

    self.request('updateBillTo',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.payCommission = function(payload, success, error) {

    self.request('payCommission',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.deleteCommission = function(payload, success, error) {

    self.request('deleteCommission',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.cleanupCache = function (payload, success, error) {

    self.request('cleanupCache', {
        payload: payload
      },
      function Success(response, token) {

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if (angular.isDefined(success)) return success(response);

      },
      function Error(err) {
        console.log(err);
        if (angular.isDefined(error)) return error(err);
      }

    );

  };

  self.markUnread = function(payload, success, error) {

    if(typeof payload == 'string') payload = { '_id' : payload };
    if(typeof payload == 'number') payload = { '_id' : payload };

    self.request('markUnread',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.query = function(payload, success, error) {

    self.request('query',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.confirmation = function(payload, success, error) {

    if(typeof payload == 'string') payload = { 'confirmation' : payload.replace(/[A-Za-z$-]/g, "") };
    if(angular.isUndefined(payload.confirmation)) payload.confirmation = "";

    self.request('get',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.sendConfirmation = function(payload, success, error) {

    self.request('confirmation',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.sendReceipt = function(payload, success, error) {

    payload = { _id: payload._id };

    self.request('receipt',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.create = function(payload, success, error) {

    self.request('create',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.update = function(payload, success, error) {

    self.request('update',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.cancel = function(payload, success, error) {

    var options = { '_id': payload._id, 'cancellation' : payload.cancellation };

    if(angular.isDefined(payload.refund)) options.refund = payload.refund;

    self.request('cancel',
      {
        payload: options
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        Socket.emit('cancelledBooking', { id: payload._id });
        if(angular.isDefined(success)) return success(response);
      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.cancelAll = function(payload, success, error) {

    var options = { 'day': payload.day, 'reason' : payload.cancellation };

    if(angular.isDefined(payload.refund)) options.refund = payload.refund;

    self.request('cancelAll',
      {
        payload: options
      },
      function Success(response, token){
        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);
      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.assignFlight = function(payload, success, error) {

    self.request('assignFlight',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.updateImpression = function(payload, success, error) {

    self.request('updateImpression',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.unassignFlight = function(payload, success, error) {

    self.request('unassignFlight',
      {
        payload: {
          id: payload._id,
          flight: payload._flight
        }
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        console.log(err);
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

});
