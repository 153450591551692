var APINotes = angular.module('RessieApp.services.api.notes', [])

.service('Notes', function(Request, API, $rootScope, $state, $timeout, $window){

  var self = this;

  self.queued = {};
  self.active = {};

  self.endpoints = function(key){

    /**
     * Log a user in.
     * @type {Object}
     */
    return {

        create: {
            method: 'POST'
          , url: '/notes'
          , isArray: false
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , one: {
            method: 'GET'
          , url: '/note/:id'
          , isArray: false
          , params: { id : '@id' }
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

      , list: {
            method: 'GET'
          , url: '/notes'
          , isArray: true
          , headers: {
              'Authorization': key,
              'Company': $rootScope.user.Company()
            }
        }

    }

  };

  self.resource = function(key){
    var endpoints = self.endpoints(key);
    angular.forEach(endpoints, function(value, key){

      value.interceptor = {
        response: function (response) {
          response.data.requestUrl = response.config.url;
          return response.data;
        }
      }

      if(angular.isDefined(value.url)) {
        value.url = API.getDomain() + value.url;
      }
    });
    return Request.resource(endpoints);
  };

  /**
   * Helper method to build requests without duplicating code.
   * @param  {[type]} options [description]
   * @param  {[type]} success [description]
   * @param  {[type]} error   [description]
   * @return {[type]}         [description]
   */
  self.request = function(endpoint, options, success, error){

    var request_key = API.api_key + "^" + Request.token;

    return self.resource(request_key)[endpoint](options.payload, function(response, headers){

      var token = Request.Token(headers('authorization'));

      if(response.error){
        // #TODO: Need to add in error handling based on returned codes
        if(angular.isDefined(error)) {
          $timeout(function(){
            return error(response);
          });
        } else {
          var message = (angular.isDefined(response.error.message)) ? response.error.message : response.error;
          window.alert(message)
        }
      } else {
        Request.renew(token);
        return success(response, token);
      }

    });
  };

  self.create = function(payload, success, error) {

    self.request('create',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.one = function(id, success, error) {

    var payload = { id: id };

    self.request('one',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

  self.list = function(payload, success, error) {

    self.request('list',
      {
        payload: payload
      },
      function Success(response, token){

        // pass the api response (generally empty) and the header object
        // to the user for successfull callback manipulation
        if(angular.isDefined(success)) return success(response);

      },
      function Error(err){
        if(angular.isDefined(error)) return error(err);
      }

    );

  };

});
