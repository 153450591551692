var APIService = angular.module('RessieApp.services.api', [
    'RessieApp.services.api.bookings'
  , 'RessieApp.services.api.events'
  , 'RessieApp.services.api.flights'
  , 'RessieApp.services.api.users'
  , 'RessieApp.services.api.gifts'
  , 'RessieApp.services.api.cards'
  , 'RessieApp.services.api.banks'
  , 'RessieApp.services.api.contacts'
  , 'RessieApp.services.api.legacy'
  , 'RessieApp.services.api.pickups'
  , 'RessieApp.services.api.packages'
  , 'RessieApp.services.api.balloons'
  , 'RessieApp.services.api.zones'
  , 'RessieApp.services.api.companies'
  , 'RessieApp.services.api.products'
  , 'RessieApp.services.api.customers'
  , 'RessieApp.services.api.notifications'
  , 'RessieApp.services.api.notes'
  , 'RessieApp.services.api.calendar'
  , 'RessieApp.services.api.messages'
  , 'RessieApp.services.api.emailTemplates'
  , 'RessieApp.services.api.reports'
	, 'RessieApp.services.api.files'
	, 'RessieApp.services.api.bugs',
	, 'RessieApp.services.api.bundles'
])

/**
 * Define configurable API settings
 */
.provider('API', function() {

  this.domain = window.api + '/api/v1/';
  this.api_key = null;

  this.$get = function() {
    var self = this;
    return {
      getDomain: function() {
        return self.domain;
      },
      getKey: function(){
        return self.api_key;
      }
    }
  };

  this.setDomain = function(domain) {
    this.domain = domain;
  };

  this.setKey = function(key) {
    this.api_key = key;
  };

})

.provider('Environment', function(){

  this.$get = function(){
    return {
      env: function(env){
       // if(location.hostname.match(/127.0.0.1|localhost/)) return 'development';
       // if(location.hostname.match(/nvbstage.joseymorton.com/)) return 'staging';
      }
    }
  }

})

/**
 * Global API methods
 */
.service('Request', function(ipCookie, $resource, API, $http, $rootScope){

  var self = this;

  self.token = null;

  self.request = null;

  self.Token = function(token){

    if(token) self.token = token;

    return self.token;

  }

  self.resource = function(section, actions) {
    if(typeof section == 'object') actions = section;
    return $resource(API.getDomain(), {}, actions);
  };

  self.error = function(res, cb) {
    $rootScope.bugCatcher.push({
      url: res.requestUrl,
      error: res.error,
      location: undefined
    });

    return cb(res);
  }

  // Renew the stored cookie with updated token
  self.renew = function(token){

    self.token = token;

    $rootScope._token = token;

    userCookie = ipCookie('nvbUser');

    ipCookie.remove('nvbUser');

    if($rootScope.user){

      API.api_key = $rootScope.user.api_key;

      self.request = $rootScope.user.api_key + "^" + token;

    }

    if(userCookie){

      API.api_key = userCookie.key;

      self.request = userCookie.key + "^" + token;

      angular.extend(userCookie, { token: token });

      ipCookie('nvbUser', userCookie, { path: '/' });

    }

  }

})

/**
 * Global methods passed to other API services
 */
angular.extend(APIService, {});
