'use strict';

var templateCtrl = angular.module('RessieApp')


.controller('templateCtrl', function (allowed, $scope, $timeout, $modal, $log, EmailTemplates) {

  if(!allowed) $state.go('login');

  $scope.selected = null;
  var original = null;

  $scope.edit = function (template, size) {

    if(typeof template !== 'object'){
      size = template;
      template = null;
    }

    var resolve = {};

    if(template){
      $scope.selected = template;
      original = angular.copy(template);
      resolve['template'] = function(){
        return $scope.selected;
      }
    }

    var modalInstance = $modal.open({
      animation: true,
      templateUrl: 'views/settings/forms/template',
      controller: 'TemplateModalCtrl',
      size: size,
      resolve: resolve
    });

    modalInstance.result.then(function (data) {

      angular.forEach(data._data, function(val, key){
        data.data[val.key] = val.value;
      });
      delete data._data;

      var endpoint = (typeof data._id !== 'undefined') ? 'update' : 'create';

      EmailTemplates[endpoint](data, function Success(res){
        console.log(res);
        if(endpoint === 'create') $scope.$parent.templates.push(res);
      }, function Error(err){
        console.error(err);
        alert(JSON.stringify(err, null, 2));
      })

    }, function () {
      if($scope.selected) delete $scope.selected._data;
      angular.forEach($scope.selected, function(val, key){
        $scope.selected[key] = original[key]
      })
      $log.info('Modal dismissed at: ' + new Date());
    });
  };

  $scope.remove = function(template) {
    template.working = true;
    EmailTemplates.delete(template, function Win(res){
      template.removed = true;
      template.working = false;
    }, function Lose(err){
      template.working = false;
      template.removed = false;
      alert(err);
    })
  }

  $scope.resetReservationConf = function(template){

    var rule = $scope.template_rules['Reservation'].confirmation;
    var confirmations = $scope.templates.filter(function(template){
      return template.model == 'Reservation' && template.type == 'confirmation';
    });

    angular.forEach(confirmations, function(template){
      if(template.default){
        template.default = false;
        EmailTemplates.update(template, function Win(res){
          console.log('defult unset', res);
        }, function Lose(err){
          console.err(err);
        })
      }
    })

    template.default = true;
    EmailTemplates.update(template, function Win(res){
      console.log('defult set', res);
    }, function Lose(err){
      template.default = false;
    })


  }

  angular.extend($scope, self || {});

})

.value('template', {})

.controller('TemplateModalCtrl', function ($scope, $modalInstance, template) {

  var originalData = {};

  $scope.addData = function(keyVal){
    $scope.template._data = $scope.template._data || [];
    $scope.template._data.push({ key: keyVal.key, value: keyVal.value })
    keyVal.key = "";
    keyVal.value = "";
    $("#newDataKey").focus();
  };

  $scope.cancel = function(){
    $modalInstance.dismiss('cancel');
  }

  $scope.template = template || {};

  if(angular.isDefined($scope.template.data)){
    $scope.template._data = [];
    originalData = $scope.template.data;
    angular.forEach($scope.template.data, function(val, key){
      $scope.template._data.push({ key: key, value: val });
    });
    $scope.template.data = {};
  }

  $scope.ok = function(){
    $modalInstance.close($scope.template);
  }

  $scope.template.body = $scope.template.body || "Enter your template's body here";

  $scope.template.data = $scope.template.data;

  $scope.models = [
    {
      model: 'Reservation',
      name: 'Reservations'
    },
    {
      model: 'Prepaid',
      name: 'Prepaids'
    },
    {
      model: 'User',
      name: 'Users'
    }
  ];

  $scope.types = {
    'Reservation': [
      { name: 'Confirmation', value: 'confirmation' },
      { name: 'Receipt', value: 'receipt' }
    ],
    'Prepaid': [
      { name: 'Confirmation', value: 'confirmation' },
      { name: 'Receipt', value: 'receipt' },
      { name: 'Voucher', value: 'voucher' },
    ],
    'User': [
      { name: 'Password Reset', value: 'password reset' },
      { name: 'Invitation', value: 'invitation' }
    ]
  }

  $scope.tags = [];

  $scope.validate = function(file){
    console.log(file)
  }

});
